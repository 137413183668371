import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, TextField, Tooltip} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { withRouter } from 'react-router-dom';
import { withMessageManager } from '../../../Helpers/messages/MessageRenderer'
import { withAuthManager, AuthContext } from '../../../Helpers/auth/auth'
import { Formik,} from "formik";
import * as Yup from "yup";
import moment from 'moment'
import CreditApi from '../../../Models/Credit'
import SetCreditForm from './SetCreditForm'
import AccessController from "../../../AccessController/AccessController.js";

class Credit extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            modalConfirm: false,
            balance : null,
            packages : [],
            formValues : {
                packageId : null,
                purchaseDate : null,
                price: null,
                rentalMode: null
            },
            packagePrice: 0,
        }
        this.accessController = new AccessController()
        this.validationSchema = Yup.object().shape({
            packageId: Yup.number().typeError("Please select a package").required("Please select a package"),
            purchaseDate: Yup.date().typeError("Please enter purchase package").required("Please enter purchase package"),
        });
        this.creditApi = new CreditApi()
    }
    openModal(modal){
        this.setState({[modal]: true})
    }
    closeModal(modal){
        this.setState({[modal]: false})
    }
    componentDidMount() {
        this.init()
    }
    init() {
        var studentId = this.props.studentId
        this.creditApi.GetPackages((packages) => {
            packages.sort((a, b) => {return a.price - b.price})
            this.setState({packages : packages})
        })
        this.creditApi.GetBalance(studentId, (balance) => {
            this.setState({balance : balance})
        })
    }
    handlePrice(rentalMode,packageId,setFieldValue){
        if(packageId != null){
            let selectedPackage = this.state.packages.find(p => p.idpackage === packageId)
            let price = selectedPackage.price
            let price_withoutrental = selectedPackage.price_withoutrental
            if(rentalMode == null || rentalMode == 1 || rentalMode == 2){
                setFieldValue("price",price)
            }else{
                setFieldValue("price",price_withoutrental)
            }
        }else{
            setFieldValue("price",0)
        }
    }
    renderConfirmModal(){
        var studentId = this.props.match.params.studentId
        return(
        <>
        <Modal show={this.state.modalConfirm} onHide={()=> this.closeModal('modalConfirm')}>
        <Modal.Body>
            <Modal.Title>Please confirm that you wish to add credits</Modal.Title>
        </Modal.Body>
        <Modal.Footer>
            <Button className='greenButton' onClick={() => {
                var values = this.state.formValues
                if (values != null) {
                    this.creditApi.RecordPackagePurchase(studentId, values.packageId,values.price,values.rentalMode,values.purchaseDate, (res,err) => {
                        if (err != null && err) {
                            this.props.setMessages([{type : "danger", message : err}])
                        } else {
                            this.props.setMessages([{type : "success", message : "Credits Recorded"}])
                        }
                        window.scrollTo(0,0)
                        this.init()
                        this.closeModal('modalConfirm')
                    })
                }
            }}>Yes</Button>
            <Button className='greyButton' onClick={()=> this.closeModal('modalConfirm')}>Cancel</Button>
        </Modal.Footer>
        </Modal>
        </>
        )
    }
 
    renderCreditDetails(){
        var balance = this.state.balance
        var expired = null
        var values = this.state.formValues
        var expiry = null
        if (balance != null) {
            expiry = moment(balance.expiryDate)
            var now = moment()
            if (now.isAfter(expiry,'day')) {
                expired = true
            }
            expiry = expiry.format("DD MMM YYYY")
        }
        return(
            <AuthContext.Consumer>
                {authManager =>{
                    return (
                        <Container>
                            {this.state.balance && (
                                <Row style={{border: '1px solid #C4C4C4', borderRadius: '4px', display: 'flex', alignItems: 'center', paddingTop: '10px', paddingBottom: '10px'}}>
                                    <Col style={{textAlign: 'left', padding: '0 30px'}}>
                                        <Row>
                                            <Col xs={5} className='txtBold'>No. Of Credits Available</Col>
                                            <Col xs={5} className='txtGrey'>{expired ? 0 : this.state.balance.credit}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5} className='txtBold'>Expiry Date</Col>
                                            <Col xs={5} className='txtGrey'>{expiry || "-"}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5} className='txtBold'>No. Of Credits Expired</Col>
                                            <Col xs={5} className='txtGrey'>{expired ? this.state.balance.credit : 0}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5} className='txtBold'>Credits Used</Col>
                                            <Col xs={5} className='txtGrey'>
                                            <a href={`/student/${this.props.studentId}/credit/used`} style={{color: '#27bdbe'}}>View all credit transactions</a></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )}
                            {this.accessController.UserCanAddNewCreditsPurchased(this.props.user.accessLevel) &&
                            <Row className='creditBorder'>
                                <Col>
                                    <Formik
                                        initialValues={{
                                            packageId: values.packageId,
                                            purchaseDate : values.purchaseDate,
                                            price : values.price,
                                            rentalMode: values.rentalMode,
                                        }}
                                        validationSchema={this.validationSchema}
                                        onSubmit={(values, { setSubmitting, resetForm }) => {
                                                this.setState({formValues : values})
                                                setSubmitting(false)
                                                this.openModal('modalConfirm')
                                    }}>
                                    {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) =>
                                        (
                                            <Form onSubmit={handleSubmit}>
                                                <Row>
                                                    <Col xs={4}>
                                                        <p >Add New Credits Purchased:</p>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <p style={{marginBottom:0}}>Purchase Date</p>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <p>Credits/Validity</p>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:"15px"}}>
                                                    <Col xs={{span:4,offset:4}}>
                                                        <KeyboardDatePicker
                                                            className="fs-12" label="Date of purchase" fullWidth
                                                            disableFuture={true} inputVariant="outlined" format="dd/MM/yyyy"
                                                            // inputProps={this.propsForTextFieldInput}
                                                            InputProps={{
                                                                onBlur : (val) => {
                                                                    var date = moment(val.target.value,"DD/MM/YYYY")
                                                                    if (date.isValid()) {
                                                                        setFieldValue("purchaseDate", date.toDate())
                                                                    }
                                                                }
                                                            }}
                                                            value={values.purchaseDate} 
                                                            onChange={(date) => {
                                                                setFieldValue("purchaseDate", date)}}
                                                        />
                                                    </Col>
                                                    <Col xs={4}>
                                                        <FormControl variant="outlined" style={{width:"100%", paddingBottom:0}}>
                                                            <InputLabel style={{color:"light gray", opacity:"0.65"}}>Select Package</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                label="Packages"
                                                                size='small'
                                                                name="packageId"
                                                                value = {values.packageId}
                                                                onChange={(e, v) => { 
                                                                    setFieldValue("packageId",v.props.value)
                                                                    this.handlePrice(values.rentalMode, v.props.value,setFieldValue) 
                                                                }}
                                                            >
                                                                <MenuItem key={-1} value={undefined}>Select a package</MenuItem>
                                                                {this.state.packages.map(packageObj => 
                                                                    <MenuItem key={packageObj.idpackage} value={packageObj.idpackage}>{packageObj.description}</MenuItem>
                                                                )}
                                                            </Select>
                                                            {touched.packageId && errors.packageId && <FormHelperText error={true}>{errors.packageId}</FormHelperText>}
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={{span:4,offset:4}}>
                                                        <p style={{marginBottom:0}}>Device Rental</p>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <span>Package Price</span>
                                                        <Tooltip
                                                            title={"Click here to view full price"}>
                                                            <HelpIcon style={{ color: "#27bdbe", height: "20px", width: "20px", marginLeft: "5px", marginBottom: "5px", cursor: "pointer" }} onClick={() => window.open("https://empirecodeworksheet.s3.ap-southeast-1.amazonaws.com/public/pricelist.jpg", "_blank")} />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginBottom:"15px"}}>
                                                    <Col xs={{span:4,offset:4}}>
                                                        <FormControl variant="outlined" style={{width:"100%", paddingBottom:0}}>
                                                            <InputLabel style={{color:"light gray", opacity:"0.65"}}>Device Rental</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                label="Device Rental"
                                                                size='small'
                                                                name="rentalMode"
                                                                value = {values.rentalMode}
                                                                onChange={(e, v) => { 
                                                                    setFieldValue("rentalMode",v.props.value)
                                                                    this.handlePrice(v.props.value, values.packageId,setFieldValue) 
                                                                }}
                                                            >
                                                                <MenuItem key={-1} value={undefined}>Select a package</MenuItem>
                                                                <MenuItem key={0} value={1}>Yes</MenuItem>
                                                                <MenuItem key={1} value={0}>No</MenuItem>
                                                                <MenuItem key={2} value={2}>Partial</MenuItem>
                                                            </Select>
                                                            {touched.packageId && errors.rentalMode && <FormHelperText error={true}>{errors.rentalMode}</FormHelperText>}
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <FormControl variant="outlined" style={{width:"100%", paddingBottom:0}}>
                                                            <TextField type="price" placeholder="Package Price" variant="outlined" 
                                                                id="demo-simple-select-outlined"
                                                                // InputProps={singleLineTextFieldProps}
                                                                value={values.price} name={'price'}                                      
                                                                onChange={handleChange}
                                                                // disabled={values.rentalMode!=2}
                                                                error={(touched.price &&  errors.price)}
                                                                helperText={touched.price && errors.price}
                                                            ></TextField>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{textAlign : "right", padding: '0 30px'}}>
                                                        <Button className='fs-14 greenButton' type="submit">Submit</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>
                            }
                            {(this.accessController.UserCanSetCredit(authManager.user.accessLevel)) &&
                                <Row className='creditBorder'>
                                    <Col>
                                        <SetCreditForm postSubmitHandler = {() => this.init()}/>
                                    </Col>
                                </Row>
                            }
                        </Container>
                    )
                }}
            
            </AuthContext.Consumer>
        )
    }
    render() {
        return (
        <>
            {this.state.modalConfirm && this.renderConfirmModal()}
            <Row>
            <Col xs={12}>
                <Row>
                <Col className='fs-24'
                    style={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    }}
                >
                    Credit Available
                </Col>
                </Row>
                {this.renderCreditDetails()}
            </Col>
            </Row>
        </>
        );
    }
}

export default withAuthManager(withMessageManager(withRouter(Credit)));
