import React from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col, Button, Modal} from 'react-bootstrap'
import Curriculum from '../../../Models/Curriculum';
import Student from '../../../Models/Student';
import Teacher from '../../../Models/Teacher';
import FullScreenSpinner from "../../FullScreenSpinner/FullScreenSpinner.js";
import AccessController from "../../../AccessController/AccessController";
import { Hidden } from '@material-ui/core';
class CurriculumListItem extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {
            graduated : undefined,
            certificate : undefined,
            graduatedPrize : undefined,
            graduatedBadge : undefined,
            milestonePrize : undefined,
            milestoneBadge : undefined,
            modalArchive : false,
            archivingCurriculum : null,
        }
        this.curriculumApi = new Curriculum()
        this.studentApi = new Student()
        this.teacherApi = new Teacher()
        this.permissions = new AccessController()
        this.curriculumId = this.props.curriculum.idcurriculum
        this.studentId = props.match.params.studentId
        this.teacherId = props.match.params.teacherId
    }
    openModal(modal){
        this.setState({[modal]: true})
    }
    closeModal(modal){
        this.setState({[modal]: false})
    }
    componentDidMount(){
        if (this.studentId !== undefined) {
            this.curriculumApi.GetCurriculumMilestone(this.studentId, this.curriculumId, (milestone) => {
                if(milestone.graduated === 1){
                    this.setState({graduated : true})           
                }else{
                    this.setState({graduated : false})
                }
                if(milestone.certificate === 1){
                    this.setState({certificate : true})
                }else{
                    this.setState({certificate : false})
                }
                if(milestone.graduatedPrize === 1){
                    this.setState({graduatedPrize : true})
                }else{
                    this.setState({graduatedPrize : false})
                }
                if(milestone.graduatedBadge === 1){
                    this.setState({graduatedBadge : true})
                }else{
                    this.setState({graduatedBadge : false})
                }
                if(milestone.milestonePrize === 1){
                    this.setState({milestonePrize : true})
                }else{
                    this.setState({milestonePrize : false})
                }
                if(milestone.milestoneBadge === 1){
                    this.setState({milestoneBadge : true})
                }else{
                    this.setState({milestoneBadge : false})
                }
            })
        }
    }
    renderArchiveConfirmationModal(){
        var curriculum = this.state.archivingCurriculum
        return(
            <Modal size="lg" show={this.state.modalArchive} onHide={()=> this.closeModal('modalArchive')}>
                <Modal.Header closeButton style={{border: 'none'}}>
                    <Modal.Title className='txtBold fs-24'>Confirm Archiving Curriculum?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{margin : "1rem 0"}}>{curriculum.name}</Row>
                    <Col style={{margin : "1rem 0", textAlign : "right"}}>
                        <Button className='redButton' type="submit"
                            onClick={()=>{
                                this.closeModal('modalArchive')
                                this.setState({archivingCurriculum : null})
                                this.removeCurriculum(curriculum)
                            }}
                        >Archive</Button>
                    </Col>
                </Modal.Body>
            </Modal>
        )
    }
    
    removeCurriculum(curriculum) {
        if (this.studentId !== undefined) {
            this.studentApi.RemoveStudentFromCurriculum(this.studentId, curriculum.idcurriculum, (res, err)=>{
                if (res) {
                    // say successfully removed
                    window.location.reload()
                }
            })
        }
        if (this.teacherId !== undefined) {
            this.teacherApi.RemoveTeacherFromCurriculum(this.teacherId, curriculum.idcurriculum, (res, err) => {
                if (res) {
                    // say successfully removed
                    window.location.reload()
                }
            })
        }
    }

    render() {
        var milestoneBadge = this.state.milestoneBadge
        if (milestoneBadge === undefined && this.studentId !== undefined) {
            return (<FullScreenSpinner />)
        }
        var showArchive = this.props.showArchive
        var curriculum = this.props.curriculum
        var viewPath = this.props.viewPath
        return (
            <>
            {this.state.modalArchive && this.renderArchiveConfirmationModal()}
            <Row>
                <Col style={{boxShadow : "3px 3px #CCCCCC", borderRadius : "15px", border : "1px solid #c0c0c0", marginTop : "1%",  marginBottom : "10px"}}>
                    <Col className="clickable" onClick={()=>{
                        var choseHandler = this.props.onChoseCurriculum
                        if (choseHandler && typeof choseHandler === "function") {
                            choseHandler(curriculum)
                        } else {
                            this.props.history.push(viewPath)
                        }
                    }}>
                        <Row>
                            <Col xs={8} >
                                <div style={{textAlign : "left", padding : "10px"}}>
                                    {curriculum.name}
                                </div>
                            </Col>
                            { ((curriculum.hasMilestone === 1)) && (
                                <Col xs={4} style={{display: "flex", justifyContent: "space-around", alignItems:"center"}}>
                                    { ((this.state.milestoneBadge !== true)) && (
                                        <img src={require("../../../Assets/Icons/curriculumIcons/badge-1.png")}  alt="badge-1"
                                    />
                                    )}
                                    { (this.state.milestoneBadge === true) && (
                                        <img src={require("../../../Assets/Icons/curriculumIcons/badge_collected-1.png")}  alt="badge collected-1"
                                        />
                                    )}

                                    { (this.state.milestonePrize !== true) && (
                                        <img src={require("../../../Assets/Icons/curriculumIcons/spin_the_wheel-1.png")}  alt="spin_the_wheel-1"
                                    />
                                    )}
                                    { (this.state.milestonePrize === true) && (
                                        <img src={require("../../../Assets/Icons/curriculumIcons/spun_the_wheel-1.png")}  alt="spun_the_wheel-1"
                                        />
                                    )}

                                    {this.state.graduated !== true && (
                                        <img src={require("../../../Assets/Icons/curriculumIcons/graduate.png")}  alt="graduation hat"
                                        />
                                    )}
                                    { this.state.graduated === true && (
                                        <img src={require("../../../Assets/Icons/curriculumIcons/graduated.png")}  alt="graduation hat"
                                        />
                                    )}

                                    { this.state.certificate !== true && (
                                        <img src={require("../../../Assets/Icons/curriculumIcons/certificate.png")}  alt="certificate"
                                        />
                                    )}
                                    { this.state.certificate === true && (
                                        <img src={require("../../../Assets/Icons/curriculumIcons/certificate_collected.png")}  alt="certificate collected"
                                        />
                                    )}

                                    { (this.state.graduatedBadge !== true && curriculum.hasMilestone === 1) && (
                                    <img src={require("../../../Assets/Icons/curriculumIcons/badge-2.png")}  alt="badge-2"
                                    />
                                    )}
                                    { (this.state.graduatedBadge === true && curriculum.hasMilestone === 1) && (
                                        <img src={require("../../../Assets/Icons/curriculumIcons/badge_collected-2.png")}  alt="badge collected-2"
                                        />
                                    )}

                                    { (this.state.graduatedPrize !== true && curriculum.hasMilestone === 1) && (
                                        <img src={require("../../../Assets/Icons/curriculumIcons/spin_the_wheel-2.png")}  alt="spin_the_wheel-2"
                                        />
                                    )}
                                    { (this.state.graduatedPrize === true && curriculum.hasMilestone === 1) && (
                                        <img src={require("../../../Assets/Icons/curriculumIcons/spun_the_wheel-2.png")}  alt="spun_the_wheel-2"
                                        />
                                    )}
                                </Col>
                            )}
                            { ((curriculum.hasMilestone === 0)) && (
                                    <Col xs={4} style={{textAlign : "right", display: "flex", justifyContent: "space-around", alignItems:"center"}}>
                                        <img src={require("../../../Assets/Icons/curriculumIcons/white_space.png")}  alt="whitespace_png"
                                            style={{height:"20px", width:"20px"}}/>
                                        <img src={require("../../../Assets/Icons/curriculumIcons/white_space.png")}  alt="whitespace_png"
                                            style={{height:"20px", width:"20px"}}/>
                                        { this.state.graduated !== true && (
                                            <img src={require("../../../Assets/Icons/curriculumIcons/graduate.png")}  alt="graduation hat"
                                            />
                                        )}
                                        { this.state.graduated === true && (
                                            <img src={require("../../../Assets/Icons/curriculumIcons/graduated.png")}  alt="graduation hat"
                                            />
                                        )}

                                        { this.state.certificate !== true && (
                                            <img src={require("../../../Assets/Icons/curriculumIcons/certificate.png")}  alt="certificate"
                                            />
                                        )}
                                        { this.state.certificate === true && (
                                            <img src={require("../../../Assets/Icons/curriculumIcons/certificate_collected.png")}  alt="certificate collected"
                                            />
                                        )}

                                        { (this.state.graduatedBadge !== true && curriculum.hasMilestone === 0) && (
                                            <img src={require("../../../Assets/Icons/curriculumIcons/badge.png")}  alt="badge"
                                            />
                                        )}
                                        { (this.state.graduatedBadge === true && curriculum.hasMilestone === 0) && (
                                            <img src={require("../../../Assets/Icons/curriculumIcons/badge_collected.png")}  alt="badge collected"
                                            />
                                        )}

                                        { (this.state.graduatedPrize !== true && curriculum.hasMilestone === 0) && (
                                            <img src={require("../../../Assets/Icons/curriculumIcons/spin_the_wheel.png")}  alt="spin_the_wheel"
                                            />
                                        )}
                                        { (this.state.graduatedPrize === true && curriculum.hasMilestone === 0) && (
                                            <img src={require("../../../Assets/Icons/curriculumIcons/spun_the_wheel.png")}  alt="spun_the_wheel"
                                            />
                                        )}        
                                    </Col>   
                                
                            )}
                        </Row>
                    </Col>
                </Col>
                {(showArchive === undefined) || !showArchive ?<></> :
                    <Button className='redButton' type="submit" 
                            style={{marginTop : "1%",  marginBottom : "10px", margin : "1.5%"}}
                            onClick={() => {
                                this.setState({archivingCurriculum : curriculum})
                                this.openModal('modalArchive')}}>
                        Archive
                    </Button>
                }
            </Row>
            </>
        )
    }
}
export default withRouter(CurriculumListItem);