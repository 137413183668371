function ParseString(optionalString) {
    if (typeof optionalString === "string" && optionalString !== "") {
        return optionalString
    } else {
        return "not a string"
    }
}

function ParseFunction(optionalFunction) {
    if (optionalFunction instanceof Function) {
        return optionalFunction
    } else {
        return () => {}
    }
}

function JSDateToHTMLDateString(JSDate) {
    if (JSDate === undefined || !JSDate.getFullYear) {
        return ""
    }
    var datestring = JSDate.getFullYear()+'-'+ ("0"+(JSDate.getMonth()+1)).slice(-2) +'-'+ ("0" + JSDate.getDate()).slice(-2)
    return datestring
}

function IsMobileView(currentWidth, customCutoffWidth) {
    // custom width, for scenarios where we want to have a different cutoff
    let mobileWidth = customCutoffWidth ?? 767; // 768 is standard md width
    return (currentWidth <= mobileWidth); 
}

export {JSDateToHTMLDateString, ParseString, ParseFunction, IsMobileView};