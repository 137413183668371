import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { TextField , Select, MenuItem, FormControl, InputLabel, FormHelperText} from '@material-ui/core';
import FullScreenSpinner from '../../../Components/FullScreenSpinner/FullScreenSpinner'
import { withRouter } from 'react-router-dom';
import { withMessageManager } from '../../../Helpers/messages/MessageRenderer'
import Student from '../../../Models/Student'
import { Formik, FieldArray, getIn} from "formik";
import * as Yup from "yup";
import Family from '../../../Models/Family';
import FamilySourceFields from './FamilySourceFormFields'
class AddFamilyForStudentForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.studentApi = new Student()
        this.validationSchema = Yup.object().shape({
            parents : Yup.array().of(
                Yup.object().shape({
                    firstName: Yup.string().typeError("Please enter your first name"),
                    lastName: Yup.string().typeError("Please enter your last name"),
                    email: Yup.string().nullable().typeError("Please enter your email").email("Please enter a valid email"),
                    // postcode: Yup.string().typeError("Please enter a postcode").required("Please enter your postcode"),
                    contact: Yup.string().nullable().typeError("Please enter your contact"),
                })
            ),
            centreId : Yup.number().required("Please select a centre"),
            idsource: Yup.number().nullable().typeError("Please select the lead source"),
            relatedParentId: Yup.number().nullable().typeError("Please select a related parent").test(
                "Referral requires parent",
                "Please select a parent who referred this family",
                function (relatedParentId) {
                    if (this.parent.idsource == 1) {
                        return relatedParentId != null
                    }
                    return true
                }
            ),
        });
        this.familyApi = new Family()
        this.state={
            centres : []
        }
    }
 
    componentDidMount() {
        this.familyApi.GetCentres((centres) => {
            this.setState({centres : centres})
        })
    }
  
    render() {
        var studentId = this.props.studentId
        var postSubmitHandler = this.props.postSubmitHandler
        return (
            <>
                <Formik
                  initialValues={{
                    parents : [
                        {
                            firstName: "",
                            lastName: "",
                            email: "",
                            contact: "",
                        },{
                            firstName: "",
                            lastName: "",
                            email: "",
                            contact: "",
                        },
                    ],
                    centreId : '',
                  }}
                  validationSchema={this.validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                        var firstParent = values.parents[0]
                        if (firstParent.firstName == "" || firstParent.lastName == "" || firstParent.email == "" || firstParent.contact == "") {
                            setSubmitting(false)
                            this.props.setMessages([{type : "danger", message : "At least one parent details need to be filled"}])
                            return
                        }
                        if (values.idsource != null) {
                            values.source = {
                              idsource : values.idsource,
                              relatedParentId : values.relatedParentId
                            }
                        }
                        this.familyApi.AddFamilyForStudent(studentId, values, (res,err) => {
                            setSubmitting(false)
                            postSubmitHandler(res,err)
                        })
                  }}>
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) =>
                    (
                        <Form onSubmit={handleSubmit}>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold' style={{alignSelf : "center"}}>Centre :</Col>
                                <Col className='flex-grow-2'>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="demo-simple-select-outlined-label">Centre</InputLabel>
                                        <Select 
                                            fullWidth
                                            value={values.centreId}
                                            name="centreId"
                                            onChange={handleChange}
                                            labelId="demo-simple-select-outlined-label" 
                                            id="demo-simple-select-outlined" label="Centre" size='small'>
                                            {this.state.centres.map(centre => 
                                                <MenuItem key={centre.idcentre} value={centre.idcentre}>{centre.name}</MenuItem>
                                            )}
                                        </Select>
                                        {touched.centreId && errors.centreId && <FormHelperText error={true}>{errors.centreId}</FormHelperText>}
                                    </FormControl>
                                </Col>
                            </Row>
                            <FamilySourceFields values={values} errors={errors} touched={touched} handleChange = {handleChange} isSubmitting = {isSubmitting} setFieldValue = {setFieldValue}/>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold'> Parent/Guardian 1</Col>
                                <Col className='txtBold'> Parent/Guardian 2</Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <FieldArray
                                    name="parents"
                                    render={arrayHelpers => (
                                    <Col>
                                    <Row>
                                        {values.parents.map((parent, index) => (
                                            <Col key={index}>
                                                <Row style={{margin : "1rem 0"}}>
                                                    <Col className='txtBold' style={{alignSelf : "center"}}>First Name :</Col>
                                                    <Col className='flex-grow-2'>
                                                        <TextField type="text" placeholder="First Name" variant="outlined" 
                                                            inputProps={{style:{padding:"0.5rem"}}}
                                                            // InputProps={singleLineTextFieldProps}
                                                            value={parent.firstName} name={`parents[${index}].firstName`}                                       
                                                            onChange={handleChange}
                                                            error={(getIn(touched,`parents[${index}].firstName`) && getIn(errors,`parents[${index}].firstName`)) != undefined}
                                                            helperText={(getIn(touched,`parents[${index}].firstName`) && getIn(errors,`parents[${index}].firstName`))}
                                                        ></TextField>
                                                    </Col>
                                                </Row>
                                                <Row style={{margin : "1rem 0"}}>
                                                    <Col className='txtBold' style={{alignSelf : "center"}}>Last Name :</Col>
                                                    <Col className='flex-grow-2'>
                                                        <TextField type="text" placeholder="Last Name" variant="outlined" 
                                                            inputProps={{style:{padding:"0.5rem"}}}
                                                            // InputProps={singleLineTextFieldProps}
                                                            value={parent.lastName} name={`parents[${index}].lastName`}                                       
                                                            onChange={handleChange}
                                                            error={(getIn(touched,`parents[${index}].lastName`) && getIn(errors,`parents[${index}].lastName`)) != undefined}
                                                            helperText={(getIn(touched,`parents[${index}].lastName`) && getIn(errors,`parents[${index}].lastName`))}
                                                        ></TextField>
                                                    </Col>
                                                </Row>
                                                <Row style={{margin : "1rem 0"}}>
                                                    <Col className='txtBold' style={{alignSelf : "center"}}>Email :</Col>
                                                    <Col className='flex-grow-2'>
                                                        <TextField type="text" placeholder="Email" variant="outlined" 
                                                            inputProps={{style:{padding:"0.5rem"}}}
                                                            // InputProps={singleLineTextFieldProps}
                                                            value={parent.email} name={`parents[${index}].email`}                                       
                                                            onChange={handleChange}
                                                            error={(getIn(touched,`parents[${index}].email`) && getIn(errors,`parents[${index}].email`)) != undefined}
                                                            helperText={(getIn(touched,`parents[${index}].email`) && getIn(errors,`parents[${index}].email`))}
                                                        ></TextField>
                                                    </Col>
                                                </Row>
                                                <Row style={{margin : "1rem 0"}}>
                                                    <Col className='txtBold' style={{alignSelf : "center"}}>Phone :</Col>
                                                    <Col className='flex-grow-2'>
                                                        <TextField type="text" placeholder="Phone" variant="outlined" 
                                                            inputProps={{style:{padding:"0.5rem"}}}
                                                            // InputProps={singleLineTextFieldProps}
                                                            value={parent.contact} name={`parents[${index}].contact`}                                       
                                                            onChange={handleChange}
                                                            error={(getIn(touched,`parents[${index}].contact`) && getIn(errors,`parents[${index}].contact`)) != undefined}
                                                            helperText={(getIn(touched,`parents[${index}].contact`) && getIn(errors,`parents[${index}].contact`))}
                                                        ></TextField>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))}
                                    </Row>
                                    </Col>
                                    )}
                                />
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col style={{textAlign : "right"}}>
                                    <Button className='greenButton' variant="secondary" type="submit" disabled={isSubmitting}>
                                        Done
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                  }
                </Formik>
            </>
        );
    }
}

export default withMessageManager(withRouter(AddFamilyForStudentForm));
