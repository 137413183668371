import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import FullScreenSpinner from '../../../Components/FullScreenSpinner/FullScreenSpinner'
import { withRouter } from 'react-router-dom';
import { Formik,} from "formik";
import * as Yup from "yup";
import Family from '../../../Models/Family';

class EditParentForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.validationSchema = Yup.object().shape({
            firstName: Yup.string().typeError("Please enter your first name").required("Please enter your first name"),
            lastName: Yup.string().typeError("Please enter your last name").required("Please enter your last name"),
            email: Yup.string().email().required("Please enter your email"),
            contact: Yup.string().nullable().typeError("Please enter your contact"),
        });
        this.state={
            family : undefined,
            parent : undefined,
        }
        this.familyApi = new Family()
    }
 
    componentDidMount() {
        var parent = this.props.parent
        this.familyApi.GetFamilyByStudentId(this.props.studentId, (family,err) => {
            this.setState({family : family, parentCopy : parent})
        })
    }
  
    render() {
        var parent = this.props.parent
        var postSubmitHandler = this.props.postSubmitHandler
        if (!parent) {
            return(<></>)
        }
        return (
            <>
                <Formik
                    initialValues={{
                        firstName: parent.firstName,
                        lastName: parent.lastName,
                        email: parent.email,
                        contact: parent.contact,
                    }}
                    validationSchema={this.validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                            var updateParent = values
                            var centreId = this.state.family.centreId
                            updateParent.parentId = parent.idparent
                            updateParent.oldEmail = parent.email
                            this.familyApi.EditParent(updateParent,centreId, (res,err) => {
                                setSubmitting(false)
                                postSubmitHandler(res,err)
                            })
                }}>
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) =>
                    (
                        <Form onSubmit={handleSubmit}>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold'> Edit Parent/Guardian</Col>
                            </Row>
                                <Row style={{margin : "1rem 0"}}>
                                    <Col className='txtBold' style={{alignSelf : "center"}}>First Name :</Col>
                                    <Col className='flex-grow-2'>
                                        <TextField type="text" placeholder="First Name" variant="outlined" 
                                            inputProps={{style:{padding:"0.5rem"}}}
                                            // InputProps={singleLineTextFieldProps}
                                            value={values.firstName} name={'firstName'}                                       
                                            onChange={handleChange}
                                            error={(touched.firstName &&  errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                        ></TextField>
                                    </Col>
                                </Row>
                                <Row style={{margin : "1rem 0"}}>
                                    <Col className='txtBold' style={{alignSelf : "center"}}>Last Name :</Col>
                                    <Col className='flex-grow-2'>
                                        <TextField type="text" placeholder="Last Name" variant="outlined" 
                                            inputProps={{style:{padding:"0.5rem"}}}
                                            // InputProps={singleLineTextFieldProps}
                                            value={values.lastName} name={`lastName`}                                       
                                            onChange={handleChange}
                                            error={(touched.lastName &&  errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                        ></TextField>
                                    </Col>
                                </Row>
                                <Row style={{margin : "1rem 0"}}>
                                    <Col className='txtBold' style={{alignSelf : "center"}}>Email :</Col>
                                    <Col className='flex-grow-2'>
                                        <TextField type="text" placeholder="Email" variant="outlined" 
                                            inputProps={{style:{padding:"0.5rem"}}}
                                            // InputProps={singleLineTextFieldProps}
                                            value={values.email} name="email"                                     
                                            onChange={handleChange}
                                            error={(touched.email &&  errors.email)}
                                            helperText={touched.email && errors.email}
                                        ></TextField>
                                    </Col>
                                </Row>
                                <Row style={{margin : "1rem 0"}}>
                                    <Col className='txtBold' style={{alignSelf : "center"}}>Phone :</Col>
                                    <Col className='flex-grow-2'>
                                        <TextField type="text" placeholder="Phone" variant="outlined" 
                                            inputProps={{style:{padding:"0.5rem"}}}
                                            // InputProps={singleLineTextFieldProps}
                                            value={values.contact} name="contact"
                                            onChange={handleChange}
                                            error={(touched.contact &&  errors.contact)}
                                            helperText={touched.contact && errors.contact}
                                        ></TextField>
                                    </Col>
                                </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col style={{textAlign : "right"}}>
                                    <Button className='greenButton' variant="secondary" type="submit" disabled={isSubmitting}>
                                        Done
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                  }
                </Formik>
            </>
        );
    }
}

export default withRouter(EditParentForm);
