import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Form} from 'react-bootstrap'
import {MessageContext} from '../../../Helpers/messages/MessageRenderer.js'
import { TextField } from '@material-ui/core'
import { Formik } from "formik";
import * as Yup from "yup";
import FullScreenSpinner from "../../FullScreenSpinner/FullScreenSpinner.js";
import Teacher from '../../../Models/Teacher'
import UserAccount from '../../../Models/UserAccount'
import AccessController from "../../../AccessController/AccessController.js";


import {AuthContext} from '../../../Helpers/auth/auth.js';
/* eslint-disable */
class TeacherAccountPage extends React.Component{
    constructor(props, context) {
        super(props,context)
        this.teacherApi = new Teacher()
        this.userAccApi = new UserAccount()
        this.accessController = new AccessController()
        this.state = {
            teacher : null,
            username : "",
            accountId : null,
            readOnly : {readOnly: true}
        }
        this.teacherId = props.match.params.teacherId
        this.teacherValidationScheme = Yup.object().shape({
            username : Yup.string().required("Please enter your username"),
            password : Yup.string().required("Please enter your password")
        });
    }

    getFormInitialValues() {
        var teacher = this.state.teacher
        return {
            initialUsername: "",
            initialPW: teacher.initialPW,
            username: teacher.username,
            password: teacher.password,
        }
    }

    componentDidMount() {
        this.teacherApi.GetTeacher(this.teacherId, (teacher) => {
            this.setState({teacher : teacher, accountId : teacher.accountId})
            this.userAccApi.GetUserAccount(this.state.accountId, (res) => {
                this.setState({username : res.username})
            })
        })
    }

    UpdateTeacherAccount = (teacher,setSubmitting) => {
        this.userAccApi.UpdateUserAccount(this.state.accountId, teacher, (resp,error) => {
            setSubmitting(false)
            if (resp) {
                this.props.setMessages([{ type: "success", message: "Teacher's account successfully updated" }]);
                this.toPrevious()
            } else {
                this.props.setMessages([{ type: "danger", message: error }]);
            }
        })
    }
    

    renderForm() {
        if (this.state.teacher === null) {
            return(<FullScreenSpinner />)
        }
        var init = this.getFormInitialValues();
        return(
            <AuthContext.Consumer>
            {authManager => {
                console.log(authManager)
            return (
                <Row>
                    <Col xs={12} style={{padding : "10px"}}>
                        <Formik
                            initialValues={init}
                            validationSchema={this.adminValidationScheme}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                console.log(values)
                                var teacher = {}
                                teacher.initialUsername = this.state.username
                                teacher.initialPW = values.initialPW
                                teacher.username = values.username
                                teacher.password = values.password
                                teacher.accountId = this.state.accountId
                                this.UpdateTeacherAccount(teacher,setSubmitting)
                            }}
                        >
                        {({values,errors,touched,handleChange,setFieldValue,handleSubmit,isSubmitting})=> 
                        ( 
                        <Form onSubmit={handleSubmit} className="mx-auto">
                            {this.showScreener(isSubmitting)}
                            {((authManager.user.accountId === this.state.accountId)|| (this.accessController.UserCanResetCredentials(authManager.user.accessLevel))) && (
                            <div>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="username">
                                            <TextField id = "initalUsername" label="Teacher's username" variant="outlined"  
                                                style={{width : "100%"}}
                                                onChange={handleChange}
                                                value={this.state.username}
                                                name="initialUsername"
                                                required
                                                error={(touched.username && errors.username) !== undefined} helperText={touched.username && errors.username}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="password">
                                            <TextField id = "initalPW" label="Enter new password" variant="outlined"  
                                                style={{width : "100%"}}
                                                onChange={handleChange}
                                                name="initialPW"
                                                type="password"
                                                required
                                                error={(touched.password && errors.password) !== undefined} helperText={touched.password && errors.password}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col>
                                        <Form.Group controlId="username">
                                            <TextField id="username" label="New Username" variant="outlined"  
                                                style={{width : "100%"}}
                                                onChange={handleChange}
                                                name="username"
                                                required
                                                error={(touched.username && errors.username) !== undefined} helperText={touched.username && errors.username}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="password">
                                            <TextField id="password" label="New Password" variant="outlined"  
                                                style={{width : "100%"}}
                                                onChange={handleChange}
                                                name="password"
                                                type="password"
                                                required
                                                error={(touched.password && errors.password) !== undefined} helperText={touched.password && errors.password}/>
                                        </Form.Group>
                                    </Col>
                                </Row> */}
                                <Row style={{marginTop : "20px"}}>
                                <Col style={{textAlign : "right"}}>
                                    <Button 
                                    variant = "secondary"
                                    onClick={() => {this.toPrevious()}} disabled={isSubmitting}>Cancel</Button>
                                    <Button 
                                    variant ="info"
                                    style = {{marginLeft : "30px"}}
                                    type="submit" disabled={isSubmitting}>Update Account</Button>
                                </Col>
                                </Row>
                            </div>
                            )}
                            {/* {((authManager.user.adminId !== undefined) 
                            && (authManager.user.adminId !== null)) && (
                            <div>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="username">
                                            <TextField id="username" label="New Username" variant="outlined"  
                                                style={{width : "100%"}}
                                                onChange={handleChange}
                                                name="username"
                                                required
                                                error={(touched.username && errors.username) !== undefined} helperText={touched.username && errors.username}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="password">
                                            <TextField id="password" label="New Password" variant="outlined"  
                                                style={{width : "100%"}}
                                                onChange={handleChange}
                                                name="password"
                                                type="password"
                                                required
                                                error={(touched.password && errors.password) !== undefined} helperText={touched.password && errors.password}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{marginTop : "20px"}}>
                                    <Col style={{textAlign : "right"}}>
                                        <Button 
                                        variant = "secondary"
                                        onClick={() => {this.toPrevious()}} disabled={isSubmitting}>Cancel</Button>
                                        <Button 
                                        variant ="info"
                                        style = {{marginLeft : "30px"}}
                                        type="submit" disabled={isSubmitting}>Update Account</Button>
                                    </Col>
                                </Row>
                            </div>
                            )} */}
                            </Form>
                        )}
                        </Formik>
                    </Col>
                </Row>
            )
            }}
            </AuthContext.Consumer>
        )
    }

    toPrevious() {
        this.props.history.goBack()
    }
    showScreener(show) {
        if (show) {
            return (<FullScreenSpinner></FullScreenSpinner>)
        } else {
            return 
        }
    }
    render() {
        return (
            <Container style={{marginTop:"3%", marginBottom:"3%"}}>
                <Row>
                    <Col xs={12} lg={{span : 10, offset : 1}}>
                        <Row >
                            <Col style={{padding : "10px", textAlign : "left", fontSize : "24px", fontWeight : "bold"}}>
                                <div>
                                    Teacher's Account : {this.state?.teacher?.firstName} {this.state?.teacher?.lastName}
                                </div>
                            </Col>
                            <Col style={{padding : "10px", textAlign : "left", fontSize : "24px", fontWeight : "bold"}}>
                                <div>Username : {this.state.username}</div>
                            </Col>
                        </Row>
                        {this.renderForm()}
                    </Col>
                </Row>
            </Container>
        )
    }
}
class TeacherAccountPageWrapper extends React.Component {
    render() {
        return (
            <MessageContext.Consumer>
                { ({messages, setMessages}) => (<TeacherAccountPage messages={messages} setMessages ={setMessages} history={this.props.history} {...this.props}></TeacherAccountPage>)}
            </MessageContext.Consumer>
        )
    }
}
export default withRouter(TeacherAccountPageWrapper);