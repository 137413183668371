import React from 'react';
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import {MessageContext} from '../../../Helpers/messages/MessageRenderer.js'

import { Container, Row, Col, Button, Form} from 'react-bootstrap'
import { TextField } from '@material-ui/core'
import LessonList from '../../Lesson/LessonList/LessonList'
import FullScreenSpinner from "../../FullScreenSpinner/FullScreenSpinner.js";
import Curriculum from '../../../Models/Curriculum';
import Student from '../../../Models/Student'
import MyCheckbox from '../../MyCheckbox/MyCheckbox.js'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
class CurriculumPage extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {
            curriculum : undefined,
            graduated : undefined,
            certificate : undefined,
            graduatedPrize : undefined, 
            graduatedBadge : undefined,
            note : "",
            milestonePrize : undefined,
            milestoneBadge : undefined,
            student : null,
            filterCurriculum : "all"
        }
        this.studentApi = new Student()
        this.curriculumApi = new Curriculum()
        this.curriculumId = props.match.params.curriculumId
        this.studentId = props.match.params.studentId
        this.propsForSelect = {
            border: '1px solid #212529',
            borderRadius: '6px',
            fontSize: '0.875rem',
            opacity: '1',
            paddingLeft: '5px'
          };
    }

    componentDidMount(){
        this.studentApi.GetStudent(this.studentId, (student) => {
            this.setState({student : student})
        })
        if (this.studentId !== undefined) {
            this.curriculumApi.GetCurriculumProgress(this.curriculumId, this.studentId, (curriculum) => {
                this.setState({curriculum : curriculum})
            })
            this.curriculumApi.GetCurriculumMilestone(this.studentId, this.curriculumId, (milestone) => {
                if(milestone.note !== null){
                    this.setState({note : milestone.note})
                }
                if(milestone.graduated === 1){
                    this.setState({graduated : true})
                }else{
                    this.setState({graduated : false})
                }
                if(milestone.certificate === 1){
                    this.setState({certificate : true})
                }else{
                    this.setState({certificate : false})
                }
                if(milestone.graduatedPrize === 1){
                    this.setState({graduatedPrize : true})
                }else{
                    this.setState({graduatedPrize : false})
                }
                if(milestone.graduatedBadge === 1){
                    this.setState({graduatedBadge : true})
                }else{
                    this.setState({graduatedBadge : false})
                }
                if(milestone.milestonePrize === 1){
                    this.setState({milestonePrize : true})
                }else{
                    this.setState({milestonePrize : false})
                }
                if(milestone.milestoneBadge === 1){
                    this.setState({milestoneBadge : true})
                }else{
                    this.setState({milestoneBadge : false})
                }
            })
        } else {
            this.curriculumApi.GetCurriculum(this.curriculumId, (curriculum) => {
                this.setState({curriculum : curriculum})
            })
        }
    }

    toPrevious() {
        this.props.history.goBack()
    }

    handleGraduatedCheckBox = (graduated) => {
        this.setState(({graduated}));
    }
    handleGraduatedBadgeCheckBox = (graduatedBadge) => {
        this.setState(({graduatedBadge}));
    }
    handleMilestoneBadgeCheckBox = (milestoneBadge) => {
        this.setState(({milestoneBadge}));
    }
    handleCertificateCheckBox = (certificate) => {
        this.setState(({certificate}));
    }
    handleGraduatedPrizeCheckBox = (graduatedPrize) => {
        this.setState(({graduatedPrize}));
    }
    handleMilestonePrizeCheckBox = (milestonePrize) => {
        this.setState(({milestonePrize}));
    }

    UpdateCurriculumMilestone = (milestone,setSubmitting) => {
        this.curriculumApi.UpdateCurriculumMilestone(this.studentId, this.curriculumId, milestone,(resp,error) => {
            setSubmitting(false)
            if (resp) {
                this.props.setMessages([{ type: "success", message: "Student successfully updated" }]);
                this.toPrevious()
            } else {
                this.props.setMessages([{ type: "danger", message: error }]);
            }
        })
    }

    render() {
        var milestoneBadge = this.state.milestoneBadge
        var curriculum = this.state.curriculum
        var student = this.state.student
        if (curriculum === undefined || milestoneBadge === undefined || student === null) {
            return (<FullScreenSpinner />)
        }
        var init = {
            graduated : this.state.graduated,
            certificate : this.state.certificate,
            graduatedPrize : this.state.graduatedPrize,
            graduatedBadge : this.state.graduatedBadge,
            note : this.state.note,
            milestonePrize : this.state.milestonePrize,
            milestoneBadge : this.state.milestoneBadge
        }
        return (
            <>
            <Container style={{marginTop:"3%", marginBottom:"3%"}}>
            <Row>
                <Col style={{textAlign : "left"}}>
                    <h1 style={{margin:0}}>
                        {curriculum.name}
                    </h1>
                </Col>
            </Row>
            <Row style={{marginTop:"3%", textAlign:"left"}}>
                <Col>
                    <p style={{fontWeight:"700", fontSize : "1.3rem", margin:0}}>Student</p>
                </Col>
            </Row>
            <Row style={{marginTop:"1%", borderRadius : "6px", border : "1px solid #c0c0c0", width : "40%"}}>
                <Col style={{textAlign:"left"}}>
                    <div style={{fontWeight:"500", fontSize:"1.2rem", padding : "15px"}}>
                        <span >{student.firstName} {student.lastName}</span>
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop:"3%", marginBottom:"1%"}}>
                <Col style={{textAlign : "left", fontSize : "1.3rem", fontWeight : "700"}}>
                    <p style={{fontWeight:"700", fontSize : "1.3rem", margin:0}}>Curriculum Milestone</p>
                </Col>
            </Row>
            <Row style={{padding:"2% 0", marginBottom:"3%", borderRadius : "6px", border : "1px solid #c0c0c0"}}>
                <Col>
                    <Formik
                        initialValues={init}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            var milestone = {}
                            milestone.graduated = this.state.graduated
                            milestone.certificate = this.state.certificate
                            milestone.graduatedPrize = this.state.graduatedPrize
                            milestone.graduatedBadge = this.state.graduatedBadge
                            milestone.note = values.note
                            milestone.milestonePrize = this.state.milestonePrize
                            milestone.milestoneBadge = this.state.milestoneBadge
                            this.UpdateCurriculumMilestone(milestone, setSubmitting)
                        }}>
                        {({values,errors,touched,handleChange,setFieldValue,handleSubmit,isSubmitting})=> 
                        ( 
                            <Form onSubmit={handleSubmit} className="mx-auto">
                                <Row>
                                    <Col>
                                        <Form.Group controlId="note">
                                            <TextField
                                                id="note"
                                                label="Notes"
                                                multiline
                                                rows={7}
                                                placeholder="Enter notes"
                                                variant="outlined"
                                                value={values.note}
                                                fullWidth
                                                onChange={handleChange}
                                                />
                                        </Form.Group>
                                    </Col>
                                    <Col style = {{justifyContent:"space-around", textAlign:"left", padding:"0px"}} xs={2}>
                                        { (curriculum.hasMilestone === 1) && (
                                            <Form.Group controlId="milestoneBadge">
                                                <MyCheckbox name="milestoneBadge" id="milestoneBadge" label="Badge (Milestone)" selected={this.state.milestoneBadge} 
                                                onChange={this.handleMilestoneBadgeCheckBox} value={values.milestoneBadge}/>
                                            </Form.Group>
                                        )}
                                        <Form.Group controlId="graduated">
                                            <MyCheckbox name="graduated" id="graduated" label="Graduated" selected={this.state.graduated} 
                                                        onChange={this.handleGraduatedCheckBox} value={values.graduated}/>
                                        </Form.Group>
                                        <Form.Group controlId="graduatedBadge">
                                            <MyCheckbox name="graduatedBadge" id="graduatedBadge" label="Badge" selected={this.state.graduatedBadge} 
                                                        onChange={this.handleGraduatedBadgeCheckBox} value={values.graduatedBadge}/>
                                        </Form.Group>
                                    </Col>
                                    <Col style = {{justifyContent:"space-around", textAlign:"left"}} xs={3}>
                                        { (curriculum.hasMilestone === 1) && (
                                            <Form.Group controlId="milestonePrize">
                                                <MyCheckbox name="milestonePrize" id="milestonePrize" label="Spin The Wheel (Milestone)" selected={this.state.milestonePrize} 
                                                onChange={this.handleMilestonePrizeCheckBox} value={values.milestonePrize}/>
                                            </Form.Group>
                                        )}
                                        <Form.Group controlId="certificate">
                                            <MyCheckbox name="certificate" id="certificate" label="Certificate Collected" selected={this.state.certificate} 
                                                        onChange={this.handleCertificateCheckBox} value={values.certificate}/>
                                        </Form.Group>
                                        <Form.Group controlId="graduatedPrize">
                                            <MyCheckbox name="graduatedPrize" id="graduatedPrize" label="Spin The Wheel" selected={this.state.graduatedPrize} 
                                                        onChange={this.handleGraduatedPrizeCheckBox} value={values.graduatedPrize}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{paddingTop:"20px"}}>
                                    <Col>
                                        <Button variant="info" style={{fontSize : "0.8rem"}} disabled={isSubmitting} type="submit" >
                                            Update Milestone
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
            <Row>
                <Col xs={9} style={{textAlign: 'right', margin: 'auto'}}>
                <span style={{fontWeight: 'bold', fontSize: '1.2rem'}}>Filter by:</span>
                </Col>
                <Col style={{textAlign: 'left', paddingRight: 0}}>
                <FormControl variant="outlined" style={{width:"100%", paddingBottom:0}}>
                    <InputLabel style={{color:"light gray", opacity:"0.65"}}>Filter by:</InputLabel>
                        <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Packages"
                        size='small'
                        name="filterCurriculum"
                        value={this.state.filterCurriculum}
                        onChange={(e)=>{this.setState({filterCurriculum : e.target.value})}}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="completed">Completed</MenuItem>
                            <MenuItem value="notcompleted">Not Completed</MenuItem>
                        </Select>
                </FormControl>
                    {/* <FormControl style={{ width: '100%'}}>
                            <Select
                            style={this.propsForSelect}
                            disableUnderline
                            id="demo-mutiple-chip"
                            name="filterCurriculum"
                            value={this.state.filterCurriculum}
                            onChange={(e)=>{this.setState({filterCurriculum : e.target.value})}}
                            input={<Input id="select-multiple-chip" />}
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="completed">Completed</MenuItem>
                                <MenuItem value="notcompleted">Not Completed</MenuItem>
                            </Select>
                    </FormControl> */}
                </Col>
            </Row>
            <LessonList lessons={curriculum.lessons} studentId={this.studentId} filter={this.state.filterCurriculum} />
            </Container>
            </>
        )
    }
}

class CurriculumPageWrapper extends React.Component {
    render() {
        return (
            <MessageContext.Consumer>
                { ({messages, setMessages}) => (<CurriculumPage messages={messages} setMessages ={setMessages} history={this.props.history} {...this.props}></CurriculumPage>)}
            </MessageContext.Consumer>
        )
    }
}
export default withRouter(CurriculumPageWrapper);