import React from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col} from 'react-bootstrap'
import ProjectList from '../../Project/ProjectList/ProjectList'
class LessonListItem extends React.Component { 
    render() {
        var lesson = this.props.lesson
        var projects = lesson.projects
        var filterProject = projects.filter((project) => {
            console.log(project)
            switch(this.props.filter)
            {
                case "completed":
                    return project.isCompleted
                case "notcompleted":
                    return !project.isCompleted
                case "all":
                    return true
                default:
                    break;
            }
        })
        var projectWithHeader = projects.find((project) => {
            return project.isMilestone < 0
          })
        return (
            <>
            {filterProject.length ? (
            <Row style={{borderRadius : "15px", marginTop : "10px",  marginBottom : "10px"}}>
                <Col >
                    {projectWithHeader && (
                        <Row>
                            <Col>
                            <span style={{fontWeight:"800", fontSize:"1.3rem"}}>
                                {projectWithHeader.isMilestone === -1 && ("EV3 Mindstorm MakeCode Projects (For ages 9 & 10)")}
                                {projectWithHeader.isMilestone === -2 && ("EV3 Classroom App (For ages 11 & above)")}
                            </span>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={12} >
                            <div style={{textAlign : "left", padding : "10px", fontSize : "1.2rem", fontWeight : "bold"}}>
                                {lesson.name}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span : 12, offset : 0}}>
                            <ProjectList projects={filterProject} studentId={this.props.studentId} filter={this.props.filter} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            ) : <></>}
            </>
        )
    }
}
export default withRouter(LessonListItem);