import React from 'react';
import { withRouter } from "react-router-dom";
import TeacherProjectListItem from './TeacherProjectListItem.js'
class TeacherProjectList extends React.Component { 
    render() {
        return (
            <>
            {
                this.props.projects.map((project,i) => {
                    return (<TeacherProjectListItem
                        teacherId = {this.props.teacherId}
                        project={project} 
                        key={i} />)
                })
            }
            </>
        )
    }
}
export default withRouter(TeacherProjectList);