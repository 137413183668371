import React from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col, Button, Form} from 'react-bootstrap'
import {MessageContext} from '../../../Helpers/messages/MessageRenderer.js'

import { TextareaAutosize, TextField} from '@material-ui/core'
import {
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import { Formik } from "formik";
import * as Yup from "yup";

import Checked from "../../../Assets/Icons/checked.png"
import Project from '../../../Models/Project.js'
import './ProjectListItem.css'
class ProjectListItem extends React.Component { 
    constructor(props) {
        super(props)
        this.project = props.project
        this.state = {
            completed : this.project.isCompleted
        }
        this.commentValidationScheme = Yup.object().shape({
            // comment : Yup.string().required("Please enter a comment"),
            initials : Yup.string().required("Please enter your initials"),
        });
        this.projectApi = new Project();
    }
    handleSubmitForm(values,setSubmitting) {
        var completed = this.state.completed
        var comment = values.comment
        var completedDate = values.completedDate
        var initials = values.initials
        if (completed) {
            // update comment
            this.projectApi.UpdateCommentForTeacher(this.props.teacherId,this.project.idproject,comment, initials, completedDate, (res,err) => {
                if (!(err !== null && err !== undefined)) {
                    this.props.setMessages([{type : "success", message : "Comment Updated"}])
                } else {
                    this.props.setMessages([{type : "danger", message : "Failed to Update Comment"}])
                }
                window.scrollTo(0,0)
            })
        } else {
            // mark as registered
            this.projectApi.MarkProjectAsCompletedForTeacher (this.props.teacherId,comment,this.project.idproject, initials, completedDate, (res,err) => {
                if (!(err !== null && err !== undefined)) {
                    this.props.setMessages([{type : "success", message : `${this.project.name} marked as completed`}])
                    this.setState({completed : true})
                } else {
                    this.props.setMessages([{type : "danger", message : `Failed to mark ${this.project.name} as completed! Error : ${err}`}])
                }
                window.scrollTo(0,0)
            })
        }
        setSubmitting(false)
    }
    renderForm() {
        if (this.props.project.isCompleted === undefined) {
            return ( <></> )
        }
        var project = this.props.project
        var init = {
            comment : this.props.project.comment,
            initials : this.props.project.initials,
            completedDate : this.props.project.completedDate ? this.props.project.completedDate : new Date()
        }
        return(
            <Row>
                <Col xs={12} style={{padding : "10px"}}>
                    <Formik
                        initialValues={init}
                        validationSchema={this.commentValidationScheme}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            this.handleSubmitForm(values,setSubmitting)
                        }}
                    >
                    {({values,errors,touched,handleChange,setFieldValue,handleSubmit,isSubmitting,setSubmitting})=> 
                    ( 
                        <Form onSubmit={handleSubmit} className="mx-auto">
                            <Row>
                                <Col xs={{span : 6}}>
                                    <Form.Group controlId="comment">
                                        <TextareaAutosize id="firstName" label="Comment" 
                                            style={{width : "100%"}}
                                            defaultValue={values.comment} 
                                            onChange={handleChange}
                                            name="comment"
                                            placeholder = "Enter your comments"
                                            rowsMax={4}
                                            rowsMin={3}
                                            // required
                                            />
                                    </Form.Group>
                                </Col>
                                <Col xs={{span : 3}}>
                                    <Form.Group controlId="initials">
                                        <TextField id="initials" label="Marked By"
                                        className="mytextfield"
                                        style={{width : "100%"}}
                                        defaultValue={values.initials} 
                                        onChange={handleChange}
                                        name="initials"
                                        placeholder = "Enter your initials"
                                        required />
                                    </Form.Group>
                                </Col>
                                <Col xs={{span : 3}}>
                                    <Form.Group controlId="date">
                                        <KeyboardDatePicker
                                            className="datePickerNoMarginTop"
                                            disableToolbar
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="Completion Date"
                                            value={values.completedDate}
                                            onChange={(date) => { 
                                                setFieldValue("completedDate",date)}}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                {(project.isMilestone === 1) && (
                                    <span style={{fontWeight:"700", fontSize:"0.9rem"}}>
                                        Note: After the completion of {project.name}, please complete and tick the milestone checkboxes.
                                    </span>
                                )}
                                </Col>
                                <Col style={{textAlign : "right"}} xs={6}>
                                    {
                                    !this.state.completed && 
                                    <Button 
                                    variant ="info"
                                    style={{fontSize : "0.8rem", marginRight :"10px"}}
                                    onClick={()=>{
                                        this.handleSubmitForm(values,setSubmitting,true)
                                    }} disabled={isSubmitting}>
                                        Update Comment
                                    </Button>
                                    }
                                    {
                                    <Button 
                                    variant ="info"
                                    style={{fontSize : "0.8rem", marginRight :"10px"}}
                                    type="submit" disabled={isSubmitting}>
                                        {this.state.completed ? "Update Comment" : "Mark Project As Done"}
                                    </Button>
                                    }
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col style={{textAlign : "right"}}>
                                    <Button 
                                    variant ="info"
                                    style={{fontSize : "0.8rem", marginRight :"10px"}}
                                    type="submit" disabled={isSubmitting}>
                                        {this.state.completed ? "Update Comment" : "Mark Project As Done"}
                                    </Button>
                                </Col>
                            </Row> */}
                        </Form>
                    )}</Formik>
                </Col>
            </Row>
                        
        )
    }
    render() {
        var project = this.props.project
        return (
            <Row style={{borderRadius : "6px", border : "1px solid #c0c0c0", backgroudnColor : "", marginTop : "10px",  marginBottom : "10px"}}>
                <Col >
                    <Row>
                        <Col xs={10}  style={{textAlign : "left", padding : "10px", fontSize : "1rem", fontWeight : "normal"}}>
                            {project.name}
                        </Col>
                        <Col style={{display : "flex", alignItems : "center"}}>
                            {project.isCompleted && (
                                <img src={Checked} style={{marginLeft : "Auto"}}/>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} style={{textAlign : "left", padding : "10px", fontSize : ".8rem", fontWeight : "normal"}}>
                            {this.renderForm()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
class ProjectListItemWrapper extends React.Component {
    render() {
        return (
            <MessageContext.Consumer>
                { ({messages, setMessages}) => (<ProjectListItem messages={messages} setMessages ={setMessages} history={this.props.history} {...this.props}></ProjectListItem>)}
            </MessageContext.Consumer>
        )
    }
}
export default withRouter(ProjectListItemWrapper);