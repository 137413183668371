import React, { Component } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, TextField, Tooltip} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { withRouter } from 'react-router-dom';
import { withMessageManager } from '../../../../Helpers/messages/MessageRenderer'
import { withAuthManager } from '../../../../Helpers/auth/auth'
import { Formik } from "formik";
import * as Yup from "yup";
import moment from 'moment'
import CreditApi from '../../../../Models/Credit'

/*
isEditing : boolean
studentId : int
onFormSubmit : (form) => {}
initForm : {}
*/
class PurchasePackageForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            modalConfirm: false,
            packages : [],
            formValues : props.initForm,
            packagePrice: 0,
        }
        this.validationSchema = Yup.object().shape({
            packageId: Yup.number().typeError("Please select a package").required("Please select a package"),
            purchaseDate: Yup.date().typeError("Please enter purchase package").required("Please enter purchase package"),
        });
        this.creditApi = new CreditApi()
    }
    openModal(modal){
        this.setState({[modal]: true})
    }
    closeModal(modal){
        this.setState({[modal]: false})
    }
    componentDidMount() {
        this.init()
    }
    init() {
        this.creditApi.GetPackages((packages) => {
            packages.sort((a, b) => {return a.price - b.price})
            this.setState({packages : packages})
        })
    }
    handlePrice(rentalMode,packageId,setFieldValue){
        if(packageId != null){
            let selectedPackage = this.state.packages.find(p => p.idpackage === packageId)
            let price = selectedPackage.price
            let price_withoutrental = selectedPackage.price_withoutrental
            if(rentalMode == null || rentalMode == 1 || rentalMode == 2){
                setFieldValue("price",price)
            }else{
                setFieldValue("price",price_withoutrental)
            }
        }else{
            setFieldValue("price",0)
        }
    }
    renderConfirmModal(){
        return(
        <>
        <Modal show={this.state.modalConfirm} onHide={()=> this.closeModal('modalConfirm')}>
        <Modal.Body>
            <Modal.Title>Please confirm that you wish to {this.props.isEditing ? "edit": "add"} credits</Modal.Title>
        </Modal.Body>
        <Modal.Footer>
            <Button className='greenButton' onClick={() => {
                var values = this.state.formValues
                values.package = this.state.packages.find((pkg) => {
                    return pkg.idpackage == values.packageId
                })
                if (values != null) {
                    this.props.onFormSubmit(values)
                }
            }}>Yes</Button>
            <Button className='greyButton' onClick={()=> this.closeModal('modalConfirm')}>Cancel</Button>
        </Modal.Footer>
        </Modal>
        </>
        )
    }
 
    renderForm(){
        var values = this.state.formValues
        return(
            <Formik
                initialValues={{
                    packageId: values.packageId,
                    purchaseDate : values.purchaseDate,
                    price : values.price,
                    rentalMode: values.rentalMode,
                }}
                validationSchema={this.validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                        this.setState({formValues : values})
                        setSubmitting(false)
                        this.openModal('modalConfirm')
            }}>
            {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) =>
                (
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={6}>
                                <p style={{marginBottom:0}}>Purchase Date</p>
                            </Col>
                            <Col xs={6}>
                                <p>Credits/Validity</p>
                            </Col>
                        </Row>
                        <Row style={{marginBottom:"15px"}}>
                            <Col xs={6}>
                                <KeyboardDatePicker
                                    className="fs-12" label="Date of purchase" fullWidth
                                    disableFuture={true} inputVariant="outlined" format="dd/MM/yyyy"
                                    InputProps={{
                                        onBlur : (val) => {
                                            var date = moment(val.target.value,"DD/MM/YYYY")
                                            if (date.isValid()) {
                                                setFieldValue("purchaseDate", date.toDate())
                                            }
                                        }
                                    }}
                                    value={values.purchaseDate} 
                                    onChange={(date) => {setFieldValue("purchaseDate", date)}}
                                />
                            </Col>
                            <Col xs={6}>
                                <FormControl variant="outlined" style={{width:"100%", paddingBottom:0}}>
                                    <InputLabel id="package-label" style={{color:"light gray", opacity:"0.65"}}>Select Package</InputLabel>
                                    <Select
                                        label="Select Package"
                                        size='small'
                                        name="packageId"
                                        value = {values.packageId}
                                        onChange={(e, v) => { 
                                            setFieldValue("packageId",v.props.value)
                                            this.handlePrice(values.rentalMode, v.props.value,setFieldValue) 
                                        }}
                                    >
                                        <MenuItem key={-1} value={undefined}>Select a package</MenuItem>
                                        {this.state.packages.map(packageObj => 
                                            <MenuItem key={packageObj.idpackage} value={packageObj.idpackage}>{packageObj.description}</MenuItem>
                                        )}
                                    </Select>
                                    {touched.packageId && errors.packageId && <FormHelperText error={true}>{errors.packageId}</FormHelperText>}
                                </FormControl>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <p style={{marginBottom:0}}>Device Rental</p>
                            </Col>
                            <Col xs={6}>
                                <span>Package Price</span>
                                <Tooltip
                                    title={"Click here to view full price"}>
                                    <HelpIcon style={{ color: "#27bdbe", height: "20px", width: "20px", marginLeft: "5px", marginBottom: "5px", cursor: "pointer" }} onClick={() => window.open("/pricelist", "_blank")} />
                                </Tooltip>
                            </Col>
                        </Row>
                        <Row style={{marginBottom:"15px"}}>
                            <Col xs={6}>
                                <FormControl variant="outlined" style={{width:"100%", paddingBottom:0}}>
                                    <InputLabel style={{color:"light gray", opacity:"0.65"}}>Device Rental</InputLabel>
                                    <Select
                                        size='small'
                                        name="rentalMode"
                                        label="Device Rental"
                                        value = {values.rentalMode}
                                        onChange={(e, v) => { 
                                            setFieldValue("rentalMode",v.props.value)
                                            this.handlePrice(v.props.value, values.packageId,setFieldValue) 
                                        }}
                                    >
                                        <MenuItem key={-1} value={undefined}>Select a package</MenuItem>
                                        <MenuItem key={0} value={1}>Yes</MenuItem>
                                        <MenuItem key={1} value={0}>No</MenuItem>
                                        <MenuItem key={2} value={2}>Partial</MenuItem>
                                    </Select>
                                    {touched.packageId && errors.rentalMode && <FormHelperText error={true}>{errors.rentalMode}</FormHelperText>}
                                </FormControl>
                            </Col>
                            <Col xs={6}>
                                <FormControl variant="outlined" style={{width:"100%", paddingBottom:0}}>
                                    <TextField type="price" placeholder="Package Price" variant="outlined" 
                                        value={values.price} name={'price'}                                      
                                        onChange={handleChange}
                                        error={(touched.price &&  errors.price)}
                                        helperText={touched.price && errors.price}
                                    ></TextField>
                                </FormControl>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign : "right", padding: '0 30px'}}>
                                <Button className='fs-14 greenButton' type="submit">Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        )
    }
    render() {
        return (
        <>
            {this.state.modalConfirm && this.renderConfirmModal()}
            {this.renderForm()}
        </>
        );
    }
}

export default withAuthManager(withMessageManager(withRouter(PurchasePackageForm)));
