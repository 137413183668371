import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Form} from 'react-bootstrap'
import {MessageContext} from '../../../Helpers/messages/MessageRenderer.js'

import { TextField, Select, MenuItem, Chip, FormControl, InputLabel} from '@material-ui/core'
import { Formik } from "formik";
import * as Yup from "yup";

import FullScreenSpinner from "../../FullScreenSpinner/FullScreenSpinner.js";
import Teacher from '../../../Models/Teacher'
import Family from '../../../Models/Family'
class TeacherAddPage extends React.Component { 
    constructor(props, context) {
        super(props,context)
        this.teacherApi = new Teacher()
        this.familyApi = new Family()
        this.state = {
            teacher : {
                firstName : "",
                lastName : "",
                username : "",
                password : "",
            },
            centres : []
        }
        this.teacherValidationScheme = Yup.object().shape({
            firstName : Yup.string().required("Please enter your first name"),
            lastName : Yup.string().required("Please enter your last name"),
            username : Yup.string().required("Please enter your username"),
            password : Yup.string().required("Please enter your password")
        });
    }
    componentDidMount() {
        this.familyApi.GetCentres((centres,err) => {
            this.setState({centres : centres ? centres : []})
        })
    }
    getFormInitialValues() {
        var teacher = this.state.teacher
        return {
            firstName: teacher.firstName,
            lastName: teacher.lastName,
            username: teacher.username,
            centreIds : []
        }
    }
    addTeacher(teacher,setSubmitting) {
        this.teacherApi.CreateTeacher(teacher,(resp,error) => {
            setSubmitting(false)
            if (resp) {
                this.props.setMessages([{ type: "success", message: "Teacher successfully registered" }]);
                var interval = setInterval(() => {
                    this.toPrevious()
                    clearInterval(interval)
                }, 3000)
            } else {
                let errormsg = error
                if (error == 'User is not an admin') {
                    errormsg = "User cannot update this teacher due to insufficient access rights or centre rights"
                }
                this.props.setMessages([{ type: "danger", message: errormsg }]);
            }
        })  
    }
    renderForm() {
        var init = this.getFormInitialValues()
        return (
            <Row>
                <Col xs={12} style={{padding : "10px"}}>
                    <Formik
                        initialValues={init}
                        validationSchema={this.teacherValidationScheme}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            var teacher = {}
                            teacher.firstName = values.firstName
                            teacher.lastName = values.lastName
                            teacher.username = values.username
                            teacher.password = values.password
                            teacher.centreIds = values.centreIds
                            this.addTeacher(teacher,setSubmitting)
                        }}
                    >
                    {({values,errors,touched,handleChange,setFieldValue,handleSubmit,isSubmitting})=> 
                    ( 
                        <Form onSubmit={handleSubmit} className="mx-auto">
                        {this.showScreener(isSubmitting)}
                            <Row>
                                <Col>
                                    <Form.Group controlId="username">
                                        <TextField id="username" label="Username" variant="outlined"  
                                            style={{width : "100%"}}
                                            value={values.username} 
                                            onChange={handleChange}
                                            name="username"
                                            required
                                            error={(touched.username && errors.username) !== undefined} helperText={touched.username && errors.username}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="password">
                                        <TextField id="password" label="Password" variant="outlined"  
                                            style={{width : "100%"}}
                                            value={values.password} 
                                            onChange={handleChange}
                                            name="password"
                                            type="password"
                                            required
                                            error={(touched.password && errors.password) !== undefined} helperText={touched.password && errors.password}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="firstName">
                                        <TextField id="firstName" label="First Name" variant="outlined"  
                                            style={{width : "100%"}}
                                            value={values.firstName} 
                                            onChange={handleChange}
                                            name="firstName"
                                            required
                                            error={(touched.firstName && errors.firstName) !== undefined} helperText={touched.firstName && errors.firstName}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="lastName">
                                        <TextField id="lastName" label="Last Name" variant="outlined"  
                                            style={{width : "100%"}}
                                            value={values.lastName} 
                                            onChange={handleChange}
                                            name="lastName"
                                            required
                                            error={(touched.lastName && errors.lastName) !== undefined} helperText={touched.lastName && errors.lastName}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="centre-label" style={{background : 'white'}}>Centres</InputLabel>
                                        <Select
                                            multiple
                                            labelId="centre-label"
                                            name="centreIds"
                                            value={values.centreIds}
                                            onChange={handleChange}
                                            renderValue={(selected) => (
                                                <div style={{
                                                    display: "flex",
                                                    flexWrap : "wrap"
                                                }}>
                                                    {this.state.centres.filter((centre) => {
                                                        return selected.includes(centre.idcentre)
                                                    }).map((value) => (
                                                        <Chip key={value.idcentre} label={value.name} style={{margin : 2}}/>
                                                    ))}
                                                </div>
                                            )}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                    //   maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                    width: 250,
                                                    },
                                                },
                                            }}
                                        >
                                            {this.state.centres.map((centre) => (
                                                <MenuItem key={centre.idcentre} value={centre.idcentre}>
                                                {centre.name}
                                                </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{marginTop : "20px"}}>
                                <Col style={{textAlign : "right"}}>
                                    <Button 
                                    variant = "secondary"
                                    style = {{marginRight : "30px"}}
                                    onClick={() => {this.toPrevious()}} disabled={isSubmitting}>Cancel</Button>
                                    <Button 
                                    variant = "info"
                                    type="submit" disabled={isSubmitting}>Add Teacher</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                    </Formik>
                </Col>
            </Row>
        )
    }
    toPrevious() {
        this.props.history.goBack()
    }
    showScreener(show) {
        if (show) {
            return (<FullScreenSpinner></FullScreenSpinner>)
        } else {
            return 
        }
    }
    render() {
        return (
            <Container>
                <Row>
                    <Col xs={12} lg={{span : 10, offset : 1}}>
                        <Row style={{marginTop : "20px"}}>
                            <Col style={{padding : "10px", textAlign : "left", fontSize : "24px", fontWeight : "bold"}}>
                                Add Teacher
                            </Col>
                        </Row>
                        {this.renderForm()}
                    </Col>
                </Row>
            </Container>
        )
    }
}
class TeacherAddPageWrapper extends React.Component {
    render() {
        return (
            <MessageContext.Consumer>
                { ({messages, setMessages}) => (<TeacherAddPage messages={messages} setMessages ={setMessages} history={this.props.history}></TeacherAddPage>)}
            </MessageContext.Consumer>
        )
    }
}
export default withRouter(TeacherAddPageWrapper);
