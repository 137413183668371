import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal, Image } from 'react-bootstrap';
import { AuthContext } from '../../../Helpers/auth/auth.js';
import { withRouter } from 'react-router-dom';
import { Formik, } from "formik";
import { withMessageManager } from '../../../Helpers/messages/MessageRenderer'
import { IsMobileView } from '../../../Helpers/Helper';
import FullScreenSpinner from '../../../Components/FullScreenSpinner/FullScreenSpinner'
import Credit from '../../../Models/Credit';
import Student from '../../../Models/Student';
import Family from '../../../Models/Family'
import AddClassForm from '../Student/AddClassForm'
import moment from "moment"
import { isRippleEnabled } from '@syncfusion/ej2-base';
import BackArrow from '../../../Assets/Icons/arrows/back-arrow.jpg';
import NextArrow from '../../../Assets/Icons/arrows/next-arrow.jpg';
import { TextField } from '@material-ui/core';

const DOTS = "...";

class ViewStudentCredit extends Component {
    constructor(props, context) {
        super(props, context)
        this.studentApi = new Student()
        this.creditApi = new Credit()
        this.familyApi = new Family()
        this.state = {
            loaded : false,
            family: null,
            transactions: null,
            balanceForStudents: null,
            modalClass: false,
            modalDeleteClass: false,
            editingClass: null,
            deletingClass: null,
            width: 0,
            height: 0,
            pageCount: 0,
            currentPage: 1, //1 as in first page
            currentData: [],
            pageSize: 5,
            siblingCount:1 // no. of elements around the current page number. Eg when 1: ... 8 *9* 10 ...
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    
    openModal(modal) {
        this.setState({ [modal]: true })
    }

    closeModal(modal) {
        this.setState({ [modal]: false })
    }
    
    componentDidMount() {
        this.init();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.currentPage !== this.state.currentPage) {
            const currentData = this.getCurrentPageData(this.state.transactions);
            this.setState({currentData});
        }
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    
    getCurrentPageData(data) {
        const firstPageIndex= this.state.pageSize * (this.state.currentPage-1);
        const lastPageIndex = firstPageIndex + this.state.pageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }

    getStyle(i) {
        let output;
        // left margin for all pages except page 1
        i === 1 ? output = {} : output = {marginLeft:"2%"}; 
        
        // using the theme colour for the current page number
        if (i === this.state.currentPage) output = {...output, backgroundColor: "#09d3ac", boxShadow: "#09d3ac"}
        return output;
    }

    leftClick() {
        this.setState(prevState => {
            return {currentPage: prevState.currentPage === 1 ? prevState.currentPage : parseInt(prevState.currentPage) -1}
        })
    }

    rightClick() {
        this.setState(prevState => {
            return {currentPage: prevState.currentPage === this.state.pageCount ? prevState.currentPage : parseInt(prevState.currentPage) +1}
        })
    }

    init() {
        var familyId = this.props.match.params.familyId
        this.familyApi.GetFamilyById(familyId, (family, err) => {
            if (err) {
                this.props.setMessages([{type : "danger", message : err}])
            }
            if (family && family.studentIds instanceof Array && family.studentIds.length > 0) {
                this.creditApi.GetTransactions(family.studentIds[0], (transactions) => {
                    const currentData = this.getCurrentPageData(transactions);
                    const pageCount = Math.ceil(transactions.length / this.state.pageSize);
                    this.setState({ transactions, currentData, pageCount })
                    family.students.forEach((student, index) => {
                        let tempArr = []
                        transactions.forEach((transaction) => {
                            if (transaction.studentId == student.idstudent) {
                                tempArr.push(transaction)
                            }
                        })
                        family.students[index].transactions = tempArr
                        this.setState({ family: family })
                    })
                })
                this.creditApi.GetBalance(family.studentIds[0], (balance, err) => {
                    this.setState({ balanceForStudents: balance, loaded : true })
                })
            } else {
                this.setState({ loaded : true, transactions: [], balanceForStudents : 0})
            }
            
        })
    }
    
    renderCreditDetails() {
        var balance = this.state.balanceForStudents
        var expired = null
        var expiry = null
        if (balance != null) {
            expiry = moment(balance.expiryDate)
            var now = moment()
            if (now.isAfter(expiry, 'day')) {
                expired = true
            }
            balance.expiry = expiry.format("DD MMM YYYY")
            balance.expired = expired
        }
        return (
            <Container>
                <Row className="creditBox">
                    <Col className="creditBoxContent">
                        <Row>
                            <Col xs={7} md={3} className='txtBold fs-16' style={{fontWeight: 600, marginTop:'2%'}}>No. Of Credits Available</Col>
                            <Col xs={5} md={9} className='txtGrey fs-16' style={{marginTop:'2%'}}>{expired ? 0 : balance.credit}</Col>
                        </Row>
                        <Row>
                            <Col xs={7} md={3} className='txtBold fs-16' style={{fontWeight: 600, marginTop:'2%'}}>Expiry Date</Col>
                            <Col xs={5} md={9} className='txtGrey fs-16'style={{marginTop:'2%'}} >{balance.expiry != "Invalid date" ? balance.expiry : "N.A."}</Col>
                        </Row>
                        <Row style={{ marginBottom: "20px" }}>
                            <Col xs={7} md={3} className='txtBold fs-16' style={{fontWeight: 600, marginTop: '2%'}}>No. Of Credits Expired</Col>
                            <Col xs={5} md={9} className='txtGrey fs-16' style={{marginTop:'2%'}}>{expired ? balance.credit : 0}</Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }

    range(start,end) {
        let length = end - start + 1;
        /*
            Create an array of certain length and set the elements within it from
            start value to end value.
        */
        return Array.from({ length }, (_, idx) => idx + start);
    }

    getPageNumberArray() {
        let outputArray;
        const totalPageNumbers = this.state.siblingCount + 5;

        /*
            Case 1: just show the entire range of numbers, short enough
        */
        if (totalPageNumbers >= this.state.pageCount) {
            return this.range(1, this.state.pageCount);
        }

        // sibling indices are the left and right numbers of the current page
        const leftSiblingIndex = Math.max(this.state.currentPage - this.state.siblingCount, 1); // to make sure it doesn't go past 1
        const rightSiblingIndex = Math.min(
            this.state.currentPage + this.state.siblingCount,
            this.state.pageCount
        );
        const shouldShowLeftDots = leftSiblingIndex > 3;
        const shouldShowRightDots = rightSiblingIndex < this.state.pageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = this.state.pageCount;

        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * this.state.siblingCount;
            let leftRange = this.range(1, leftItemCount);
            if (leftItemCount + 1 >= this.state.pageCount) {
                // nothing between last item in range and the final page number
                outputArray = [...leftRange, this.state.pageCount];
            }
            else {
                outputArray = [...leftRange, DOTS, this.state.pageCount];
            }
            return outputArray;
        }

        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {
            
            let rightItemCount = 3 + 2 * this.state.siblingCount;
            let rightRange = this.range(
                this.state.pageCount - rightItemCount + 1,
                this.state.pageCount
            );
            if (this.state.pageCount - rightItemCount <= 1) {
                // nothing between first item in range and the first page number
                outputArray = [firstPageIndex, ...rightRange];
            } else {
                outputArray = [firstPageIndex, DOTS, ...rightRange];
            }
            return outputArray;
        }
        
        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = this.range(leftSiblingIndex, rightSiblingIndex);
            outputArray = [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
        
        return outputArray;
    }

    renderPageButtons() {
        let outputArray = this.getPageNumberArray();
        return outputArray.map((i) => {
            return (
                <Button style={this.getStyle(i)} variant={this.state.currentPage === i ? "custom" : "outline-secondary"} disabled = {i === DOTS} onClick={() => i !== DOTS && this.setState({currentPage: i})}>
                    {i}
                </Button>
            )
        })
    }
    render() {
        var family = this.state.family
        var transactions = this.state.currentData; // rendering only currentData for the page
        if (family === undefined || !this.state.balanceForStudents || !transactions) {
            return (<FullScreenSpinner />)
        }
        var totalCreditsUsed = this.state.transactions.filter(transaction => transaction.relatedClass != null).reduce((total, currentItem) => {
            return total + currentItem.credits * -1
        }, 0)
        return (
            <AuthContext.Consumer>
                {authManager => (
                    <Container style={{ paddingTop: '40px', paddingBottom: '40px' }}>
                        <Row>
                            <Col className='fs-24'
                                style={{
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                }}
                            >
                                Credits Available
                            </Col>
                        </Row>
                        {this.renderCreditDetails()}
                        <div className="tableContainer">
                            <Row style={{ marginBottom: '20px', marginTop: "40px" }}>
                                <Col className='txtBold fs-24' style={{ textAlign: 'left' }}>Transactions</Col>
                                {!IsMobileView(this.state.width) && (<Col className='txtBold fs-24' style={{ textAlign: 'right' }}>Total Credits Used: {totalCreditsUsed}</Col>)}
                            </Row>
                            {/* {this.renderCreditTable(transactions)} */}
                            <Row className='txtBold creditBorderTop onlyBottomBorder' style={{ marginLeft: "0", marginRight: "0" }}>
                                {IsMobileView(this.state.width) ? (
                                        // mobile
                                    <>
                                        <Col xs={3} className='timeRow fs-16'>Time</Col>
                                        <Col xs={2} className='creditRow fs-16'>Credits</Col>
                                        <Col className='tableRow fs-16'>Date</Col>
                                    </>
                                ) : (
                                    <>
                                        <Col md={2} className='tableRow fs-16'>Time Start</Col>
                                        <Col md={2} className='tableRow fs-16'>Time End</Col>
                                        <Col md={3} className='tableRow fs-16'>Credits Transacted</Col>
                                        <Col md={3} className='tableRow fs-16'>Transaction Date</Col>
                                    </>
                                )}
                                <Col xs={2} md={2} className='studentRow  fs-16'>Student</Col>
                            </Row>
                            {transactions != undefined ?
                                transactions.length != 0 ?
                                    transactions.map(transaction =>
                                        <Row key={transaction.idtransaction} className='creditBorder onlyBottomBorder' style={{ marginLeft: "0", marginRight: "0" }}>
                                        {IsMobileView(this.state.width) ? (
                                            // mobile
                                            <Col xs={3} className='timeRow fs-16'>{transaction.relatedClass != null ?  `${moment(transaction.relatedClass.starttime).format("hh:mma")} - ${moment(transaction.relatedClass.endtime).format("hh:mma")}` : "N.A."}</Col>
                                        ) : (
                                            <>
                                                <Col className='fs-16' md={2}>{transaction.relatedClass != null ? moment(transaction.relatedClass.starttime).format("hh:mma") : "-"}</Col>
                                                <Col className='fs-16' md={2}>{transaction.relatedClass != null ? moment(transaction.relatedClass.endtime).format("hh:mma") : "-"}</Col>
                                            </>
                                        )}
                                            <Col xs={2} md={3} className='creditRow fs-16'>{transaction.relatedClass != null ? transaction.credits * -1 : transaction.credits}</Col>
                                            <Col xs={5} md={3} className='tableRow fs-16'>{moment(transaction.purchasedate).format("DD/MM/YYYY")}</Col>
                                            <Col xs={2} md={2} className='studentRow fs-16'>{transaction.student.firstName}</Col>
                                        </Row>
                                    ) : <Row className='creditBorder' style={{ marginLeft: "0", marginRight: "0" }}>
                                        <Col>No transaction</Col>
                                    </Row>
                                :
                                <Row className='creditBorder' style={{ marginLeft: "0", marginRight: "0" }}>
                                    <Col>No transaction</Col>
                                </Row>}
                        </div>
                        {this.state.transactions != undefined && this.state.transactions.length > 0 && (
                            <Row style={{marginTop:'5%',display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <img src={BackArrow}  className="clickable arrow" onClick={() => this.leftClick()}/>
                                <TextField
                                    error={this.state.currentPage < 1 || this.state.currentPage > this.state.pageCount}
                                    name="pageNumber"
                                    placeholder="Page Number"
                                    type="numeric"
                                    variant="outlined"
                                    size="small"
                                    value={this.state.currentPage}
                                    onChange={e => this.setState({currentPage: e.target.value})}
                                    style={{width: '60px', marginRight:'5px', textAlign:'center'}}
                                    inputProps={{style: { textAlign: 'center'}}}
                                />
                                of {this.state.pageCount}
                                <img src={NextArrow}  className="clickable arrow" onClick={() => this.rightClick()}/>
                            </Row>
                         )}
                        
                        {IsMobileView(this.state.width) && (
                        <Row style={{ marginTop: "40px" }}>
                            <Col className='txtBold fs-24' style={{ textAlign: 'left' }}>Total Credits Used: {totalCreditsUsed}</Col>
                        </Row>
                        )}
                    </Container>
                )}
            </AuthContext.Consumer>
        )
    }
}

export default withRouter(withMessageManager(ViewStudentCredit))