import { createMuiTheme } from "@material-ui/core";
const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#27bdbe",
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          // backgroundColor: lightBlue.A200,
          // color: "white",
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: "#27bdbe",
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: "#27bdbe",
        },
      },
    },
});
export {materialTheme}