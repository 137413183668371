import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Form} from 'react-bootstrap'
import {MessageContext} from '../../../Helpers/messages/MessageRenderer.js'
import { withAuthManager } from '../../../Helpers/auth/auth'
import AccessController from '../../../AccessController/AccessController'
import MyCheckbox from '../../MyCheckbox/MyCheckbox.js'

import { TextField } from '@material-ui/core'
import { Formik } from "formik";
import * as Yup from "yup";

import FullScreenSpinner from "../../FullScreenSpinner/FullScreenSpinner.js";
import FullScreenModal from "../../FullScreenModal/FullScreenModal.js";
import Student from '../../../Models/Student'
import Curriculum from '../../../Models/Curriculum'
import addIcon from '../../../Assets/Icons/addIcon.png'
import FamilyDetails from './StudentFamilyPanel'
import CreditDetails from './StudentCreditPanel'

import CurriculumList from '../../Curriculum/CurriculumList/CurriculumList'
class StudentProfilePage extends React.Component { 
    constructor(props, context) {
        super(props,context)
        this.studentApi = new Student()
        this.curriculumApi = new Curriculum()
        this.permissions = new AccessController()
        this.state = {
            student : null,
            curriculums : [],
            curriculumsAvailable : [],
            showCurriculumSelection : false,
            showCurriculumSelectionConfirmation : false,
            newCurriculumChosen : null,
            note: null,
            dsa : null,
            noteIsNew : true,
            candyAllowed : null,
            tshirtCollected : null,
            deviceRental : null,
            sharingCredits : null
        }
        this.studentId = props.match.params.studentId
        this.studentValidationScheme = Yup.object().shape({
            firstName : Yup.string().required("Please enter your first name"),
            lastName : Yup.string().required("Please enter your last name")
        });
    }
    onHideCurriculumSelectionModal() {
        this.setState({showCurriculumSelection : false})
    }

    refreshStudentCurriculum() {
        this.studentApi.GetStudentCurriculum(this.studentId, (curriculums) => {
            this.setState({curriculums : curriculums})
        })
    }

    refresh() {
        this.setState({student : null}, () => {
            this.studentApi.GetStudent(this.studentId, (student) => {
                this.setState({student : student})
            })
        })
    }

    componentDidMount() {
        this.studentApi.GetStudent(this.studentId, (student) => {
            this.setState({student : student})
        })
        this.studentApi.GetStudentChecklist(this.studentId, (studentChecklist) => {
            if(studentChecklist !== null){
                if(studentChecklist.device_rental === 1){
                    this.setState({deviceRental : true})
                }else{
                    this.setState({deviceRental : false})
                }
                if(studentChecklist.tshirt_collected === 1){
                    this.setState({tshirtCollected : true})
                }else{
                    this.setState({tshirtCollected : false})
                }
                if(studentChecklist.candy_allowed === 1){
                    this.setState({candyAllowed : true})
                }else{
                    this.setState({candyAllowed : false})
                }
                if(studentChecklist.sharing_credits === 1){
                    this.setState({sharingCredits : true})
                }else{
                    this.setState({sharingCredits : false})
                }
            }
        })
        this.studentApi.GetStudentNote(this.studentId, (studentNote) => {
            if(studentNote !== null){
                this.setState({noteIsNew : false})
                this.setState({note : studentNote})
            }else{
                this.setState({note : "", dsa : ""})
            }
        })
        this.refreshStudentCurriculum()
        this.curriculumApi.ListCurriculum((curriculums) => {
            this.setState({curriculumsAvailable : curriculums})
        })
    }
    updateStudent = (student,setSubmitting) => {
        this.studentApi.UpdateStudent(this.studentId, student,(resp,error) => {
            setSubmitting(false)
            if (resp) {
                this.props.setMessages([{ type: "success", message: "Student successfully updated" }]);
                window.location.reload()
            } else {
                this.props.setMessages([{ type: "danger", message: error }]);
            }
        })
    }

    handleSubmitNote(studentNote, setSubmitting){
        var noteIsNew = this.state.noteIsNew
        if(noteIsNew){
            this.studentApi.CreateStudentNote(studentNote, this.studentId,(resp,error) => {
                setSubmitting(false)
                if (error == null) {
                    this.props.setMessages([{ type: "success", message: "Student successfully updated" }]);
                } else {
                    this.props.setMessages([{ type: "danger", message: error }]);
                }
            })
        }
        else{
            this.studentApi.UpdateStudentNote(studentNote, this.studentId,(resp,error) => {
                setSubmitting(false)
                if (error == null) {
                    this.props.setMessages([{ type: "success", message: "Student successfully updated" }]);
                } else {
                    this.props.setMessages([{ type: "danger", message: error }]);
                }
            }) 
        }
    }

    handleDeviceRentalCheckBox = (deviceRental) => {
        this.setState(({deviceRental}));
        var checklistObj = {
            device_rental : deviceRental
        }
        this.studentApi.UpdateStudentChecklist(checklistObj, this.studentId, (resp,error) => {
            if (resp) {
                this.props.setMessages([{ type: "success", message: "Student successfully updated" }]);
            } else {
                this.props.setMessages([{ type: "danger", message: error }]);
            }
        })
    }
    handleTshirtCollectedCheckBox = (tshirtCollected) => {
        this.setState(({tshirtCollected}));
        var checklistObj = {
            tshirt_collected : tshirtCollected
        }
        this.studentApi.UpdateStudentChecklist(checklistObj, this.studentId, (resp,error) => {
            if (resp) {
                this.props.setMessages([{ type: "success", message: "Student successfully updated" }]);
            } else {
                this.props.setMessages([{ type: "danger", message: error }]);
            }
        })
    }
    handleCandyAllowedCheckBox = (candyAllowed) => {
        this.setState(({candyAllowed}));
        var checklistObj = {
            candy_allowed : candyAllowed
        }
        this.studentApi.UpdateStudentChecklist(checklistObj, this.studentId, (resp,error) => {
            if (resp) {
                this.props.setMessages([{ type: "success", message: "Student successfully updated" }]);
            } else {
                this.props.setMessages([{ type: "danger", message: error }]);
            }
        })
    }
    toPrevious() {
        this.props.history.goBack()
    }

    renderStudentNote(){
        if (this.state.note === null){
            return(<FullScreenSpinner />)
        }
        var init = {
            note : this.state.note.note ? this.state.note.note : "",
            dsa : this.state.note.dsa ? this.state.note.dsa : ""
        }
        return(
            <Container>
            <Row>
                <Col style={{padding:0}}>
                    <Formik
                        initialValues={init}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            var studentNote = {}
                            studentNote.note = values.note
                            studentNote.dsa = values.dsa
                            this.handleSubmitNote(studentNote, setSubmitting)
                        }}
                        >
                        {({values,errors,touched,handleChange,setFieldValue,handleSubmit,isSubmitting})=> 
                        ( 
                            <Form onSubmit={handleSubmit} className="mx-auto">
                                <Row>
                                    <Col >
                                        <Form.Group controlId="note">
                                            <TextField
                                                id="note"
                                                label="Notes"
                                                multiline
                                                rows={9}
                                                placeholder="Enter notes"
                                                variant="outlined"
                                                value={values.note}
                                                fullWidth
                                                onChange={handleChange}
                                                />
                                        </Form.Group>
                                    </Col>
                                    <Col >
                                        <Form.Group controlId="dsa">
                                            <TextField
                                                id="dsa"
                                                label="DSA"
                                                multiline
                                                rows={9}
                                                placeholder="Enter DSA notes"
                                                variant="outlined"
                                                value={values.dsa}
                                                fullWidth
                                                onChange={handleChange}
                                                />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:"0.5%"}}>
                                    <Col style={{textAlign : "right", padding: '0 45px'}}>
                                        <Button className='fs-14 greenButton' variant ="info" type="submit" disabled={isSubmitting}>
                                        {this.state.noteIsNew ? "Create Note" : "Update Note"}
                                        </Button>
                                    </Col>
                                </Row>
                        </Form>
                    )}
                    </Formik>
                </Col>
            </Row>
            </Container>
        )
    }

    renderStudentChecklist(){
        var candyAllowed = this.state.candyAllowed
        var deviceRental = this.state.deviceRental
        var tshirtCollected = this.state.tshirtCollected
        if (candyAllowed === null || deviceRental === null || tshirtCollected === null) {
            return(<FullScreenSpinner />)
        }
        var init = {
            candyAllowed : candyAllowed,
            deviceRental : deviceRental,
            tshirtCollected : tshirtCollected,
        }

        return(
            <Container>
            <Formik
                initialValues={init}
                >
                {({values,errors,touched,handleChange,setFieldValue,handleSubmit,isSubmitting})=> 
                ( 
                    <Form onSubmit={handleSubmit} className="mx-auto">
                        <Row style={{paddingTop:"16px",marginBottom:"20px", borderRadius : "4px", border : "1px solid #c0c0c0"}}>
                            <Col style={{textAlign:"left"}}>
                                <MyCheckbox name="deviceRental" id="deviceRental" label="Device Rental" selected={this.state.deviceRental} 
                                    onChange={this.handleDeviceRentalCheckBox} 
                                    value={values.deviceRental}
                                />
                                <MyCheckbox name="tshirtCollected" id="tshirtCollected" label="T-Shirt Collected" selected={this.state.tshirtCollected} 
                                    onChange={this.handleTshirtCollectedCheckBox} 
                                    value={values.tshirtCollected}
                                />
                            </Col>
                            <Col style={{textAlign:"left"}}>
                                <MyCheckbox name="candyAllowed" id="candyAllowed" label="Candy Allowed" selected={this.state.candyAllowed} 
                                    onChange={this.handleCandyAllowedCheckBox} 
                                    value={values.candyAllowed}
                                />
                            </Col>
                            <Col>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
            </Container>
        )
    }

    showScreener(show) {
        if (show) {
            return (<FullScreenSpinner></FullScreenSpinner>)
        } else {
            return 
        }
    }
    renderConfirmationModal() {
        var show = this.state.showCurriculumSelectionConfirmation
        var curriculum = this.state.newCurriculumChosen
        if (curriculum === null) {
            return (<></>)
        }
        var studentId = this.studentId
        return (
            <FullScreenModal onCloseModal={() => {
                this.setState({showCurriculumSelectionConfirmation : false})
            }} show={show}>
                <Container>
                    <Row>
                        <Col style={{padding : "10px"}}>
                            <h2 style={{fontSize : "1.5rem"}}>Confirm enroll student into curriculum "{curriculum.name}" ?</h2>
                        </Col>
                    </Row>
                    <Row style={{marginBottom : "10px"}}>
                        
                        <Col style={{textAlign : "right"}}>
                            <Button variant="secondary" style={{marginRight : "10px"}} onClick={()=>{
                                this.setState({showCurriculumSelectionConfirmation : false,showCurriculumSelection : true})}}>Cancel</Button>
                            <Button variant="info" onClick={()=>{
                                this.studentApi.EnrollStudentIntoCurriculum(studentId,curriculum.idcurriculum, (success) => {
                                    if (success) {
                                        this.props.setMessages([{type: "success", message : `You have enrolled the student into ${curriculum.name}`}])
                                    } else {
                                        this.props.setMessages([{type: "danger", message : `Enrollment into ${curriculum.name} failed`}])
                                    }
                                    this.setState({showCurriculumSelectionConfirmation : false, showCurriculumSelection : false})
                                    this.refreshStudentCurriculum()
                                })
                            }}>OK</Button>
                        </Col>
                    </Row>
                </Container>
            </FullScreenModal> 
        )
    }
    renderCurriculumSelectionModal() {
        var show = this.state.showCurriculumSelection
        var curriculumsAvailable = this.state.curriculumsAvailable
        return(
            <Container>
            <FullScreenModal onCloseModal={() => {this.onHideCurriculumSelectionModal()}} show={show}>
                <Container>
                    <Row>
                        <Col style={{padding : "10px"}}>
                            <h2>
                                Choose Curriculum to Add
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span : 10, offset : 1}}>
                            <CurriculumList onChoseCurriculum={(curriculum)=>{
                                this.setState({showCurriculumSelectionConfirmation : true, showCurriculumSelection : false, newCurriculumChosen : curriculum})
                            }} curriculums={curriculumsAvailable}/>
                        </Col>
                    </Row>
                </Container>
            </FullScreenModal>
            </Container>
        )
    }
    render() {
        var showArchiveButton = this.permissions.UserCanRemoveCurriculum(this.props.user.accessLevel)
        if (this.state.student === null) {
            return(<FullScreenSpinner />)
        }
        return (
            <Container style={{marginTop:"3%", marginBottom:"3%"}}>
                {this.renderCurriculumSelectionModal()}
                {this.renderConfirmationModal()}
                <Row>
                    <Col xs={12} lg={{span : 10, offset : 1}}>
                        <Row >
                            <Col className = "fs-24 txtBoldUppercase" style={{padding : "10px", textAlign : "left", fontWeight : "bold"}}>
                                {this.state.student.firstName} {this.state.student.lastName}
                            </Col>
                        </Row>
                        <Row >
                            <Col style={{padding : "10px", textAlign : "left", fontWeight : "bold"}}>
                                <FamilyDetails reload={()=>{
                                    this.refresh()
                                }} style={{margin: '0'}}></FamilyDetails>
                            </Col>
                        </Row>
                        <Row >
                            <Col style={{padding : "10px", textAlign : "left", fontWeight : "bold"}}>
                                <CreditDetails studentId={this.props.match.params.studentId} style={{margin: '0'}}></CreditDetails>
                            </Col>
                        </Row>
                        <Row >
                            <Col style={{padding : "10px", textAlign : "left", fontWeight : "bold"}}>
                                <Row>
                                    <Col xs={12}>
                                    <Row >
                                        <Col className = "fs-24" style={{padding : "10px", textAlign : "left", fontWeight : "bold"}}>
                                            Student Checklist
                                        </Col>
                                    </Row>
                                    {this.renderStudentChecklist()}
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>

                        <Row >
                            <Col style={{padding : "10px", textAlign : "left", fontWeight : "bold"}}>
                                <Row>
                                    <Col xs={12}>
                                    <Row >
                                        <Col className = "fs-24" style={{padding : "10px", textAlign : "left", fontWeight : "bold"}}>
                                            Student Notes
                                        </Col>
                                    </Row>
                                    {this.renderStudentNote()}
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>
                        
                        
                        <Row >
                            <Col className = "fs-24" style={{padding : "10px 10px 0 10px", textAlign : "left", fontWeight : "bold"}}>
                                Curriculums Enrolled
                            </Col>
                        </Row>
                        <CurriculumList curriculums={this.state.curriculums} showArchive={showArchiveButton} viewPath={`/student/${this.studentId}/curriculum`}/>
                        <Row>
                            <Col style={{textAlign : "right"}}>
                                <img className="clickable" src={addIcon} style={{maxHeight: "100%", padding : "10px"}} onClick={() => {
                                    this.setState({showCurriculumSelection : true})
                                }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}
class StudentProfilePageWrapper extends React.Component {
    render() {
        return (
            <MessageContext.Consumer>
                { ({messages, setMessages}) => (<StudentProfilePage messages={messages} setMessages ={setMessages} history={this.props.history} {...this.props}></StudentProfilePage>)}
            </MessageContext.Consumer>
        )
    }
}
export default withAuthManager(withRouter(StudentProfilePageWrapper));
