import React, {Component} from 'react'
import { Day, Week, WorkWeek, TimelineViews, ScheduleComponent, ViewsDirective, ViewDirective, Inject } from '@syncfusion/ej2-react-schedule';
import { extend } from '@syncfusion/ej2-base';
import './Calender.css'

class Calender extends React.Component {
    constructor() {
        super(...arguments);
        this.data = extend([], [
            {
              "Subject": "eat",
              "StartTime": "2020-12-08T02:00:00.000Z",
              "EndTime": "2020-12-08T05:30:00.000Z",
              "IsAllDay": false,
              "StartTimezone": null,
              "EndTimezone": null,
              "RecurrenceRule": null,
              "Id": 1
            }
          ], null, true);
    }
    render() { //Calender Timeslot
        return (
          <>
          <h1>Timeslot</h1>
          {this.data.map(timeslot => {
              let hours = Math.abs(new Date(timeslot.EndTime) - new Date(timeslot.StartTime)) / 3600000
              return <p>{timeslot.Subject}: {hours} hours</p>
          })}
        <ScheduleComponent width='100%' height='550px' ref={schedule => this.scheduleObj = schedule} selectedDate={new Date(2020, 11, 8)} eventSettings={{ dataSource: this.data }} timeScale={{ enable: true, interval: 60, slotCount: 6 }}>
            <ViewsDirective>
                <ViewDirective option='Day'/>
                <ViewDirective option='Week'/>
                <ViewDirective option='WorkWeek'/>
                <ViewDirective option='TimelineDay'/>
            </ViewsDirective>
            <Inject services={[Day, Week, WorkWeek, TimelineViews]}/>
            </ScheduleComponent>
            
            </>
            )
    }
}
;

export default Calender