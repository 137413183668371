const axios = require('axios');
axios.defaults.withCredentials = true;
class Model {
    constructor() {
        this.apiEndPoint = process.env.REACT_APP_BACKENDURL || "http://localhost:4001"
        // this.apiEndPoint = "http://localhost:4001"
    }
    
    delete(path,data,callback) {
        axios.delete(
            this.apiEndPoint + path, 
            { 
                data : data ,
                withCredentials : true
            })
        .then(function (res) {
            if (res.error == "Token has expired, please re log in") {
                window.location = "/login"
                return
            }
            callback(res.data, null)
            return
        }).catch(function (error) {
            callback(null, error)
            return
        });
    }
    get(path, params, callback) {
        axios
        .get(
            this.apiEndPoint + path, 
            { 
                params : params ,
                withCredentials : true
            })
        .then(function (res) {
            if (res.error == "Token has expired, please re log in") {
                window.location = "/login"
                return
            } else {
                // regen token
            }
            callback(res.data, null)
            return
        }).catch(function (error) {
            callback(null, error)
            return
        });
    }
    postReq(path, body, callback){
        axios.post(
            this.apiEndPoint + path,
            body,
            {
                headers: {
                    'Content-Type' : 'application/json',
                },
                withCredentials : true
            })
        .then(function (response) {
            if (response !== null) {
                if (response.error == "Token has expired, please re log in") {
                    window.location = "/login"
                    return
                }
                callback(response.data, null)
                return
            }
        })
        .catch(function (error) {
            console.log(error)
            callback(null, error)
            return
        });
    }
    postArrBuffer(path,body, callback) {
        axios.post(
            this.apiEndPoint + path,
            body,
            {
                headers: {
                    'Content-Type' : 'application/json',
                },
                withCredentials : true,
                responseType : 'arraybuffer'
            })
        .then(function (response) {
            if (response !== null) {
                if (response.error == "Token has expired, please re log in") {
                    window.location = "/login"
                    return
                }
                callback(response.data, null)
                return
            }
        })
        .catch(function (error) {
            console.log(error)
            callback(null, error)
            return
        });
    }
    async GetOpsManual() {
        return await new Promise((res,rej) => {
            this.get("/operation/manual",{},(resp,err) => {
                if (err || resp.error) {
                    res(null)
                }
                res(resp.value)
            })
        })
    }
}
export default Model;
