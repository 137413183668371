import Model from './Model'
class Curriculum {
    constructor() {
        this.model = new Model()
    }
    ListCurriculum(callback) {
        this.model.get(`/curriculum`,null,(curriculums,err) => {
            callback(curriculums.value)
        })
    }
    GetCurriculum(curriculumId,callback) {
        this.model.get(`/curriculum/${curriculumId}`,null,(curriculum,err) => {
            callback(curriculum.value)
        })
    }
    GetCurriculumProgress(curriculumId, studentId, callback) {
        this.model.get(`/student/${studentId}/curriculum/${curriculumId}/progress`,null,(curriculum,err) => {
            callback(curriculum.value)
        })
    }
    GetTeacherCurriculumProgress(curriculumId, teacherId, callback) {
        this.model.get(`/teacher/${teacherId}/curriculum/${curriculumId}/progress`,null,(curriculum,err) => {
            callback(curriculum.value)
        })
    }
    UpdateCurriculumMilestone(studentId, curriculumId, milestone, callback) {
        this.model.postReq(`/student/${studentId}/curriculum/${curriculumId}/milestone_update`, milestone, (res,err) => {
            callback(err === null, err)
        })
    }
    GetCurriculumMilestone(studentId, curriculumId, callback) {
        this.model.get(`/student/${studentId}/curriculum/${curriculumId}/milestone`, null, (res,err) => {
            if(res != null){
                callback(res.value[0])
            }else{
                callback(err)
            }
            
        })
    }
}
export default Curriculum;