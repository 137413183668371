import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Form} from 'react-bootstrap'
import {MessageContext} from '../../../Helpers/messages/MessageRenderer.js'

import { TextField } from '@material-ui/core'
import { Formik } from "formik";
import * as Yup from "yup";

import FullScreenSpinner from "../../FullScreenSpinner/FullScreenSpinner.js";
import Student from '../../../Models/Student'
class StudentAddPage extends React.Component { 
    constructor(props, context) {
        super(props,context)
        this.studentApi = new Student()
        this.state = {
            student : {
                firstName : "",
                lastName : ""
            },
        }
        this.studentValidationScheme = Yup.object().shape({
            firstName : Yup.string().required("Please enter your first name"),
            lastName : Yup.string().required("Please enter your last name")
        });
    }
    getFormInitialValues() {
        var student = this.state.student
        return {
            firstName: student.firstName,
            lastName: student.lastName,
        }
    }
    addStudent(student,setSubmitting) {
        this.studentApi.AddStudent(student,(resp,error) => {
            setSubmitting(false)
            if (resp) {
                this.props.setMessages([{ type: "success", message: "Student successfully registered" }]);
            } else {
                this.props.setMessages([{ type: "danger", message: error }]);
            }
            var interval = setInterval(() => {
                this.toPrevious()
                clearInterval(interval)
            }, 3000)
        })  
    }
    renderForm() {
        var init = this.getFormInitialValues()
        return (
            <Row>
                <Col xs={12} style={{padding : "10px"}}>
                    <Formik
                        initialValues={init}
                        validationSchema={this.studentValidationScheme}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            var student = {}
                            student.firstName = values.firstName
                            student.lastName = values.lastName
                            this.addStudent(student,setSubmitting)
                        }}
                    >
                    {({values,errors,touched,handleChange,setFieldValue,handleSubmit,isSubmitting})=> 
                    ( 
                        <Form onSubmit={handleSubmit} className="mx-auto">
                        {this.showScreener(isSubmitting)}
                            <Row>
                                <Col>
                                    <Form.Group controlId="firstName">
                                        <TextField id="firstName" label="First Name" variant="outlined"  
                                            style={{width : "100%"}}
                                            value={values.firstName} 
                                            onChange={handleChange}
                                            name="firstName"
                                            required
                                            error={(touched.firstName && errors.firstName) !== undefined} helperText={touched.firstName && errors.firstName}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="lastName">
                                        <TextField id="lastName" label="Last Name" variant="outlined"  
                                            style={{width : "100%"}}
                                            value={values.lastName} 
                                            onChange={handleChange}
                                            name="lastName"
                                            required
                                            error={(touched.lastName && errors.lastName) !== undefined} helperText={touched.lastName && errors.lastName}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{marginTop : "20px"}}>
                                <Col style={{textAlign : "right"}}>
                                    <Button 
                                    variant = "secondary"
                                    style = {{marginRight : "30px"}}
                                    onClick={() => {this.toPrevious()}} disabled={isSubmitting}>Cancel</Button>
                                    <Button 
                                    variant = "info"
                                    type="submit" disabled={isSubmitting}>Add Student</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                    </Formik>
                </Col>
            </Row>
        )
    }
    toPrevious() {
        this.props.history.goBack()
    }
    showScreener(show) {
        if (show) {
            return (<FullScreenSpinner></FullScreenSpinner>)
        } else {
            return 
        }
    }
    render() {
        return (
            <Container>
                <Row>
                    <Col xs={12} lg={{span : 10, offset : 1}}>
                        <Row style={{marginTop : "20px"}}>
                            <Col style={{padding : "10px", textAlign : "left", fontSize : "24px", fontWeight : "bold"}}>
                                Add Student
                            </Col>
                        </Row>
                        {this.renderForm()}
                    </Col>
                </Row>
            </Container>
        )
    }
}
class StudentAddPageWrapper extends React.Component {
    render() {
        return (
            <MessageContext.Consumer>
                { ({messages, setMessages}) => (<StudentAddPage messages={messages} setMessages ={setMessages} history={this.props.history}></StudentAddPage>)}
            </MessageContext.Consumer>
        )
    }
}
export default withRouter(StudentAddPageWrapper);
