import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Formik } from "formik";
import { TextField } from '@material-ui/core';
import LoginBackground from '../../../Assets/ParentPortal/Background.png';
import LoginBackgroundMobile from '../../../Assets/ParentPortal/MobileBackground.png';
import LogoEmpirecode from '../../../Assets/ParentPortal/LogoEmpirecode.png';
import { authenticate, AuthContext } from '../../../Helpers/auth/auth';
import FullScreenModal from '../../FullScreenModal/FullScreenModal'
import { MessageContext } from '../../../Helpers/messages/MessageRenderer'
import './Login.css';
import UserAccount from '../../../Models/UserAccount';
import Family from '../../../Models/Family'
import { IsMobileView } from '../../../Helpers/Helper';
import { authenticator } from "otplib";
import QRCode from "qrcode";
import Model from '../../../Models/Model';

class Login extends Component {
  constructor(props, context) {
    super(props, context);
    this.username = React.createRef();
    this.password = React.createRef();
    this.code = React.createRef();
    this.accountApi = new UserAccount();
    this.familyApi = new Family();
    this.state = {
      showForgotPasswordModal: false,
      width: 0,
      height: 0,
      pageState: "login", // set-2fa, 2fa, set-recovery, recovery, login,
      recoveryKey: "", // recovery key for 2fa
      qrCodeUrl: "",
      secretKey: "", // secret key for 2fa
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  login = (authManager, setMessages) => {
    authenticate(
      this.username.value,
      this.password.value,
      authManager,
      (success, userstore) => {
        console.log(userstore);
        if (userstore?.state === "set-2fa") {
          this.setState({ pageState: "set-2fa" })
          const secretKey = authenticator.generateSecret();
          const qrCodeUrl = authenticator.keyuri("Empire Code", "Worksheets System", secretKey);
          QRCode.toDataURL(qrCodeUrl, (err, url) => {
            this.setState({ qrCodeUrl: url, secretKey: secretKey })
          });
        } else if (success && userstore?.state === "set-recovery") {
          this.setState({ pageState: "set-recovery" })
        } else if (success && userstore?.state === "recovery") {
          this.setState({ pageState: "recovery" })
        } else if (success && userstore?.state === "2fa") {
          this.setState({ pageState: "2fa" })
          // this.setState({ secretKey: userstore.secret })
          // const qrCodeUrl = authenticator.keyuri("Empire Code", "Worksheets System", userstore.secret);
          // QRCode.toDataURL(qrCodeUrl, (err, url) => {
          //     this.setState({ qrCodeUrl: url })
          // });
        } else if (success) {
          var path = undefined;
          this.familyApi.GetFamily((res, err) => {
            if (res !== null) {
              if (this.props.location.state !== undefined) {
                path = this.props.location.state.referrer;
              }
              if (path === undefined) {
                this.props.history.push(`/student/gallery/${res.idfamily}`);
              } else {
                this.props.history.push(path);
              }
            }
          })
        } else {
          setMessages([
            {
              type: 'danger',
              message: 'Error : Invalid username and password',
            },
          ]);
        }
      }
    );
  };

  verify2faSetup = (authManager, setMessages) => {
    const code = this.code.value
    const api = new Model();
    api.postReq("/2fa/setup", {
      code: code,
      secret_key: this.state.secretKey
    }, (values, err) => {
      if (values.success) {
        this.setState({ pageState: "login" })
      } else {
        setMessages([{ type: "danger", message: "Error : Invalid code" }])
      }
    })
  }

  setRecovery = (authManager, setMessages) => {
    const recoveryKey = this.recoveryKey
    const api = new Model();
    api.postReq("/2fa/set-recovery-key", {
      recoveryKey: recoveryKey,
    }, (values, err) => {
      if (values.success) {
        this.setState({ pageState: "login" })
      }
    })
  }


  verify2fa = (authManager, setMessages) => {
    const code = this.code.value
    const api = new Model();
    api.postReq("/2fa/verify", {
      code: code,
      secret_key: this.state.secretKey
    }, (values, err) => {
      console.log(values)
      if (values.success) {
        window.location = "/home"
      } else {
        setMessages([{ type: "danger", message: "Error : Invalid code" }])
      }
    })
  }


  requestResetPassword = username => {
    this.accountApi.RequestPasswordReset(username, (res, err) => {
      if (res == null) {
        // this.props.setMessages([{ type: "danger", message: err }])
      } else {
        this.setState({ showForgotPasswordModal: false });
        // this.props.setMessages([{ type: "success", message: "An email has been sent to " + res.email }])
      }
    });
  };
  onHidePopupModal() {
    this.setState({ showForgotPasswordModal: false });
  }
  renderPopupModal() {
    var show = this.state.showForgotPasswordModal;
    return (
      <FullScreenModal
        onCloseModal={() => {
          this.onHidePopupModal();
        }}
        show={show}
        size="lg"
      >
        <div className="modalContainer">
          <Row>
            <Col>
              <p className="fs-16" style={{ fontWeight: '600' }}>
                Enter your email below and we'll send you a link to reset your
                password.
              </p>
            </Col>
          </Row>
          <Formik
            initialValues={{
              username: '',
            }}
            validationSchema={this.validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              var username = values.username;
              this.requestResetPassword(username);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  <TextField
                    name="username"
                    className="forgetpassfield"
                    placeholder="Email address"
                    fullWidth
                    type="email"
                    variant="outlined"
                    value={values.username}
                    onChange={handleChange}
                    inputProps={this.propsForTextFieldInput}
                    InputProps={this.propsForTextFieldInputProps}
                    error={touched.username && errors.username}
                    helperText={touched.username && errors.username}
                  ></TextField>
                </div>
                <div style={{ paddingTop: '15px' }}>
                  <Button
                    className="greenButton"
                    style={{ width: '100%' }}
                    type="submit"
                  >
                    Reset Password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <Row>
            <Col>
              <p className="fs-16" style={{ fontWeight: '600', marginTop: "16px" }}>
                If you do not receive an email within 10 minutes, please check your spam folder first.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="fs-16" style={{ fontWeight: '600' }}>
                If the issue persists, please email us at hello@empirecode.co
              </p>
            </Col>
          </Row>
        </div>
      </FullScreenModal>
    );
  }
  render() {
    return (
      <AuthContext.Consumer>
        {authManager => (
          <>
            {this.renderPopupModal()}
            <MessageContext.Consumer>
              {({ message, setMessages }) => {
                return (
                  <div
                    className="loginBackground"
                    style={
                      IsMobileView(this.state.width) ? {
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '38px'
                      } : {
                        background: `url(${LoginBackground})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '38px',
                      }}
                  >
                    <Row>
                      <Col
                        className="txtBoldUppercase"
                        style={{ fontSize: '40px' }}
                      >
                        Parents Portal
                      </Col>
                    </Row>
                    <Row>
                      <Col className="txtContent">
                        We created a photo gallery for you to track your child’s
                        progress while learning with us.
                      </Col>
                    </Row>
                    {this.state.pageState === "login" && <>

                      <Row style={{ marginTop: '20px' }}>
                        <Col>
                          <Form>
                            <Form.Group>
                              <Form.Row>
                                <Form.Control
                                  className="loginForm"
                                  placeholder="Enter your email"
                                  ref={(ref) => { this.username = ref }}
                                  type="email"
                                ></Form.Control>
                              </Form.Row>
                            </Form.Group>
                            <Form.Group>
                              <Form.Row>
                                <Form.Control
                                  className="loginForm"
                                  placeholder="Enter your password"
                                  ref={(ref) => { this.password = ref }}
                                  type="password"
                                ></Form.Control>
                              </Form.Row>
                            </Form.Group>
                          </Form>
                          <Row>
                            <Col>
                              <Button
                                className="fs-18 loginButton"
                                style={{ textTransform: 'uppercase' }}
                                onClick={() => this.login(authManager, setMessages)}
                              >
                                Login
                              </Button>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: '9px' }}>
                            <Col>
                              <a className="forgetpassword" style={{ cursor: "pointer" }} onClick={() => { this.setState({ showForgotPasswordModal: true }) }}>
                                Reset Password
                              </a>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>}

                    {this.state.pageState === "set-2fa" && <>
                      <Row>
                        <Col>
                          <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                            <p>Set up 2FA</p>
                            <p>Scan the QR code below with your authenticator app</p>
                            <img src={this.state.qrCodeUrl} style={{ width: 300, height: 300 }} />
                            <Form.Control
                              className="code fs-18"
                              placeholder="Enter a 6 digit code"
                              ref={(ref) => { this.code = ref }}
                              type="text"
                              autoComplete="code"
                              style={{ maxWidth: '500px' }}
                            ></Form.Control>
                            <Button
                              className="fs-18 loginButton"
                              style={{ textTransform: 'uppercase', marginTop: "20px" }}
                              onClick={() => this.verify2faSetup(authManager, setMessages)}
                            >
                              Verify
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </>}

                    {this.state.pageState === "set-recovery" && <>
                      <Row>
                        <Col>
                          <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                            <p>Set up recovery key</p>
                            <p>Enter a recovery key</p>
                            <Form.Control
                              className="code fs-18"
                              placeholder="Enter a recovery key"
                              ref={(ref) => { this.recoveryKey = ref }}
                              type="text"
                              autoComplete="recoveryKey"
                              style={{ maxWidth: '500px' }}
                            ></Form.Control>
                            <Button
                              className="fs-18 loginButton"
                              style={{ textTransform: 'uppercase', marginTop: "20px" }}
                              onClick={() => this.setRecovery(authManager, setMessages)}
                            >
                              Set Recovery Key
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </>}

                    {this.state.pageState === "2fa" && <>
                      <Row>
                        <Col>
                          <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                            <p>Open your authenticator app and enter the code below</p>
                            <Form.Control
                              className="code fs-18"
                              placeholder="Enter a 6 digit code"
                              ref={(ref) => { this.code = ref }}
                              type="text"
                              autoComplete="code"
                              style={{ maxWidth: '500px' }}
                            ></Form.Control>
                            <Button
                              className="fs-18 loginButton"
                              style={{ textTransform: 'uppercase', marginTop: "20px" }}
                              onClick={() => this.verify2fa(authManager, setMessages)}
                            >
                              Verify
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </>}

                    {IsMobileView(this.state.width) && (
                      // render image below for mobile
                      <img src={LoginBackgroundMobile} />
                    )}
                  </div>
                );
              }}
            </MessageContext.Consumer>
          </>
        )}
      </AuthContext.Consumer>
    );
  }
}

export default withRouter(Login);
