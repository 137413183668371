import Model from './Model'
class Family {
    constructor() {
        this.model = new Model()
    }
    ListSources(callback) {
        this.model.get(`/source`, {}, (res, err) => {
            callback(res.value, err || (!res.success && res.error))
        })
    }
    ListParents(callback) {
        this.model.get(`/parent/list`, {}, (res, err) => {
            callback(res.value, err || (!res.success && res.error))
        })
    }
    GetFamilyByStudentId(studentId, callback) {
        this.model.get(`/student/${studentId}/family`, {}, (res, err) => {
            callback(res.value, err || (!res.success && res.error))
        })
    }
    GetFamilyById(familyId, callback) {
        this.model.get(`/family/${familyId}`, {}, (res, err) => {
            callback(res && res.value, err || (!res.success && res.error))
        })
    }
    GetCentres(callback) {
        this.model.get(`/family/centre`, {}, (res, err) => {
            callback(res.value, err || (!res.success && res.error))
        })
    }
    /*
    centreId : Int,
    parents : [{
        firstName : String,
        lastName : String,
        email : String,
        contact : String,
        postcode : String
    }]
    */
    AddFamilyForStudent(studentId, family, callback) {
        this.model.postReq(`/student/${studentId}/family`, { centreId: family.centreId, parents: family.parents }, (res, err) => {
            callback(res, err)
        })
    }
    /*
    centreId : Int,
    parents : [{
        firstName : String,
        lastName : String,
        email : String,
        contact : String,
        postcode : String
    }],
    students : [{
        firstName : String,
        lastName : String,
        email : String,
        contact : String, 
        dob : Datetime,
        school : String,
        postcode : String,
    }]
    */
    AddFamily(family, callback) {
        this.model.postReq(`/family/new`, family, (res, err) => {
            callback(res, err)
        })
    }
    /*
    parentId : Int
    firstName : String,
    lastName : String,
    email : String,
    contact : String,
    postcode : String
    */
    EditParent(parent, centreId, callback) {
        this.model.postReq(`/family/parent/update`, {parent: parent, centreId}, (res, err) => {
            callback(res, err)
        })
    }
    GetUploadedFiles(familyId, callback) {
        this.model.get(`/family/${familyId}/upload`, {}, (res, err) => {
            callback(res.value, err || (!res.success && res.error))
        })
    }
    /*
    familyId : Int
    source : {
        idsource : Int,
        relatedParentId : Int?
    }
    */
    UpdateFamilySource(familyId, source, callback) {
        this.model.postReq(`/source`, { familyId: familyId, source: source }, (res, err) => {
            callback(res, err)
        })
    }
    ZipMediaFiles(fileIds, familyId, callback) {
        this.model.postArrBuffer(`/family/${familyId}/upload/proxy`, { fileIds: fileIds }, (data, err) => {
            if (err || data.error) {
                callback(null, err || data.error)
            } else {
                var blob = new Blob([data], { type: 'application/octet-stream' })
                callback(blob, null)
            }
        })
    }
    GetFamily(callback) {
        this.model.get(`/parent/family`, {}, (res, err) => {
            callback(res.value, err)
        })
    }
    // SubscribeToMailchimp(details,callback){
    //     this.model.postReq(`/mailchimp/subscribe`, details, (res,err) => {
    //         callback(res,err)
    //     })
    // }
    
}
export default Family;