import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { TextField, MenuItem, Select, FormHelperText, InputLabel,FormControl } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import FullScreenSpinner from '../../../Components/FullScreenSpinner/FullScreenSpinner'
import { withRouter } from 'react-router-dom';
import Student from '../../../Models/Student'
import Family from '../../../Models/Family'
import { Formik } from "formik";
import * as Yup from "yup";
import moment, { invalid } from'moment'

const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#27bdbe",
        },
      },
      MuiButton: {
          label: {
              color: "#27bdbe"
          }
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          // backgroundColor: lightBlue.A200,
          // color: "white",
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: "#27bdbe",
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: "#27bdbe",
        },
      },
    },
  });

class StudentForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.studentApi = new Student()
        this.familyApi = new Family()
        this.validationSchema = Yup.object().shape({
            firstName: Yup.string().typeError("Please enter your first name").required("Please enter your first name"),
            lastName: Yup.string().typeError("Please enter your last name").required("Please enter your last name"),
            email: Yup.string().typeError("Please enter your email").email("Please enter a valid email"),
            dob: Yup.date().typeError("Please enter a valid date").required("Please enter your date of birth"),
            gender: Yup.string().typeError("Please select your gender").min(1,"Please select your gender").required("Please select your gender"),
            school: Yup.string().typeError("Please enter your school"),
            postcode: Yup.string().typeError("Please enter your postcode"),
            classPreference : Yup.string()
        });
    }
 
    componentDidMount() {}
  
    render() {
        var student = this.props.student || {}
        var submitHandler = this.props.submitHandler
        var postSubmitHandler = this.props.postSubmitHandler
        var familyId = this.props.familyId
        
        return (
            <>
                <Formik
                  initialValues={{
                    firstName: student.firstName || "",
                    lastName: student.lastName || "",
                    email : student.email || "",
                    dob : student.dob || "",
                    gender : student.gender || "",
                    school : student.school || "",
                    postcode : student.postcode || "",
                    contact : student.contact || "",
                    classPreference : student.classPreference || ""
                  }}
                  validationSchema={this.validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    if (submitHandler) {
                        var myStudent = values
                        
                        if (this.props.student) {
                            var studentId = student.studentId
                            myStudent.studentId = studentId
                        }
                        myStudent.familyId = familyId
                        setSubmitting(false)
                        submitHandler(myStudent)
                        return
                    }
                    if (this.props.student) {
                        var studentId = student.studentId
                        this.familyApi.GetFamilyByStudentId(studentId, (family,err) => {
                            this.studentApi.UpdateStudent(studentId, values, family.centreId, (res,err) => {
                                setSubmitting(false)
                                postSubmitHandler(res,err)
                            })
                        })

                    } else {
                        var myStudent = values
                        myStudent.familyId = familyId
                        this.studentApi.AddStudent(myStudent, this.props.centreId, (res,err) => {
                            if(err == null){
                                setSubmitting(false)
                                postSubmitHandler(res,err)
                            }
                        })
                    }
                  }}>
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) =>
                    (
                        <Form onSubmit={handleSubmit}>
                            <Row style={{margin : "1rem 0"}}>
                                <Col>
                                    <TextField
                                        type="text" placeholder="First name" variant="outlined" fullWidth name="firstName"
                                        value={values.firstName} onChange={handleChange}
                                        error={(touched.firstName && errors.firstName) != undefined}
                                        helperText={touched.firstName && errors.firstName}
                                    ></TextField>
                                </Col>
                                <Col>
                                    <TextField
                                        type="text" placeholder="Last name" variant="outlined" fullWidth name="lastName"
                                        value={values.lastName} onChange={handleChange}
                                        error={(touched.lastName && errors.lastName) != undefined}
                                        helperText={touched.lastName && errors.lastName}
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col>
                                    <TextField
                                        type="text" placeholder="Email" variant="outlined" fullWidth name="email"
                                        value={values.email} onChange={handleChange}
                                        error={(touched.email && errors.email) != undefined}
                                        helperText={touched.email && errors.email}
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="demo-simple-select-outlined-label" style={{backgroundColor : 'white'}}>Gender</InputLabel>
                                        <Select 
                                            fullWidth
                                            value={values.gender}
                                            name="gender"
                                            onChange={handleChange}
                                            labelId="demo-simple-select-outlined-label" 
                                            id="demo-simple-select-outlined" size='small'>
                                                <MenuItem value={'M'}>Male</MenuItem>
                                                <MenuItem value={'F'}>Female</MenuItem>
                                        </Select>
                                        {touched.gender && errors.gender && <FormHelperText error={true}>{errors.gender}</FormHelperText>}
                                    </FormControl>
                                </Col>
                                <Col>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel style={{backgroundColor : 'white'}}>Class Preference</InputLabel>
                                        <Select 
                                            fullWidth
                                            value={values.classPreference}
                                            name="classPreference"
                                            onChange={handleChange}
                                            size='small'>
                                                <MenuItem value={'NA'}>NA</MenuItem>
                                                <MenuItem value={'No Preference'}>No Preference</MenuItem>
                                                <MenuItem value={'Online'}>Online</MenuItem>
                                                <MenuItem value={'Centre'}>Centre</MenuItem>
                                        </Select>
                                        {touched.gender && errors.gender && <FormHelperText error={true}>{errors.gender}</FormHelperText>}
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col>
                                    <TextField
                                        type="text" placeholder="Phone" variant="outlined" fullWidth name="contact"
                                        value={values.contact} onChange={handleChange}
                                        error={(touched.contact && errors.contact) != undefined}
                                        helperText={touched.contact && errors.contact}
                                    ></TextField>
                                </Col>
                                <Col>
                                <ThemeProvider theme={materialTheme}>
                                    <KeyboardDatePicker
                                        id="date-picker-dialog" className="fs-12" label="" fullWidth
                                        disableFuture={true} inputVariant="outlined" format="dd/MM/yyyy"
                                        emptyLabel="Enter Date Of Birth"
                                        InputProps={{
                                            onBlur : (val) => {
                                                var date = moment(val.target.value,"DD/MM/YYYY")
                                                if (date.isValid()) {
                                                    setFieldValue("dob", date.toDate())
                                                }
                                            }
                                        }}
                                        value={values.dob} 
                                        onChange={(date) => {
                                            setFieldValue("dob", date)}}
                                    />
                                </ThemeProvider>
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col>
                                    <TextField
                                        type="text" placeholder="School/Company" variant="outlined" fullWidth name="school"
                                        // inputProps={this.propsForTextFieldInput}
                                        // InputProps={this.propsForTextFieldInputProps}
                                        value={values.school} onChange={handleChange}
                                        error={(touched.school && errors.school) != undefined}
                                        helperText={touched.school && errors.school}
                                    ></TextField>
                                </Col>
                                <Col>
                                    <TextField
                                        type="text" placeholder="Postcode/Zip" variant="outlined" fullWidth name="postcode"
                                        // inputProps={this.propsForTextFieldInput}
                                        // InputProps={this.propsForTextFieldInputProps}
                                        value={values.postcode} onChange={handleChange}
                                        error={(touched.postcode && errors.postcode) != undefined}
                                        helperText={touched.postcode && errors.postcode}
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col style={{textAlign : "right"}}>
                                    <Button className='greenButton' variant="secondary" type="submit" disabled={isSubmitting}>
                                        Done
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                  }
                </Formik>
            </>
        );
    }
}

export default withRouter(StudentForm);
