import React, { Component } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import FullScreenSpinner from '../../../Components/FullScreenSpinner/FullScreenSpinner'
import { withRouter } from 'react-router-dom';
import { withMessageManager } from '../../../Helpers/messages/MessageRenderer'
import MediaGalleryItem from './MediaGalleryItem.js'
import 'react-month-picker-dropdown/dist/index.css'
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';

import Student from '../../../Models/Student'
import Family from '../../../Models/Family'
import moment from 'moment'
import "./family.css"

class FamilyMediaGallery extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            family : undefined,
            loading: false,
            files : undefined,
            selectedFilter : 0,
            filteredFiles : undefined,
            year: null,
            month: null,
        }
        this.inputRef = undefined
        this.studentId = props.match.params.studentId
        this.studentApi = new Student()
        this.familyApi = new Family()
    }
    filterFiles(){
        let filteredFiles = this.state.files.filter(file => {
            var date = moment(file.uploadDate)
            if(this.state.month && this.state.year){
                return date.month() == this.state.month && date.year() == this.state.year;
            }else if(this.state.month){
                return date.month() == this.state.month;
            }else if(this.state.year){
                return date.year() == this.state.year;
            } else {
                return true
            }
        })
        this.setState({filteredFiles})
    }
    openModal(modal){
        this.setState({[modal]: true})
    }
    closeModal(modal){
        this.setState({[modal]: false})
    }
    componentDidMount() {
        this.init()
    }
    init() {
        var familyId = this.props.match.params.familyId
        this.familyApi.GetFamilyById(familyId, (family,err) => {
            this.setState({family : family})
        })
        this.familyApi.GetUploadedFiles(familyId, (files, err) => {
            files.reverse()
            if(err === false){
                this.setState({files : files, filteredFiles : files})
            }
        })
    }

    reloadData() {
        var familyId = this.props.match.params.familyId
        this.familyApi.GetUploadedFiles(familyId, (files, err) => {
            files.reverse()
            if(err === false){
                this.setState({files : files},this.filterFiles)
            }
        })
    }

    showAll = () => {
        this.setState({filteredFiles : this.state.files})
    }

    renderEditParentModal(){
        var parent = this.state.editingParent
        if (parent == null) { return }
        return(
            <Modal size="lg" show={this.state.modalParent} onHide={()=> this.closeModal('modalParent')}>
                <Modal.Header closeButton style={{border: 'none'}}>
                    <Modal.Title className='txtBold fs-24'>Add Family</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                </Modal.Body>
            </Modal>
        )
    }

    render() {
        var files = this.state.filteredFiles
        if (this.state.family === undefined) {
            return <FullScreenSpinner />
        }
        return (
        <>
            {this.state.loading && <FullScreenSpinner />}
        <Row style={{backgroundColor:"#F5F5F5", height:"100%"}}>
            <Col className="whiteBoxGallery">
                <Row className="justify-content-center" style={{marginTop:"3%"}}>
                    <Col xs={11} md={12} className='filter fs-20' style={{textAlign:"left", paddingLeft:0, fontWeight:"800"}}>
                        Student Gallery
                    </Col>
                </Row>
                <Row className="justify-content-center" style={{marginTop:"2%"}}>
                    <Col xs={11} md={1} className='filter' style={{textAlign:"left", paddingLeft:0, display:'flex', alignItems:'center'}}>
                        <span className="fs-12" style={{color:"#4A4A4A", fontWeight:"500", opacity:"0.7"}}>Sort by:</span>
                    </Col>
                    <Col xs={5} md={2} className='filter' style={{textAlign: 'left'}}>
                        <MonthPicker
                            defaultValue={'Month'}
                            short                     // default is full name
                            endYearGiven              // mandatory if end={} is given in YearPicker
                            year={this.state.year}    // mandatory
                            required={true}           // default is false
                            value={this.state.month}  // mandatory
                            onChange={(month) => {    // mandatory
                                this.setState({ month }, this.filterFiles);
                                
                            }}
                            id={'month'}
                            name={'month'}
                            classes={'fs-12 dropdownpicker filter-height'}
                            optionClasses={'option classes'}
                        />
                    </Col>
                    <Col xs={{span:5, offset:1}} md={{span:2, offset:0}} className='filter' style={{textAlign: 'left'}}>
                        <YearPicker
                            defaultValue={'Year'}
                            start={2010}                // default is 1900
                            reverse                     // default is ASCENDING
                            required={true}             // default is false
                            value={this.state.year}     // mandatory
                            onChange={(year) => {       // mandatory
                                this.setState({ year }, this.filterFiles);
                            }}
                            id={'year'}
                            name={'year'}
                            classes={'fs-12 dropdownpicker filter-height'}
                            optionClasses={'option classes'}
                        />
                    </Col>
                    <Col xs={11} md={7} className='filterButton' style={{textAlign:"right", paddingRight:0, justifyContent: 'center'}}>
                        <Button className="fs-12 greenButton buttonGallery filter-height" variant="info" onClick={() => this.showAll()}>CLEAR FILTER</Button>
                    </Col>
                </Row>
                <Row className="tableContainer"style={{marginTop:"4%"}} >
                    <Col>
                        {files instanceof Array && files.length > 0 && (
                            <MediaGalleryItem mediaFiles={files} reinit={() => {this.reloadData()}}/>
                        )}
                        {/* {files instanceof Array && files.length > 0 && (
                            <MediaGalleryItem mediaFiles={files} reinit={() => {this.reloadData()}}/>
                        )} */}
                        {/* {files instanceof Array && (
                            files.map((media,i)=> (
                                <MediaGalleryItem key={i} mediaFiles={media} reinit={() => {this.reloadData()}}/>
                            ))
                        )} */}
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
        );
    }
}

export default withMessageManager(withRouter(FamilyMediaGallery));
