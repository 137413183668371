import React from 'react';
import 'date-fns';
import './App.css';
import { Container, Col, Row } from 'react-bootstrap';
import { AuthProvider, AuthContext, logout } from './Helpers/auth/auth.js';
import { BrowserRouter as Router, Route } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Messages, MessageProvider, MessageContext } from "./Helpers/messages/MessageRenderer.js";
import PrivateRoute from './Helpers/auth/privateRoute.js';
import ParentRoute from './Helpers/auth/parentRoute';
import TeacherRoute from './Helpers/auth/teacherRoute';
import Home from './Components/Pages/Home.js'
import LoginPage from "./Components/Auth/login.js"
import ResetPassword from './Components/Pages/ResetPassword/ResetPassword.js'
import TeacherListPage from './Components/Pages/Teacher/TeacherListPage'
import TeacherAddPage from './Components/Pages/Teacher/TeacherAddPage'
import TeacherProfilePage from './Components/Pages/Teacher/TeacherProfilePage'
import TeacherAccountPage from './Components/Pages/Teacher/TeacherAccountPage'
import TeacherCurriculumPage from './Components/Pages/Curriculum/TeacherCurriculumPage'
import StudentListPage from './Components/Pages/Student/StudentListPage'
import ViewStudentCredit from './Components/Pages/Credit/ViewStudentCredit'
import StudentAddPage from './Components/Pages/Student/StudentAddPage'
import StudentProfilePage from './Components/Pages/Student/StudentProfilePage'
import ViewCreditUsedPage from './Components/Pages/Credit/ViewCreditUsedPage'
import CurriculumPage from './Components/Pages/Curriculum/CurriculumPage'
import AddNewFamily from './Components/Pages/AddNewFamilyForm/AddNewFamilyForm'
import AddNewProspect from './Components/Pages/AddNewProspectForm/AddNewProspectForm'
import FamilyMediaGallery from './Components/Pages/Family/FamilyMediaGallery'
import NavBar from './Components/Navbar/Navbar'
import Footer from './Components/Footer/footer.js'
import ParentLogin from './Components/Pages/ParentPortal/Login'
import Calender from './Components/Pages/Calender/Calender'
import PriceList from './Assets/PriceList.jpg'
import { ThemeProvider } from "@material-ui/styles";
import { materialTheme } from './Helpers/Style'
function App() {
  return (
    <div className="App">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AuthProvider>
          <ThemeProvider theme={materialTheme}>
          <MessageProvider>
            <Router>
              <MessageContext.Consumer>
                {({ messages, setMessages }) => {
                  return (<Messages messages={messages} setMessages={setMessages}></Messages>)
                }}
              </MessageContext.Consumer>
              <Container fluid={true} style={{ padding: 0 }}>
                <NavBar />
              </Container>
              <Container fluid={true}>
                <Row className="contentContainer">
                  <Col style={{ padding: '0' }}>
                    <ParentRoute exact path="/" component={Home} />
                    <Route exact path="/login" render={() => (
                      <LoginPage></LoginPage>
                    )} />
                    <Route exact path="/parent/login" component={ParentLogin}></Route>
                    <Route exact path="/password/reset" component={ResetPassword} />
                    
                    {/* <Route exact path='/pricelist'><img src={PriceList}/></Route> */}
                    <ParentRoute exact path="/home" component={Home} />
                    <TeacherRoute exact path="/student/new" component={StudentAddPage} />
                    <TeacherRoute exact path="/student/list" component={StudentListPage} />
                    <TeacherRoute exact path="/student/profile/:studentId" component={StudentProfilePage} />
                    <TeacherRoute exact path="/student/:studentId/credit/used" component={ViewCreditUsedPage} />
                    <TeacherRoute exact path="/student/:studentId/curriculum/:curriculumId" component={CurriculumPage} />
                    <TeacherRoute exact path="/student/family/new" component={AddNewFamily} />
                    <TeacherRoute exact path="/prospect/new" component={AddNewProspect} />
                    <PrivateRoute exact path="/student/gallery/:familyId" component={FamilyMediaGallery} />
                    <PrivateRoute exact path="/parent/:familyId/credit/" component={ViewStudentCredit} />

                    <TeacherRoute exact path="/teacher/list" component={TeacherListPage} />
                    <TeacherRoute exact path="/teacher/new" component={TeacherAddPage} />
                    <TeacherRoute exact path="/teacher/profile/:teacherId" component={TeacherProfilePage} />
                    <TeacherRoute exact path="/teacher/profile/:teacherId/account" component={TeacherAccountPage} />
                    <TeacherRoute exact path="/teacher/:teacherId/curriculum/:curriculumId" component={TeacherCurriculumPage} />
                    {/* <TeacherRoute exact path="/calender/test" component={Calender} /> */}

                  </Col>
                </Row>
              </Container>
              <Container fluid={true} style={{ padding: 0 }}>
                <Footer />
              </Container>
            </Router>
          </MessageProvider>
          </ThemeProvider>
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default App;
