import React from 'react';
import { InputBase } from '@material-ui/core'
import cancelIcon from '../../Assets/Icons/cancelIcon.png'
// searchDidEnd, list, searchOn, placeholder
class Search extends React.Component {
    searchArr(searchTerm) {
        var keys = this.props.searchOn
        var array = this.props.list
        var newArr = []
        if (!keys instanceof Array || !array instanceof Array) {
            return
        }
        for ( var i = 0; i< keys.length; i++) {
            var key = keys[i];
            var filtered = array.filter((val) => {
                return (val[key].toLowerCase().includes(searchTerm.toLowerCase()))
            })
            filtered.forEach(element => {
                if (!newArr.includes(element)) {
                    newArr.push(element)
                }
            });
        }
        if (this.props.searchDidEnd && {}.toString.call(this.props.searchDidEnd) === '[object Function]') {
            this.props.searchDidEnd(newArr);
        }
    }
    render() {
        return(
            <div 
            style={{fontWeight : "bold", fontSize : "1rem", border:"1px solid #c0c0c0", color:"#E2E2E2",
            backgroundColor : "white", borderRadius : "6px", display : "flex", ...this.props.style}} >
                <InputBase
                    id="searchinput"
                    placeholder={this.props.placeholder} 
                    onChange = {(e) => {
                        this.searchArr(e.target.value)
                    }}
                    style={{display : "block", flexGrow : 1, padding:"10.5px 14px"}}
                    inputProps={{ 'aria-label': 'naked' }}
                />
                <img src={cancelIcon} className="clickable" style={{width : "20px", height : "20px", marginRight : "10px", alignSelf : "center", marginLeft : "auto"}} onClick={() => {
                    this.searchArr("")
                    document.getElementById("searchinput").value = ""
                }}/>
            </div>
        );
    }
}

export default Search;