import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import './AddNewProspectForm.css';
import { withRouter } from 'react-router-dom';
import { withMessageManager } from '../../../Helpers/messages/MessageRenderer'
import { Formik, FieldArray, getIn} from "formik";
import FullScreenSpinner from '../../../Components/FullScreenSpinner/FullScreenSpinner'
import * as Yup from "yup";
import Family from '../../../Models/Family';
import Prospect from '../../../Models/Prospect';

import FamilySourceFields from '../Student/FamilySourceFormFields'
class AddNewProspectForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.validationSchema = Yup.object().shape({
        // prospect : Yup.array().of(
        //     Yup.object().shape({
        //         firstName: Yup.string().typeError("Please enter your first name"),
        //         lastName: Yup.string().typeError("Please enter your last name"),
        //         // email: Yup.string().nullable().typeError("Please enter your email").email("Please enter a valid email"),
        //         email: Yup.string().email().required("Please enter your email"),
        //         contact: Yup.string().nullable().typeError("Please enter your contact"),
        //     })
        // ),
        firstName: Yup.string().typeError("Please enter your first name"),
        lastName: Yup.string().typeError("Please enter your last name"),
        // email: Yup.string().nullable().typeError("Please enter your email").email("Please enter a valid email"),
        email: Yup.string().email().required("Please enter your email"),
        contact: Yup.string().nullable().typeError("Please enter your contact"),
        centreId : Yup.number().required("Please select a centre to submit!"),
        idsource: Yup.number().nullable().typeError("Please select the lead source"),
    });
    this.familyApi = new Family()
    this.prospectApi= new Prospect()
    this.state={
      centres : [],
      loading : false
    }
  }
  componentDidMount() {
    this.familyApi.GetCentres((centres) => {
        this.setState({centres : centres})
    })
  }
  render() {
    if (this.state.loading) {
      return(<FullScreenSpinner />)
    }
    return (
      <Container style={{marginTop:'3%'}}>
        <Row style={{textAlign: 'left'}}>
          <Col>
            <Row>
              <Col className="txtBold fs-24">Add New Prospect</Col>
            </Row>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                contact: "",
                centreId : '',
                idsource: null,
              }}
              validationSchema={this.validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                this.setState({loading : true})
                console.log(values)
                // if (this.initialValue.email == "") {
                //   setSubmitting(false)
                //   this.props.setMessages([{type : "danger", message : "Email needs to be filled"}])
                //   return
                // }
                this.prospectApi.AddProspect(values,(res,err)=>{
                  if (err) {
                    this.props.setMessages([{type : "danger" , message : err}])
                  } else {
                    resetForm()
                    this.props.setMessages([{type : "success" , message : "Prospect Added"}])
                  }
                  this.setState({loading : false})
                  setSubmitting(false)
                  window.scrollTo(0,0)
                })
              }}>
              {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                        <Row style={{margin : "1rem 0"}}>
                            <Col className='txtBold' style={{alignSelf : "center", paddingLeft:"55px"}}>Centre :</Col>
                            <Col className='flex-grow-2'>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-simple-select-outlined-label">Centre</InputLabel>
                                    <Select 
                                        fullWidth
                                        value={values.centreId}
                                        name="centreId"
                                        onChange={handleChange}
                                        labelId="demo-simple-select-outlined-label" 
                                        id="demo-simple-select-outlined" label="Centre" size='small'>
                                        {this.state.centres.map(centre => 
                                            <MenuItem key={centre.idcentre} value={centre.idcentre}>{centre.name}</MenuItem>
                                        )}
                                    </Select>
                                    {touched.centreId && errors.centreId && <FormHelperText error={true}>{errors.centreId}</FormHelperText>}
                                </FormControl>
                            </Col>
                        </Row>
                        <Row style={{margin : "1rem 0"}}>
                          <Col style={{paddingLeft : "40px", paddingRight : "0px"}}>
                            <FamilySourceFields values={values} errors={errors} touched={touched} handleChange = {handleChange} isSubmitting = {isSubmitting} setFieldValue = {setFieldValue}/>
                          </Col>
                        </Row>
                        <Row style={{textAlign: 'left', border: '1px solid #c4c4c4', padding: '30px 40px', borderRadius: '4px'}}>
                          <Col>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold' style={{alignSelf : "center"}}>First Name :</Col>
                                <Col className='flex-grow-2'>
                                    <TextField type="text" placeholder="First Name" variant="outlined" 
                                    fullWidth
                                    inputProps={{style:{padding:"0.5rem"}}}
                                    // InputProps={singleLineTextFieldProps}
                                    initialValue={values.firstName} name={`firstName`}                                       
                                    onBlur={handleChange}
                                    error={(getIn(touched,`firstName`) && getIn(errors,`firstName`)) != undefined}
                                    helperText={(getIn(touched,`firstName`) && getIn(errors,`firstName`))}
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold' style={{alignSelf : "center"}}>Last Name :</Col>
                                <Col className='flex-grow-2'>
                                <TextField type="text" placeholder="Last Name" variant="outlined" 
                                    fullWidth
                                    inputProps={{style:{padding:"0.5rem"}}}
                                    // InputProps={singleLineTextFieldProps}
                                    initialValue={values.lastName} name={`lastName`}                                       
                                    onBlur={handleChange}
                                    error={(getIn(touched,`lastName`) && getIn(errors,`lastName`)) != undefined}
                                    helperText={(getIn(touched,`lastName`) && getIn(errors,`lastName`))}
                                ></TextField>
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold' style={{alignSelf : "center"}}>Email :</Col>
                                <Col className='flex-grow-2'>
                                <TextField type="text" placeholder="Email" variant="outlined" 
                                    fullWidth
                                    inputProps={{style:{padding:"0.5rem"}}}
                                    // InputProps={singleLineTextFieldProps}
                                    initialValue={values.email} name={`email`}                                       
                                    onBlur={handleChange}
                                    // error={(getIn(touched,`email`) && getIn(errors,`email`)) != undefined}
                                    // // error={(touched.email && errors.email) !== undefined}
                                    // helperText={(getIn(touched,`email`) && getIn(errors,`email`))}
                                    // // helperText={touched.email && errors.email}
                                ></TextField>
                                {(touched.email && errors.email) !== undefined && <FormHelperText error={true}>{errors.email}</FormHelperText>}
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold' style={{alignSelf : "center"}}>Phone :</Col>
                                <Col className='flex-grow-2'>
                                <TextField type="text" placeholder="Phone" variant="outlined" 
                                    fullWidth
                                    inputProps={{style:{padding:"0.5rem"}}}
                                    // InputProps={singleLineTextFieldProps}
                                    initialValue={values.contact} name={`contact`}                                       
                                    onBlur={handleChange}
                                    error={(getIn(touched,`contact`) && getIn(errors,`contact`)) != undefined}
                                    helperText={(getIn(touched,`contact`) && getIn(errors,`contact`))}
                                ></TextField>
                                </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{margin : "1rem 0"}}>
                            <Col style={{textAlign : "right"}}>
                                <Button className='greenButton' variant="secondary" type="submit" disabled={isSubmitting}>
                                  Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )
              }
            </Formik>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withMessageManager(withRouter(AddNewProspectForm));