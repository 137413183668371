import React from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Col} from 'react-bootstrap'
import MyButton from '../../MyButton/MyButton'
import TeacherList from '../../Teacher/TeacherList/TeacherList'
import Search from '../../../Components/Search/Search'
import Teacher from '../../../Models/Teacher';
class TeacherListPage extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {
            teachers : [],
            originalTeachers : []
        }
        this.teacherApi = new Teacher()
    }
    componentDidMount(){
        this.reloadData()
    }
    reloadData() {
        this.teacherApi.ListTeachers((teachers) => {
            var sorted = teachers.sort((a,b) => {
                if(a.fullname < b.fullname) { return -1; }
                if(a.fullname > b.fullname) { return 1; }
                return 0;
            })
            this.setState({teachers : sorted, originalTeachers : sorted})
        })
    }
    render() {
        return (
            <>
            <Container style={{marginTop:"3%", marginBottom:"3%"}}>
            <Row >
                <Col style={{textAlign : "left"}}>
                    <h1>
                        Teachers
                    </h1>
                </Col>
            </Row>
            <Row style={{paddingBottom:"10px"}}>
                <Col>
                    <Search searchDidEnd={(results) => {
                        this.setState({teachers : results})
                    }} list={this.state.originalTeachers}
                    searchOn={['fullname']} 
                    placeholder="Search Teachers"
                    style={{textAlign : "left"}}/>
                </Col>
                <Col style={{textAlign : "right"}}>
                    <MyButton onClick = {() => {
                        this.props.history.push('/teacher/new')
                    }}>Add Teacher</MyButton>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TeacherList teachers={this.state.teachers} onDeleteTeacher={()=>{
                        this.reloadData()
                    }}/>
                </Col>
            </Row>
            </Container>
            </>
        )
    }
}
export default withRouter(TeacherListPage);