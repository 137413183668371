import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { TextField, FormHelperText } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FullScreenSpinner from '../../../Components/FullScreenSpinner/FullScreenSpinner'
import { withRouter } from 'react-router-dom';
import { withMessageManager } from '../../../Helpers/messages/MessageRenderer'
import { withAuthManager } from '../../../Helpers/auth/auth'
import { Formik,} from "formik";
import * as Yup from "yup";
import Credit from '../../../Models/Credit';
import moment from "moment"

class SetCreditForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.validationSchema = Yup.object().shape({
            credit: Yup.number().typeError("Please enter credits to set").required("Please enter credits to set"),
            expiry: Yup.date().typeError("Please enter credits expiry date").required("Please enter credits expiry date"),
        });
        this.state = {
            loading : false
        }
        this.creditApi = new Credit()
    }
 
    componentDidMount() {
    }
  
    render() {
        var studentId = this.props.match.params.studentId
        var postSubmitHandler = this.props.postSubmitHandler
        if (this.props.user.adminId == null) {
            return (<></>)
        }
        return (
            <>
                {this.state.loading && <FullScreenSpinner />}
                <Formik
                    initialValues={{
                        credit: 0,
                        expiry : null,
                    }}
                    validationSchema={this.validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        this.setState({loading : true})
                        this.creditApi.SetBalance(studentId, values.credit, values.expiry, (res,err) => {
                            if (err != null && err) {
                                this.props.setMessages([{type : "danger", message : err}])
                            } else {
                                this.props.setMessages([{type : "success", message : "Credits Recorded"}])
                            }
                            resetForm()
                            setSubmitting(false)
                            this.setState({loading : false})
                            postSubmitHandler(res,err)
                        })
                }}>
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) =>
                    (
                        <Form onSubmit={handleSubmit}>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBoldUppercase'>SET CREDIT</Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold' style={{alignSelf : "center"}} xs={5}>Credit</Col>
                                <Col className='flex-grow-2'>
                                    <TextField type="text" placeholder="Credit" variant="outlined" 
                                        // InputProps={singleLineTextFieldProps}
                                        value={values.credit} name={'credit'}                                       
                                        onChange={handleChange} fullWidth
                                        error={(touched.credit &&  errors.credit)}
                                        helperText={touched.credit && errors.credit}
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold' style={{alignSelf : "center"}} xs={5}>Expiry</Col>
                                <Col className='flex-grow-2'>
                                    <KeyboardDatePicker
                                        id="date-picker-dialog" className="fs-12" label="Credit Expiry Date" fullWidth
                                        disableFuture={false} inputVariant="outlined" format="dd/MM/yyyy"
                                        disablePast={true}
                                        // inputProps={this.propsForTextFieldInput}
                                        InputProps={{
                                            onBlur : (val) => {
                                                var date = moment(val.target.value,"DD/MM/YYYY")
                                                if (date.isValid()) {
                                                    setFieldValue("expiry", date.toDate())
                                                }
                                            }
                                        }}
                                        value={values.expiry} 
                                        onChange={(date) => {
                                            setFieldValue("expiry", date)}}
                                    />
                                    {touched.expiry && errors.expiry && <FormHelperText error = {true}>{errors.expiry}</FormHelperText>}
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col style={{textAlign : "right"}}>
                                    <Button className='fs-14 greenButton' type="submit" disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                  }
                </Formik>
            </>
        );
    }
}

export default withAuthManager(withMessageManager(withRouter(SetCreditForm)));
