import React from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { Divider, TextField } from '@material-ui/core'
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {MessageContext} from '../../../Helpers/messages/MessageRenderer.js'
import Model from '../../../Models/UserAccount.js'

import './ResetPassword.css'
class ResetPassword extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.api = new Model();
        this.validationSchema = Yup.object().shape({
            newPassword: Yup.string().required("Please enter your new password").min(8, "Password needs to be at least 8 characters"),
            confirmPassword: Yup.string().required("Please re-enter your new password").min(8, "Password needs to be at least 8 characters"),
        });
    }

    resetPassword = (values) => {
        var token = this.props.location.search.slice(7)
        console.log(token, values)
        this.api.ResetPassword(values.newPassword, token, (res, err) => {
            console.log(res, err)
            if (err != null) {
                this.props.setMessages([{ type: "danger", message: err }])
            }
            else {
                this.props.setMessages([{ type: "success", message: "Password changed successfully!" }])
                this.props.history.push("/login")
            }
        })
    }

    render() {
        return (
            <Container style={{ paddingTop: "20px" }}>
                <Row>
                    <Col xs={12} sm={{ span: 10, offset: 1 }}>
                        <p className="fs-20" style={{ textAlign: "left", fontWeight: "700" }}>Reset Password</p>
                        <Divider style={{ marginBottom: "20px", color: "#D6D6D6", padding: "0.6px" }} />
                        <Formik
                            initialValues={{
                                newPassword: "",
                                confirmPassword: "",
                            }}
                            validationSchema={this.validationSchema}
                            validateOnChange={false}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                if (values.newPassword != values.confirmPassword) {
                                    setSubmitting(false)
                                    this.props.setMessages([{ type: "danger", message: "New passwords do not match" }])
                                    window.scrollTo(0, 0)
                                    return
                                }
                                this.resetPassword(values)

                                }}
                        >{({ values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting, setSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            <p style={{margin:0, textAlign:"left"}}>New Password</p>
                            <TextField 
                                fullWidth type="password" variant="outlined" inputProps={{ style: { padding: "10px" } }}
                                value={values.newPassword}
                                onChange={handleChange}
                                error={(touched.newPassword && errors.newPassword) !== undefined} helperText={touched.newPassword && errors.newPassword}
                                name="newPassword">
                            </TextField>
                            <p style={{margin:0, textAlign:"left", marginTop:"10px"}}>Confirm New Password</p>
                            <TextField 
                                fullWidth type="password" variant="outlined" inputProps={{ style: { padding: "10px" } }}
                                value={values.confirmPassword}
                                onChange={handleChange}
                                error={(touched.confirmPassword && errors.confirmPassword) !== undefined} helperText={touched.confirmPassword && errors.confirmPassword}
                                name="confirmPassword">
                            </TextField>
                            <Row>
                                <Col style={{ textAlign: 'left' }}>
                                    <Button className='greenButton'
                                        variant="primary" type="submit"
                                        style={{ cursor: "pointer", marginTop: "20px" }} >Reset Password</Button>
                                </Col>
                            </Row>
                        </Form>
                        )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        )
    }
}

class ResetPasswordWrapper extends React.Component {
    render() {
        return (
            <MessageContext.Consumer>
                { ({messages, setMessages}) => (<ResetPassword messages={messages} setMessages ={setMessages} history={this.props.history} {...this.props}></ResetPassword>)}
            </MessageContext.Consumer>
        )
    }
}
export default withRouter(ResetPasswordWrapper);