import React from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col, Button} from 'react-bootstrap'
import DeleteIcon from '../../../Assets/Icons/DeleteIcon.png'
import EditIcon from '../../../Assets/Icons/EditIcon.png'
import FullScreenModal from '../../FullScreenModal/FullScreenModal'
import Student from '../../../Models/Student';

import {AuthContext} from '../../../Helpers/auth/auth'
// onDeleteStudent, student
class StudentListItem extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {
            showDeleteModal : false
        }
        this.studentApi = new Student();
    }
    deleteStudent() {
        var studentId = this.props.student.idstudent
        var onDelete = this.props.onDeleteStudent
        this.studentApi.DeleteStudent(studentId, (success,err) => {
            if (success) {
                this.props.setMessages([{type : "success", message : `You have deleted student ${this.props.student.firstName} ${this.props.student.lastName}`}])
            } else {
                this.props.setMessages([{type : "danger", message : `You have failed to delete student ${this.props.student.firstName} ${this.props.student.lastName}`}])
            }
            if (onDelete && {}.toString.call(onDelete) === '[object Function]') {
                onDelete()
            }
        })
    }
    renderModal() {
        return (
            <FullScreenModal show={this.state.showDeleteModal} onClose={()=>{this.setState({showDeleteModal : false})}}>
                <Row>
                    <Col>
                        <div style={{padding : "20px"}}>
                            Confirm Delete {this.props.student.firstName} {this.props.student.lastName} ?
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{padding : "10px", textAlign : "right"}}>
                            <Button 
                                variant = "secondary"
                                style={{marginRight : "30px"}}
                                onClick={() => {this.setState({showDeleteModal : false})}}>Cancel</Button>
                            <Button 
                                variant = "danger"
                                onClick={() => {this.deleteStudent()}}>Delete</Button>
                        </div>
                    </Col>
                </Row>
            </FullScreenModal>
        )
    }
    render() {
        var student = this.props.student
        return (
            <>
            <Row style={{boxShadow : "3px 3px #CCCCCC", borderRadius : "15px", border : "1px solid #c0c0c0", ...this.props.style}}>
                <Col>
                    <Row className = "clickable" >
                        <Col xs={9} onClick={() => {
                            this.props.history.push(`/student/profile/${student.idstudent}`)
                        }}>
                            <div style={{textAlign : "left", padding : "10px"}}>
                                {student.firstName} {student.lastName}
                            </div>
                        </Col>
                        <Col xs={3} style={{textAlign : "right", display: "flex", alignItems: "center"}}>
                            <div style={{marginLeft : "auto"}}>
                                <img className = "clickable clickemphasis" src={EditIcon} style={{marginRight : "10px"}} onClick={() => {
                                    this.props.history.push(`/student/profile/${student.idstudent}`)
                                }}/>
                                {/* {
                                    this.props.user.adminId && 
                                        (<img className = "clickable clickemphasis" src={DeleteIcon} onClick={()=>{
                                            this.setState({showDeleteModal : true})
                                        }}/>)
                                } */}
                            
                            </div>
                        </Col>   
                    </Row>
                </Col>
            </Row>
            {this.renderModal()}
            </>
        )
    }
}
// use context here
class StudentListItemWrapper extends React.Component {
    render() {
        return (
            <AuthContext.Consumer>
                { ({isAuthenticated, setAuthenticated, user}) => (
                <StudentListItem user={user} history={this.props.history} {...this.props}></StudentListItem>)
                }
            </AuthContext.Consumer>
        )
    }
}
export default withRouter(StudentListItemWrapper);