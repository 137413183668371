import { ca } from 'date-fns/locale';
import Model from './Model'
const axios = require('axios');
axios.defaults.withCredentials = true;

class Student {
    constructor() {
        this.model = new Model()
    }
    /*
    familyId : Int?,
    firstName : String,
    lastName : String,
    email : String,
    dob : Datetime,
    gender : String,
    school : String,
    postcode : String,
    contact : string,
    */
    AddStudent(student, centreId, callback) {
        console.log(student)
        this.model.postReq(`/new/student`, {student:student, centreId :centreId}, (res,err) => {
            callback(err === null, err)
        })
    }
    DeleteStudent(studentId, callback) {
        this.model.postReq(`/delete/student/${studentId}`, {}, (res,err) => {
            callback(err === null, err)
        })
    }
    /*
    firstName : String,
    lastName : String,
    email : String,
    dob : Datetime,
    gender : String,
    school : String,
    postcode : String,
    contact : string,
    */
    UpdateStudent(studentId, student,centreId, callback) {
        this.model.postReq(`/update/student/${studentId}`, {student: student, centreId: centreId}, (res,err) => {
            callback(err === null, err)
        })
    }

    async SearchStudent(studentSearchTerm, parentSearchTerm, curriculumId ) {
        return await new Promise((res,rej) => {
            this.model.get(`/search/student`, { student : studentSearchTerm, parent : parentSearchTerm, curriculum : curriculumId }, (resp,err) => {
                if (resp && resp.value && resp.success) {
                    res(resp.value)
                } else {
                    rej(err || resp.error)
                }
            })
        })
    }

    GetClassesTakenByStudent(studentId, callback) {
        this.model.get(`/student/${studentId}/class`,{}, (res,err) => {
            callback(res.value, err || res.error)
        })
    }
    /*
    class : {
        classId : Int?
        starttime : Datetime,
        endtime : Datetime,
        isNoShow : Bool,
        creditsUsed : Double
    }
    */
    PostClassTakenByStudent(studentId, classObj, callback) {
        this.model.postReq(`/student/${studentId}/class`, {class : classObj}, (res,err) => {
            callback(res.value, err || res.error)
        })
    }
    RemoveClass(classId, callback) {
        this.model.postReq(`/student/class/remove`, {classId : classId}, (res,err) => {
            callback(res.value, err || res.error)
        })
    }
    ListStudents(callback) {
        this.model.get(`/student`,null,(results,err) => {
            callback(results && results.value, err || results.error)
        })
    }
    GetStudent(studentId, callback) {
        this.model.get(`/student/${studentId}`,null,(student,err) => {
            if (student == null) {
                callback(null)
            } else {
                callback(student.value)
            }
        })
    }
    GetStudentCurriculum(studentId, callback) {
        this.model.get(`/student/${studentId}/curriculum`,null,(curriculums,err) => {
            callback(curriculums.value)
        })
    }
    EnrollStudentIntoCurriculum(studentId, curriculumId, callback) {
        this.model.postReq(`/student/enroll_curriculum`, {
            curriculumId : curriculumId,
            studentId : studentId
        }, (res,err) => {
            callback(err === null, err)
        })
    }
    RemoveStudentFromCurriculum(studentId, curriculumId, callback) {
        this.model.postReq(`/student/remove_curriculum`, {
            curriculumId : curriculumId,
            studentId : studentId
        }, (res,err) => {
            callback(err === null, err)
        })
    }
    CreateStudentNote(studentNote, studentId, callback){
        this.model.postReq(`/student/${studentId}/note_create`, studentNote,(res,err) => {
            callback(err === null && res && res.success, err || res.error)
        })
    }
    UpdateStudentNote(studentNote, studentId, callback){
        this.model.postReq(`/student/${studentId}/note_update`, studentNote,(res,err) => {
            callback(err === null && res && res.success, err || res.error)
        })
    }
    GetStudentNote(studentId, callback){
        this.model.get(`/student/${studentId}/note`, null,(studentNote,err) => {
            if(studentNote != null){
                callback(studentNote.value)
            }else{
                callback(studentNote)
            }
            
        })
    }
    UpdateStudentChecklist(checklistObj, studentId, callback){
        this.model.postReq(`/student/${studentId}/checklist_update`, checklistObj,(res,err) => {
            callback(err === null, err)
        })
    }
    GetStudentChecklist(studentId, callback){
        this.model.get(`/student/${studentId}/checklist`, null,(studentChecklist,err) => {
            if(studentChecklist != null){
                callback(studentChecklist.value)
            }else{
                callback(studentChecklist)
            }
            
        })
    }
    ListStudentsInCurriculum(curriculumId, callback){
        this.model.get(`/curriculum/${curriculumId}/students/`, null, (res,err) => {
            if(res != null){
                callback(res.value)
            }else{
                callback(err)
            }
            
        })
    }
    DeleteFile(fileId, callback) {
        axios.delete(this.model.apiEndPoint + `/student/upload/${fileId}`,{}).then((resp) => {
            callback(resp && resp.data, null);
        }).catch((err) => {
        })
    }
    UploadFiles(studentId, formData, callback) {
        axios
        .post(this.model.apiEndPoint + `/student/${studentId}/upload`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            withCredentials : true
        })
        .then(function (response) {
            if (response !== null) {
                if (response.success) {
                    callback(response.data, null);
                } else {
                    callback(null, response.error);
                }
            } else {
                callback(null, "Upload failed");
            }
        }).catch((err) => {
            callback(null, err);
        });
    }
    GetUploadedFiles(studentId, callback) {
        this.model.get(`/student/${studentId}/upload`,{}, (res,err) => {
            if(res != null && res.success){
                callback(res.value,err)
            }else{
                callback(null,err || res.error)
            }
        })
    }
}
export default Student;