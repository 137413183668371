import Model from './Model'
class Teacher {
    constructor() {
        this.model = new Model()
    }
    ListTeachers(callback) {
        this.model.get(`/teacher`,null,(teachers,err) => {
            if (err !== null) {
                callback([],err)
            } else {
                callback(teachers.value,null)
            }
        })
    }
    DeleteTeacher(accountId,callback) {
        this.model.postReq(`/teacher/delete/${accountId}`,null,(teacher,err) => {
            if (err !== null) {
                callback(null,err)
            } else {
                callback(teacher.value,null)
            }
        })
    }
    CreateTeacher(teacher, callback) {
        this.model.postReq(`/teacher/new`,teacher,(res,err) => {
            callback((res && res.value) ? res.value : null,err || res.error)
        })
    }
    GetTeacher(teacherId, callback) {
        this.model.get(`/teacher/${teacherId}`, null, (teacher,err) => {
            if (err !== null) {
                callback([],err) 
            } else {
                callback(teacher.value)
            }
        })
    }
    UpdateTeacher(teacherId, teacher, callback) {
        this.model.postReq(`/update/teacher/${teacherId}`, teacher, (res,err) => {
            callback((res && res.value) ? res.value : null, err || res.error)
        })
    }
    EnrollTeacherIntoCurriculum(teacherId, curriculumId, callback) {
        this.model.postReq(`/teacher/enroll_curriculum`, {
            curriculumId : curriculumId,
            teacherId : teacherId
        }, (res,err) => {
            callback(err === null, err)
        })
    }
    RemoveTeacherFromCurriculum(teacherId, curriculumId, callback) {
        this.model.postReq(`/teacher/remove_curriculum`, {
            curriculumId : curriculumId,
            teacherId : teacherId
        }, (res,err) => {
            callback(err === null, err)
        })
    }
    GetTeacherCurriculum(teacherId, callback) {
        this.model.get(`/teacher/${teacherId}/curriculum`,null,(res,err) => { 
            callback((res && res.value) ? res.value : [],err || res.error)
        })
    }
}
export default Teacher;