import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { AuthContext } from '../../../Helpers/auth/auth.js';
import { withRouter } from 'react-router-dom';
import { withMessageManager } from '../../../Helpers/messages/MessageRenderer'
import { withAuthManager } from '../../../Helpers/auth/auth'
import AccessController from "../../../AccessController/AccessController.js";
import FullScreenSpinner from '../../../Components/FullScreenSpinner/FullScreenSpinner'
import Credit from '../../../Models/Credit';
import Student from '../../../Models/Student';
import AddClassForm from '../Student/AddClassForm'
import PurchasePackageForm from '../Student/forms/PurchasePackageForm'
import moment from "moment"
class CreditUsed extends Component {
    constructor(props, context) {
        super(props, context)
        this.studentApi = new Student()
        this.creditApi = new Credit()
        this.accessController = new AccessController()
        this.state = {
            student: null,
            transactions: [],
            packages : [],
            modalClass: false,
            modalTransaction: false,
            modalDeleteClass: false,
            modalDeleteTransaction : false,
            editingClass: null,
            editingTransaction : null,
            deletingClass: null
        }
    }
    openModal(modal) {
        this.setState({ [modal]: true })
    }
    closeModal(modal) {
        this.setState({ [modal]: false })
    }
    componentDidMount() {
        this.init()
    }

    init() {
        var studentId = this.props.match.params.studentId
        this.studentApi.GetStudent(studentId, (student) => {
            this.creditApi.GetTransactions(studentId, (transactions) => {
                this.creditApi.GetPackages((packages) => {
                    this.setState({ student: student, transactions: transactions, packages : packages})
                })
                
            })
        })
    }

    renderAddClassModal() {
        return (
            <Modal show={this.state.modalClass} onHide={() => this.closeModal('modalClass')}>
                <Modal.Header closeButton style={{ border: 'none' }}>
                    <Modal.Title className='txtBold fs-24'>Edit Class</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddClassForm studentId={this.state.editingTransaction ? this.state.editingTransaction.studentId : null}
                        existingClass={this.state.editingClass}
                        postSubmitHandler={() => {
                            this.init()
                            this.closeModal('modalClass')
                        }}
                    ></AddClassForm>
                </Modal.Body>
            </Modal>
        )
    }
    renderEditTransactionModal() {
        if (this.state.editingTransaction == null || this.state.modalTransaction != true) {
            return (<></>)
        }
        let dbpackage = this.state.packages.find((pkg) => {
            return pkg.credit == this.state.editingTransaction.credits
        })
        let packageForm = {
            packageId : dbpackage.idpackage,
            purchaseDate : new Date(this.state.editingTransaction.purchasedate),
            price: this.state.editingTransaction.price,
            rentalMode: this.state.editingTransaction.rental_mode
        }
        return (
            <Modal size={"lg"} show={this.state.modalTransaction} onHide={() => this.closeModal('modalTransaction')}>
                <Modal.Header closeButton style={{ border: 'none' }}>
                    <Modal.Title className='txtBold fs-24'>Edit Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PurchasePackageForm 
                        studentId={this.state.editingTransaction ? this.state.editingTransaction.studentId : null}
                        isEditing={true}
                        initForm ={packageForm}
                        onFormSubmit={(formValues) => {
                            let transaction = {
                                transactionId : this.state.editingTransaction.idtransaction,
                                credits : formValues.package.credit,
                                studentId : this.state.editingTransaction.studentId,
                                rental_mode : formValues.rentalMode,
                                price : formValues.price
                            }
                            this.creditApi.UpdateTransaction(transaction, (res,err) => {
                                if (err != null && err) {
                                    this.props.setMessages([{ type: "danger", message: err }])
                                } else {
                                    this.props.setMessages([{ type: "success", message: "Transaction Updated" }])
                                }
                                this.init()
                                this.closeModal('modalTransaction')
                            })
                        }}
                    ></PurchasePackageForm>
                </Modal.Body>
            </Modal>
        )
    }
    renderDeleteClassModal() {
        return (
            <Modal show={this.state.modalDeleteClass} onHide={() => this.closeModal('modalDeleteClass')}>
                <Modal.Header closeButton style={{ border: 'none' }}>
                    <Modal.Title className='txtBold fs-24'>Confirm Delete Class?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col style={{ textAlign: "right" }}>
                            <div>
                                <Button style={{ marginRight: "1rem" }} className='redButton' onClick={() => {
                                    this.studentApi.RemoveClass(this.state.deletingClass.classId, (res, err) => {
                                        if (err != null && err) {
                                            this.props.setMessages([{ type: "danger", message: err }])
                                        } else {
                                            this.props.setMessages([{ type: "success", message: "Class Removed" }])
                                        }
                                        window.scrollTo(0, 0)
                                        this.init()
                                        this.closeModal('modalDeleteClass')
                                    })
                                }}>Yes</Button>
                                <Button className='greyButton' onClick={() => this.closeModal('modalDeleteClass')}>Cancel</Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
    renderDeleteTransactionModal() {
        return (
            <Modal show={this.state.modalDeleteTransaction} onHide={() => this.closeModal('modalDeleteTransaction')}>
                <Modal.Header closeButton style={{ border: 'none' }}>
                    <Modal.Title className='txtBold fs-24'>Confirm Delete Transaction?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col style={{ textAlign: "right" }}>
                            <div>
                                <Button style={{ marginRight: "1rem" }} className='redButton' onClick={() => {
                                    this.creditApi.DeleteTransaction(this.state.editingTransaction.idtransaction,(res,err) => {
                                        if (err != null && err) {
                                            this.props.setMessages([{ type: "danger", message: err }])
                                        } else {
                                            this.props.setMessages([{ type: "success", message: "Transaction Removed" }])
                                        }
                                        this.closeModal('modalDeleteTransaction')
                                        this.init()
                                    })
                                }}>Yes</Button>
                                <Button className='greyButton' onClick={() => this.closeModal('modalDeleteTransaction')}>Cancel</Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }

    render() {
        if (this.state.student == null) {
            return (<FullScreenSpinner />)
        }
        var transactions = this.state.transactions
        var totalCreditsUsed = transactions.filter(transaction => transaction.relatedClass != null).reduce((total, currentItem) => {
            return total + currentItem.credits * -1
        }, 0)
        return (
            <AuthContext.Consumer>
                {authManager => (
                    <Container style={{ paddingBottom: '40px' }}>
                        {this.renderAddClassModal()}
                        {this.renderDeleteClassModal()}
                        {this.renderDeleteTransactionModal()}
                        {this.renderEditTransactionModal()}
                        <Row style={{ marginBottom: '20px' }}>
                            <Col className='txtBoldUppercase fs-24' style={{ textAlign: 'left' }}>{this.state.student.firstName} {this.state.student.lastName}</Col>
                            <Col className='txtBoldUppercase fs-24' style={{ textAlign: 'right' }}>Total Credits Used: {totalCreditsUsed}</Col>
                        </Row>
                        <Row className='txtBold creditBorderTop'>
                            <Col>Time Start</Col>
                            <Col>Time End</Col>
                            <Col>Credits Transacted</Col>
                            <Col>Transaction Date</Col>
                            <Col>Rental</Col>
                            <Col>Student</Col>
                            <Col>Action</Col>
                        </Row>
                        {transactions.map(transaction =>
                            <Row key={transaction.idtransaction} className='creditBorder'>
                                <Col>{transaction.relatedClass != null ? moment(transaction.relatedClass.starttime).format("hh:mma") : "-"}</Col>
                                <Col>{transaction.relatedClass != null ? moment(transaction.relatedClass.endtime).format("hh:mma") : "-"}</Col>
                                <Col>{transaction.relatedClass != null ? transaction.credits * -1 : transaction.credits}</Col>
                                <Col>{moment(transaction.purchasedate).format("DD/MM/YYYY")}</Col>
                                <Col>{transaction.rental_mode != null ? (transaction.rental_mode == 0 ? "No" : (transaction.rental_mode == 1 ? "Yes" : "Partial")) : "-"}</Col>
                                <Col>{transaction.student && transaction.student.firstName}</Col>
                                <Col>
                                {/* {(this.accessController.UserCanEditTransactionPackages(this.props.user.accessLevel, transaction.relatedClass)) && (
                                    <Button className='greyButton' onClick={() => {
                                        if (transaction.relatedClass) {
                                            this.setState({ editingClass: transaction.relatedClass, editingTransaction : transaction})
                                            this.openModal("modalClass")
                                        } else {
                                            this.setState({ editingTransaction : transaction})
                                            this.openModal("modalTransaction")
                                        }
                                    }}> 
                                        Edit
                                    </Button>
                                )} */}
                                {
                                    (transaction.relatedClass != null && (authManager.user.accessLevel.includes(1) || authManager.user.accessLevel.includes(2)) &&
                                    <Button className='redButton' style={{ marginTop: "1rem" }} onClick={() => {
                                        this.setState({ deletingClass: transaction.relatedClass })
                                        this.openModal("modalDeleteClass")
                                    }}>
                                        Delete
                                    </Button>)
                                }
                                {
                                    (transaction.relatedClass == null && (authManager.user.accessLevel.includes(1) || authManager.user.accessLevel.includes(2)) &&
                                    <Button className='redButton' style={{ marginTop: "1rem" }} onClick={() => {
                                        this.setState({ editingTransaction: transaction })
                                        this.openModal("modalDeleteTransaction")
                                    }}>
                                        Delete
                                    </Button>)
                                }
                                </Col>
                            </Row>
                        )}
                    </Container>
                )}
            </AuthContext.Consumer>
        )
    }
}

export default withRouter(withAuthManager(withMessageManager(CreditUsed)))