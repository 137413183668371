import React from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { TextField } from '@material-ui/core';
import { Formik } from "formik";
import { AuthContext, authenticate, logout } from '../../Helpers/auth/auth.js'
import { MessageContext } from "../../Helpers/messages/MessageRenderer.js";
import FullScreenModal from "../FullScreenModal/FullScreenModal.js"
import './login.css'
import UserAccount from '../../Models/UserAccount.js';
import { authenticator } from "otplib";
import QRCode from "qrcode";

import LoginBackground from '../../Assets/TeacherPortal/teachers_portal.jpg';
import LogoEmpirecode from '../../Assets/ParentPortal/LogoEmpirecode.png';
import Model from '../../Models/Model.js';

class LoginPage extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.username = React.createRef();
        this.password = React.createRef();
        this.code = React.createRef();

        this.accountApi = new UserAccount()
        this.state = {
            showForgotPasswordModal: false,
            pageState: "login", // set-2fa, 2fa, set-recovery, recovery, login,
            recoveryKey: "", // recovery key for 2fa
            qrCodeUrl: "",
            secretKey: "", // secret key for 2fa
        }
    }
    login = (authManager, setMessages) => {
        authenticate(this.username.value, this.password.value, authManager,
            (success, userstore) => {
                console.log(userstore)
                if (userstore?.state === "set-2fa") {
                    this.setState({ pageState: "set-2fa" })
                    const secretKey = authenticator.generateSecret();
                    const qrCodeUrl = authenticator.keyuri("Empire Code", "Worksheets System", secretKey);
                    QRCode.toDataURL(qrCodeUrl, (err, url) => {
                        this.setState({ qrCodeUrl: url, secretKey: secretKey })
                    });
                } else if (success && userstore?.state === "set-recovery") {
                    this.setState({ pageState: "set-recovery" })
                } else if (success && userstore?.state === "recovery") {
                    this.setState({ pageState: "recovery" })
                } else if (success && userstore?.state === "2fa") {
                    this.setState({ pageState: "2fa" })
                    // this.setState({ secretKey: userstore.secret })
                    // const qrCodeUrl = authenticator.keyuri("Empire Code", "Worksheets System", userstore.secret);
                    // QRCode.toDataURL(qrCodeUrl, (err, url) => {
                    //     this.setState({ qrCodeUrl: url })
                    // });
                } else if (success) {
                    let current_path = window.location.pathname
                    if (!current_path.includes("/parent")) {
                        return this.props.history.push('/')
                    }
                    var path = undefined
                    if (this.props.location.state !== undefined) {
                        path = this.props.location.state.referrer
                    }
                    if (path === undefined) {
                        if (userstore.adminId !== null || userstore.teacherId !== null || !current_path.includes("/parent")) {
                            this.props.history.push('/')
                        }
                        else {
                            logout(authManager)
                            this.props.history.push('/parent/login')
                        }
                    } else {
                        this.props.history.push(path)
                    }
                } else {
                    setMessages([{ type: "danger", message: "Error : Invalid username and password" }])
                }
            })
    }

    verify2faSetup = (authManager, setMessages) => {
        const code = this.code.value
        const api = new Model();
        api.postReq("/2fa/setup", {
            code: code,
            secret_key: this.state.secretKey
        }, (values, err) => {
            if (values.success) {
                this.setState({ pageState: "login" })
            } else {
                setMessages([{ type: "danger", message: "Error : Invalid code" }])
            }
        })
    }

    setRecovery = (authManager, setMessages) => {
        const recoveryKey = this.recoveryKey
        const api = new Model();
        api.postReq("/2fa/set-recovery-key", {
            recoveryKey: recoveryKey,
        }, (values, err) => {
            if (values.success) {
                this.setState({ pageState: "login" })
            }
        })
    }


    verify2fa = (authManager, setMessages) => {
        const code = this.code.value
        const api = new Model();
        api.postReq("/2fa/verify", {
            code: code,
            secret_key: this.state.secretKey
        }, (values, err) => {
            console.log(values)
            if (values.success) {
                window.location = "/home"
            } else {
                setMessages([{ type: "danger", message: "Error : Invalid code" }])
            }
        })
    }


    requestResetPassword = (username) => {
        this.accountApi.RequestPasswordReset(username, (res, err) => {
            if (res == null) {
                // this.props.setMessages([{ type: "danger", message: err }])
            } else {
                this.setState({ showForgotPasswordModal: false });
                // this.props.setMessages([{ type: "success", message: "An email has been sent to " + res.email }])
            }
        })
    }

    onHidePopupModal() {
        this.setState({ showForgotPasswordModal: false })
    }

    renderPopupModal() {
        var show = this.state.showForgotPasswordModal
        return (
            <FullScreenModal onCloseModal={() => { this.onHidePopupModal() }} show={show} size="lg">
                <div className="modalContainer">
                    <Row>
                        <Col>

                            <p className="fs-16" style={{ fontWeight: "600" }}>Enter your email below and we'll send you a link to reset your password.</p>
                        </Col>
                    </Row>
                    <Formik
                        initialValues={{
                            username: "",
                        }}
                        validationSchema={this.validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            var username = values.username
                            this.requestResetPassword(username)
                        }}>
                        {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                            <Form onSubmit={handleSubmit}>
                                <div>
                                    <TextField
                                        name="username"
                                        className="forgetpassfield"
                                        placeholder="Email address"
                                        fullWidth
                                        type="email"
                                        variant="outlined"
                                        value={values.username}
                                        onChange={handleChange}
                                        inputProps={this.propsForTextFieldInput}
                                        InputProps={this.propsForTextFieldInputProps}
                                        error={touched.username && errors.username}
                                        helperText={touched.username && errors.username}></TextField>
                                </div>
                                <div style={{ paddingTop: "15px" }}>
                                    <Button className="greenButton" style={{ width: "100%" }} type="submit">Reset Password</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

            </FullScreenModal>
        )
    }


    render() {
        return (
            <AuthContext.Consumer>
                {authManager => (
                    <>
                        {this.renderPopupModal()}
                        <MessageContext.Consumer>
                            {({ messages, setMessages }) => {
                                return (
                                    <div
                                        className="loginBackground"
                                        style={{
                                            background: `url(${LoginBackground})`,
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingTop: '38px',
                                        }}
                                    >
                                        <Row style={{ marginBottom: '52px' }}>
                                            <Col>
                                                <img src={LogoEmpirecode} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                className="txtBoldUppercase"
                                                style={{ fontSize: '40px' }}
                                            >
                                                Teachers Portal
                                            </Col>
                                        </Row>
                                        {this.state.pageState === "login" && <>
                                            <Row style={{ marginTop: '20px' }}>
                                                <Col>
                                                    <Form>
                                                        <Form.Group>
                                                            <Form.Row>
                                                                <Form.Control
                                                                    className="loginForm"
                                                                    placeholder="Enter your email"
                                                                    ref={(ref) => { this.username = ref }}
                                                                    type="email"
                                                                    autoComplete="username"
                                                                ></Form.Control>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Row>
                                                                <Form.Control
                                                                    className="loginForm"
                                                                    placeholder="Enter your password"
                                                                    ref={(ref) => { this.password = ref }}
                                                                    type="password"
                                                                    autoComplete="current-password"
                                                                ></Form.Control>
                                                            </Form.Row>
                                                        </Form.Group>
                                                    </Form>
                                                    <Row>
                                                        <Col>
                                                            <Button
                                                                className="fs-18 loginButton"
                                                                style={{ textTransform: 'uppercase' }}
                                                                onClick={() => this.login(authManager, setMessages)}
                                                            >
                                                                Login
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: '9px' }}>
                                                        <Col>
                                                            <a className="forgetpassword" style={{ cursor: "pointer" }} onClick={() => { this.setState({ showForgotPasswordModal: true }) }}>
                                                                Reset Password
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </>}

                                        {this.state.pageState === "set-2fa" && <>
                                            <Row>
                                                <Col>
                                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                                                        <p>Set up 2FA</p>
                                                        <p>Scan the QR code below with your authenticator app</p>
                                                        <img src={this.state.qrCodeUrl} style={{ width: 300, height: 300 }} />
                                                        <Form.Control
                                                            className="code fs-18"
                                                            placeholder="Enter a 6 digit code"
                                                            ref={(ref) => { this.code = ref }}
                                                            type="text"
                                                            autoComplete="code"
                                                            style={{ maxWidth: '500px' }}
                                                        ></Form.Control>
                                                        <Button
                                                            className="fs-18 loginButton"
                                                            style={{ textTransform: 'uppercase', marginTop: "20px" }}
                                                            onClick={() => this.verify2faSetup(authManager, setMessages)}
                                                        >
                                                            Verify
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>}

                                        {this.state.pageState === "set-recovery" && <>
                                            <Row>
                                                <Col>
                                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                                                        <p>Set up recovery key</p>
                                                        <p>Enter a recovery key</p>
                                                        <Form.Control
                                                            className="code fs-18"
                                                            placeholder="Enter a recovery key"
                                                            ref={(ref) => { this.recoveryKey = ref }}
                                                            type="text"
                                                            autoComplete="recoveryKey"
                                                            style={{ maxWidth: '500px' }}
                                                        ></Form.Control>
                                                        <Button
                                                            className="fs-18 loginButton"
                                                            style={{ textTransform: 'uppercase', marginTop: "20px" }}
                                                            onClick={() => this.setRecovery(authManager, setMessages)}
                                                        >
                                                            Set Recovery Key
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>}

                                        {this.state.pageState === "2fa" && <>
                                            <Row>
                                                <Col>
                                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                                                        <p>Open your authenticator app and enter the code below</p>
                                                        <Form.Control
                                                            className="code fs-18"
                                                            placeholder="Enter a 6 digit code"
                                                            ref={(ref) => { this.code = ref }}
                                                            type="text"
                                                            autoComplete="code"
                                                            style={{ maxWidth: '500px' }}
                                                        ></Form.Control>
                                                        <Button
                                                            className="fs-18 loginButton"
                                                            style={{ textTransform: 'uppercase', marginTop: "20px" }}
                                                            onClick={() => this.verify2fa(authManager, setMessages)}
                                                        >
                                                            Verify
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>}
                                    </div>)


                            }}
                        </MessageContext.Consumer>

                    </>
                )}
            </AuthContext.Consumer>
        )
    }
}
export default withRouter(LoginPage);