import React from 'react';
import { withRouter } from "react-router-dom";
import LessonListItem from './LessonListItem.js'
class LessonList extends React.Component { 
    render() {
        return (
            <>
            {
                this.props.lessons.map((lesson,i) => {
                    return (<LessonListItem 
                        lesson={lesson} 
                        studentId={this.props.studentId}
                        filter={this.props.filter}
                        key={i} />)
                })
            }
            </>
        )
    }
}
export default withRouter(LessonList);