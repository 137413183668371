import React from 'react';
import { withRouter } from "react-router-dom";
import { withAuthManager, AuthContext } from '../../Helpers/auth/auth'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { Redirect } from 'react-router'
import Model from '../../Models/Model'
import HomeBackground from '../../Assets/TeacherPortal/teachers_portal.jpg'
import LogoEmpirecode from '../../Assets/ParentPortal/LogoEmpirecode.png';
import AccessController from '../../AccessController/AccessController'
import './home.css'

class Home extends React.Component { 
    constructor(props,context) {
        super(props,context)
        this.api = new Model()
        this.permissions = new AccessController()
    }
    render() {
        if (this.props.user.familyId != null) {
            return (
            <Redirect to={{
                pathname: `/student/gallery/${this.props.user.familyId}`
              }} />
            )
        }
        return (
            <div
                className="loginBackground"
                style={{
                    background: `url(${HomeBackground})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '38px',
                }}
            >
                <Row className="sectionRow" style={{marginTop:"100px"}}>
                    <Col className="imgButtonLeft" >
                        <img
                            src={require("../../Assets/TeacherPortal/button-ViewStudents.png")}
                            style={{cursor:"pointer"}}
                            onClick={()=>this.props.history.push(`/student/list`)}
                        />
                    </Col>
                    <Col className="imgButtonRight">
                        <img
                            src={require("../../Assets/TeacherPortal/button-OpsManual.png")}
                            style={{cursor:"pointer"}}
                            onClick={async ()=>{
                                let url = await this.api.GetOpsManual()
                                if(url!= null) {
                                    window.open(url,'_blank')
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row className="sectionRow" style={{ marginTop:"20px"}}>
                    <Col className="imgButtonLeft" >
                        {this.permissions.UserCanAddFamilyAndStudent(this.props.user.accessLevel) &&
                            <img
                                src={require("../../Assets/TeacherPortal/button-AddNewFamily.png")}
                                style={{cursor:"pointer"}}
                                onClick={()=>this.props.history.push(`/student/family/new`)}
                            />
                        }
                    </Col>
                    <Col className="imgButtonRight" >
                        {this.permissions.UserCanAddProspect(this.props.user.accessLevel) &&
                            <img
                                src={require("../../Assets/TeacherPortal/button-AddProspect.png")}
                                style={{cursor:"pointer"}}
                                onClick={()=>this.props.history.push(`/prospect/new`)}
                            />
                        }
                    </Col>
                </Row>

                <Row style={{marginTop:"100px"}}>
                    <Col>
                        <p style={{color:"#878787"}}>Copyright © {new Date().getFullYear()} EMPIRE CODE PTE. LTD. All Right Reserved.</p>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default withAuthManager(withRouter(Home));