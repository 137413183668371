import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { TextField, FormControl, InputLabel, Select, Divider } from '@material-ui/core';
import FullScreenSpinner from '../../../Components/FullScreenSpinner/FullScreenSpinner'
import { withRouter } from 'react-router-dom';
import { withMessageManager } from '../../../Helpers/messages/MessageRenderer'
import { withAuthManager, AuthContext } from '../../../Helpers/auth/auth'
import './StudentCreditPage.css'

import Student from '../../../Models/Student'
import Family from '../../../Models/Family'

import moment from 'moment'

import StudentForm from './StudentForm'
import AddFamilyForStudentForm from './AddFamilyForStudentForm'
import AddClassForm from "./AddClassForm"
import EditParentForm from "./EditParentForm"
import FamilySourceForm from "./FamilySourceForm"
import UploadMediaModal from './modals/UploadMediaModal'
class StudentFamilyPanel extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            modalStudent: false,
            modalClass: false,
            modalFamily: false,
            modalConfirm: false,
            modalParent : false,
            modalSource : false,
            family : undefined,
            editingStudent : null,
            addClassFor : null,
            addMediaFor : null,
            renderUpload : false,
            loading: false,
        }
        this.inputRef = undefined
        this.studentId = props.match.params.studentId
        this.studentApi = new Student()
        this.familyApi = new Family()
    }
    openModal(modal){
        this.setState({[modal]: true})
    }
    closeModal(modal){
        this.setState({[modal]: false})
    }
    componentDidMount() {
        this.init()
    }
    init() {
        console.log("Init")
        var studentId = this.props.match.params.studentId
        this.familyApi.GetFamilyByStudentId(studentId, (family,err) => {
            console.log(family)
            this.setState({family : family})
        })
    }

    renderEditParentModal(){
        var parent = this.state.editingParent
        if (parent == null) { return }
        return(
            <Modal size="lg" show={this.state.modalParent} onHide={()=> this.closeModal('modalParent')}>
                <Modal.Header closeButton style={{border: 'none'}}>
                    <Modal.Title className='txtBold fs-24'>Edit Parent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditParentForm 
                        parent={parent} 
                        studentId = {this.props.match.params.studentId}
                        postSubmitHandler={()=>{
                            this.init()
                            this.closeModal('modalParent')
                            this.setState({editingParent : null})
                        }}
                    ></EditParentForm>
                </Modal.Body>
            </Modal>
        )
    }
  
    renderAddFamilyModal(){
        var studentId = this.props.match.params.studentId
        return(
            <Modal size="lg" show={this.state.modalFamily} onHide={()=> this.closeModal('modalFamily')}>
                <Modal.Header closeButton style={{border: 'none'}}>
                    <Modal.Title className='txtBold fs-24'>Add Family</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddFamilyForStudentForm 
                        studentId={studentId} 
                        postSubmitHandler={()=>{
                            this.init()
                            this.closeModal('modalFamily')
                        }}
                    ></AddFamilyForStudentForm>
                </Modal.Body>
            </Modal>
        )
    }
    renderAddStudentModal(){
        var student = null
        if (this.state.editingStudent) {
            student = this.state.editingStudent
        }
        return(
            <>
            <Modal size="lg" show={this.state.modalStudent} onHide={()=> this.closeModal('modalStudent')}>
                <Modal.Header closeButton style={{border: 'none'}}>
                    <Modal.Title className='txtBold fs-24'>{student == null ? 'Add Student' : 'Edit Student'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StudentForm student={student} familyId={this.state.family.familyId} centreId = {this.state.family.centreId} postSubmitHandler={() => {
                        this.init()
                        this.closeModal('modalStudent')
                    }}></StudentForm>
                </Modal.Body>
            </Modal>
            </>
        )
    }
    renderAddClassModal(){
        var studentTargetted = this.state.addClassFor
        var studentId = this.props.match.params.studentId
        if (studentTargetted != null) {
            studentId = studentTargetted.studentId
        }
        return(
            <Modal show={this.state.modalClass} onHide={()=> this.closeModal('modalClass')}>
                <Modal.Header closeButton style={{border: 'none'}}>
                    <Modal.Title className='txtBold fs-24'>Add Class</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddClassForm studentId={studentId} postSubmitHandler={() => {
                        this.props.reload()
                    }}
                    ></AddClassForm>
                </Modal.Body>
            </Modal>
        )
    }
    renderUploadModal(){
        var studentTargetted = this.state.addMediaFor
        var studentId = this.props.match.params.studentId
        if (studentTargetted != null) {
            studentId = studentTargetted.studentId
        }
        return(
           <UploadMediaModal studentId = {studentId} onClose={()=> {this.setState({renderUpload : false})}} />
        )
    }
    renderSourceModal() {
        var familyId = this.state.family.familyId
        return(
            <Modal show={this.state.modalSource} onHide={()=> this.closeModal('modalSource')}>
                <Modal.Header closeButton style={{border: 'none'}}>
                    <Modal.Title className='txtBold fs-24'>{this.state.editingSource ? "Edit Source" : "Add Source"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FamilySourceForm source={this.state.editingSource} familyId={familyId} postSubmitHandler={() => {
                        this.init()
                        this.setState({editingSource : null})
                        this.closeModal('modalSource')
                    }}
                    ></FamilySourceForm>
                </Modal.Body>
            </Modal>
        )
    }

    renderAccountDetails() {
        if (this.state.family != null) {
            return (
                <Container>
                <Row className='creditBorderTop'>
                    <Col xs={3} style={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}>
                    <span className='txtBoldUppercase'>CENTRE NAME</span>
                    <span className='txtGrey'>{this.state.family.centre.name}</span>
                    </Col>
                    <Col xs={3} style={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}>
                    <span className='txtBoldUppercase'>CENTRE ID</span>
                    <span className='txtGrey'>{this.state.family.centreId}</span>
                    </Col>
                    <Col xs={3} style={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}>
                    <span className='txtBoldUppercase'>FAMILY ID</span>
                    <span className='txtGrey'>{this.state.family.familyId}</span>
                    </Col>
                </Row>
                {this.state.family.students && this.state.family.students.length > 0 && (
                    <Row className='creditBorder'>
                        <Col>
                        {this.state.family.students.map((student, index) => 
                        <>
                        {index > 0 && <Divider style={{marginLeft: '-15px', marginRight: '-15px', color: '#c4c4c4'}}></Divider>}
                            <Row style={{margin : "2rem 0"}} key={student.idstudent}>
                                <Col xs={9} style={{ textAlign: 'left' }}>
                                    <Row>
                                        <Col className='txtBoldUppercase'>Student : {student.firstName}</Col>
                                    </Row>
                                    <Row>
                                        <Col className='txtBold'>First Name</Col>
                                        <Col className='student-detail-col txtGrey'>{student.firstName}</Col>
                                    </Row>
                                    <Row>
                                        <Col className='txtBold'>Last Name</Col>
                                        <Col className='student-detail-col txtGrey'>{student.lastName}</Col>
                                    </Row>
                                    <Row>
                                        <Col className='txtBold'>Email</Col>
                                        <Col className='student-detail-col txtGrey'>{student.email || "-"}</Col>
                                    </Row>
                                    <Row>
                                        <Col className='txtBold'>Gender</Col>
                                        <Col className='student-detail-col txtGrey'>{student.gender || "-"}</Col>
                                    </Row>
                                    <Row>
                                        <Col className='txtBold'>Phone</Col>
                                        <Col className='student-detail-col txtGrey'>{student.contact || "-"}</Col>
                                    </Row>
                                    <Row>
                                        <Col className='txtBold'>Date of Birth</Col>
                                        <Col className='student-detail-col txtGrey'>{(student.dob && moment(student.dob).format("DD MMM YYYY"))|| "-"}</Col>
                                    </Row>
                                    <Row>
                                        <Col className='txtBold'>School/Company</Col>
                                        <Col className='student-detail-col txtGrey'>{student.school || "-"}</Col>
                                    </Row>
                                    <Row>
                                        <Col className='txtBold'>Postcode</Col>
                                        <Col className='student-detail-col txtGrey'>{student.postcode || "-"}</Col>
                                    </Row>
                                    <Row>
                                        <Col className='txtBold'>Class Preference</Col>
                                        <Col className='student-detail-col txtGrey'>{student.classPreference || "-"}</Col>
                                    </Row>
                                </Col>
                                <Col xs={3} style={{textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                    {!this.props.user.accessLevel.includes(3) && (
                                        <Row>
                                            <Col style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                            <Button className='fs-14 greyButton' onClick={() => {
                                                this.setState({editingStudent : student})
                                                this.openModal('modalStudent')
                                            }}>Edit</Button>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                        <Button className='fs-14 greenButton' onClick={()=> 
                                            {
                                                this.setState({addClassFor : student})
                                                this.openModal('modalClass')
                                            }
                                        }>Add Class</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                        <Button className='fs-14 greenButton' onClick={()=> 
                                            {
                                                this.setState({addMediaFor : student, renderUpload : true})
                                            }
                                        }>Upload Media</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            </>
                        )}
                        </Col>
                    </Row>
                )}
                <Row style={{alignItems: 'center', paddingTop: '15px', paddingBottom: '15px'}}>
                    <Col style={{textAlign: 'left', padding: '0 30px'}}>
                        {!this.props.user.accessLevel.includes(3) && (
                            <Button className='fs-14 blackButton' onClick={()=> {
                                this.setState({editingStudent : null})
                                this.openModal('modalStudent')}}>Add Student</Button>
                        )}
                    </Col>
                    <Col style={{textAlign: 'right', padding: '0 30px'}}>
                        <Button className='fs-14 blackButton' onClick={()=> {
                            this.props.history.push(`/student/gallery/${this.state.family.familyId}`)}}>View Gallery</Button>
                    </Col>
                </Row>
                {this.state.family.parents && this.state.family.parents.length > 0 && (
                    <Row className='creditBorderTop'>
                        <Col>
                        {this.state.family.parents.map(parent => 
                        <Row style={{margin : "2rem 0"}} key={parent.idparent}>
                            <Col xs={9} style={{ textAlign: 'left' }}>
                                <Row>
                                    <Col className='txtBoldUppercase'>Parent/Guardian : {parent.firstName}</Col>
                                </Row>
                                <Row>
                                    <Col className='txtBold'>First Name</Col>
                                    <Col className='student-detail-col txtGrey'>{parent.firstName}</Col>
                                </Row>
                                <Row>
                                    <Col className='txtBold'>Last Name</Col>
                                    <Col className='student-detail-col txtGrey'>{parent.lastName}</Col>
                                </Row>
                                <Row>
                                    <Col className='txtBold'>Email</Col>
                                    <Col className='student-detail-col txtGrey'>{parent.email}</Col>
                                </Row>
                                <Row>
                                    <Col className='txtBold'>Phone</Col>
                                    <Col className='student-detail-col txtGrey'>{parent.contact}</Col>
                                </Row>
                            </Col>
                            <Col xs={3} style={{textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                {!this.props.user.accessLevel.includes(3) && (
                                    <Row>
                                        <Col style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                            <Button className='fs-14 greyButton' onClick={() => {
                                                this.setState({editingParent : parent})
                                                this.openModal('modalParent')
                                            }}>Edit</Button>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                        )}
                        </Col>
                    </Row>
                )}
                {this.state.family.source ? (
                    <Row className='creditBorderTop'>
                        <Col>
                            <Row style={{margin : "2rem 0"}}>
                                <Col xs={9} style={{ textAlign: 'left' }}>
                                    <Row>
                                        <Col className='txtBoldUppercase'>Lead Source : {this.state.family.source.description}</Col>
                                    </Row>
                                    {this.state.family.source.relatedParentId && (
                                        <Row>
                                            <Col className='txtBoldUppercase'>Lead Parent : {this.state.family.source.relatedParent}</Col>
                                        </Row>
                                    )}
                                </Col>
                                <Col xs={3} style={{textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                    {!this.props.user.accessLevel.includes(3) && (
                                        <Row>
                                           <Col style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                               <Button className='fs-14 greyButton' onClick={() => {
                                                   this.setState({editingSource : this.state.family.source})
                                                   this.openModal('modalSource')
                                               }}>Edit</Button>
                                           </Col>
                                       </Row>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ): (
                    <Row style={{padding : "15px 0"}}>
                        <Col style={{padding: '5px 30px'}}>
                            <Button className='fs-14 blackButton' onClick={() => {
                                this.openModal('modalSource')
                            }}>Add Source</Button>
                        </Col>
                    </Row>
                )}
                </Container>
            );
        } else {
            return (
                <Button className='greenButton fs-14' onClick={()=>this.openModal('modalFamily')}>Add Family</Button>
            )
        }
        
    }
    render() {
        if (this.state.family === undefined) {
            return <FullScreenSpinner />
        }
        return (
        <>
            {this.state.loading && <FullScreenSpinner />}
            {this.state.modalStudent && this.renderAddStudentModal()}
            {this.state.modalFamily && this.renderAddFamilyModal()}
            {this.state.modalClass && this.renderAddClassModal()}
            {this.state.modalParent && this.renderEditParentModal()}
            {this.state.renderUpload && this.renderUploadModal()}
            {this.state.modalSource && this.renderSourceModal()}
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col className='fs-24'
                            style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            }}
                        >
                            Account Details
                        </Col>
                    </Row>
                    {this.renderAccountDetails()}
                </Col>
            </Row>
        </>
        );
    }
}

export default withAuthManager(withMessageManager(withRouter(StudentFamilyPanel)));
