import React from 'react';
import { withRouter } from "react-router-dom";
import ProjectListItem from './ProjectListItem.js'
class ProjectList extends React.Component { 
    render() {
        return (
            <>
            {
                this.props.projects.map((project,i) => {
                    return (<ProjectListItem
                        studentId = {this.props.studentId}
                        project={project} 
                        key={i} />)
                })
            }
            </>
        )
    }
}
export default withRouter(ProjectList);