import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import './AddNewFamilyForm.css';
import { withRouter } from 'react-router-dom';
import { withMessageManager } from '../../../Helpers/messages/MessageRenderer'
import { Formik, FieldArray, getIn} from "formik";
import FullScreenSpinner from '../../../Components/FullScreenSpinner/FullScreenSpinner'
import * as Yup from "yup";
import Family from '../../../Models/Family';
import moment from 'moment';

import FamilySourceFields from '../Student/FamilySourceFormFields'
class AddNewFamilyForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.validationSchema = Yup.object().shape({
        parents : Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string().typeError("Please enter your first name"),
                lastName: Yup.string().typeError("Please enter your last name"),
                email: Yup.string().nullable().typeError("Please enter your email").email("Please enter a valid email"),
                contact: Yup.string().nullable().typeError("Please enter your contact"),
            })
        ),
        students :  Yup.array().of(
          Yup.object().shape({
            firstName: Yup.string().typeError("Please enter your first name").required("Please enter your first name"),
            lastName: Yup.string().typeError("Please enter your last name").required("Please enter your last name"),
            email: Yup.string().nullable().typeError("Please enter your email").email("Please enter a valid email"),
            gender: Yup.string().typeError("Please select your gender").required("Please select your gender"),
            dob: Yup.date().typeError("Please enter a valid date").required("Please enter your date of birth"),
            contact: Yup.string().nullable().typeError("Please enter your phone number"),
            school: Yup.string().nullable().typeError("Please enter your school"),
            postcode: Yup.string().nullable().typeError("Please enter your postcode"),
          })
        ),
        centreId : Yup.number().required("Please select a centre"),
        idsource: Yup.number().nullable().typeError("Please select the lead source"),
        relatedParentId: Yup.number().nullable().typeError("Please select a related parent").test(
          "Referral requires parent",
          "Please select a parent who referred this family",
          function (relatedParentId) {
              if (this.parent.idsource == 1) {
                  return relatedParentId != null
              }
              return true
          }),
    });
    this.familyApi = new Family()
    this.state={
      centres : [],
      loading : false,
      studentIds : []
    }
  }
  componentDidMount() {
    this.familyApi.GetCentres((centres) => {
        this.setState({centres : centres})
    })
  }

  toStudentProfilePage() {
    this.props.history.push(`/student/profile/${this.state.studentIds[0]}`)
  }

  getStudentIdsByFamilyId(familyId) {
    this.familyApi.GetFamilyById(familyId,(res,err) => {
      if (err) {
        this.props.setMessages([{type : "danger" , message : err}])
      } else {
        this.setState({studentIds : res.studentIds})
        this.toStudentProfilePage()
      }
    })
  } 

  renderAddStudent(values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue ) {
    return (
      <Row style={{textAlign: 'left', border: '1px solid #c4c4c4', padding: '30px 40px', borderRadius: '4px'}}>
        <Col>
          <FieldArray
            name="students"
            render={arrayHelpers => (
              <React.Fragment >
                {values.students.map((student, index) => (
                  <React.Fragment key={index}>
                    <Row style={{margin : "1rem 0"}}>
                      <Col className='txtBold'> Student {index+1}</Col>
                    </Row>
                    <Row style={{margin : "1rem 0"}}>
                      <Col className='txtBold' style={{alignSelf : "center"}}>First Name :</Col>
                      <Col className='flex-grow-2'>
                          <TextField type="text" placeholder="First Name" variant="outlined" 
                            fullWidth
                            inputProps={{style:{padding:"0.5rem"}}}
                            // InputProps={singleLineTextFieldProps}
                            onChange={handleChange}
                            value={student.firstName} name={`students[${index}].firstName`}                                       
                            onBlur={handleChange}
                            error={(getIn(touched,`students[${index}].firstName`) && getIn(errors,`students[${index}].firstName`)) != undefined}
                            helperText={(getIn(touched,`students[${index}].firstName`) && getIn(errors,`students[${index}].firstName`))}
                          ></TextField>
                      </Col>
                    </Row>
                    <Row style={{margin : "1rem 0"}}>
                      <Col className='txtBold' style={{alignSelf : "center"}}>Last Name :</Col>
                      <Col className='flex-grow-2'>
                          <TextField type="text" placeholder="Last Name" variant="outlined" 
                            fullWidth
                            inputProps={{style:{padding:"0.5rem"}}}
                            // InputProps={singleLineTextFieldProps}
                            onChange={handleChange}
                            value={student.lastName} name={`students[${index}].lastName`}                                       
                            onBlur={handleChange}
                            error={(getIn(touched,`students[${index}].lastName`) && getIn(errors,`students[${index}].lastName`)) != undefined}
                            helperText={(getIn(touched,`students[${index}].lastName`) && getIn(errors,`students[${index}].lastName`))}
                          ></TextField>
                      </Col>
                    </Row>
                    <Row style={{margin : "1rem 0"}}>
                      <Col className='txtBold' style={{alignSelf : "center"}}>Email :</Col>
                      <Col className='flex-grow-2'>
                          <TextField type="text" placeholder="Email" variant="outlined" 
                            fullWidth
                            inputProps={{style:{padding:"0.5rem"}}}
                            // InputProps={singleLineTextFieldProps}
                            onChange={handleChange}
                            value={student.email} name={`students[${index}].email`}                                       
                            onBlur={handleChange}
                            error={(getIn(touched,`students[${index}].email`) && getIn(errors,`students[${index}].email`)) != undefined}
                            helperText={(getIn(touched,`students[${index}].email`) && getIn(errors,`students[${index}].email`))}
                          ></TextField>
                      </Col>
                    </Row>
                    <Row style={{margin : "1rem 0"}}>
                      <Col className='txtBold' style={{alignSelf : "center"}}>Gender :</Col>
                      <Col className='flex-grow-2'>
                        <FormControl variant="outlined" fullWidth>
                          <Select 
                            fullWidth
                            className="halfemshift"
                            SelectDisplayProps={{style:{padding:"0.5rem"}}}
                            onChange={handleChange}
                            value={student.gender}
                            variant="outlined"
                            name={`students[${index}].gender`}
                            onBlur={handleChange}
                            labelId="gender-select-label" 
                            size='small'>
                            <MenuItem value={'M'}>Male</MenuItem>
                            <MenuItem value={'F'}>Female</MenuItem>
                          </Select>
                          {getIn(touched,`students[${index}].gender`) && getIn(errors,`students[${index}].gender`)&& <FormHelperText error={true}>{getIn(errors,`students[${index}].gender`)}</FormHelperText>}
                        </FormControl>
                      </Col>
                    </Row>
                    <Row style={{margin : "1rem 0"}}>
                      <Col className='txtBold' style={{alignSelf : "center"}}>Phone :</Col>
                      <Col className='flex-grow-2'>
                          <TextField type="text" placeholder="Phone" variant="outlined" 
                            fullWidth
                            inputProps={{style:{padding:"0.5rem"}}}
                            // InputProps={singleLineTextFieldProps}
                            onChange={handleChange}
                            value={student.contact} name={`students[${index}].contact`}                                       
                            onBlur={handleChange}
                            error={(getIn(touched,`students[${index}].contact`) && getIn(errors,`students[${index}].contact`)) != undefined}
                            helperText={(getIn(touched,`students[${index}].contact`) && getIn(errors,`students[${index}].contact`))}
                          ></TextField>
                      </Col>
                    </Row>
                    <Row style={{margin : "1rem 0"}}>
                      <Col className='txtBold' style={{alignSelf : "center"}}>Date Of Birth :</Col>
                      <Col className='flex-grow-2'>
                        <KeyboardDatePicker
                          id="date-picker-dialog" className="fs-12" label="" fullWidth
                          invalidDateMessage=""
                          disableFuture={true} inputVariant="outlined" format="dd/MM/yyyy"
                          inputProps={{style:{padding:"0.5rem"}}}
                          InputProps={{
                            onBlur : (val) => {
                              var date = moment(val.target.value,"DD/MM/YYYY")
                              if (date.isValid()) {
                                  setFieldValue(`students[${index}].dob`, date.toDate())
                              }
                            }
                          }}
                          value={student.dob} 
                          onChange={(date) => {
                            setFieldValue(`students[${index}].dob`, date)
                          }}
                        />
                        {getIn(touched,`students[${index}].dob`) && getIn(errors,`students[${index}].dob`) && <FormHelperText error={true}>{getIn(errors,`students[${index}].dob`)}</FormHelperText>}
                      </Col>
                    </Row>
                    <Row style={{margin : "1rem 0"}}>
                      <Col className='txtBold' style={{alignSelf : "center"}}>School/Company :</Col>
                      <Col className='flex-grow-2'>
                          <TextField type="text" placeholder="School/Company" variant="outlined" 
                            fullWidth
                            inputProps={{style:{padding:"0.5rem"}}}
                            // InputProps={singleLineTextFieldProps}
                            onChange={handleChange}
                            value={student.school} name={`students[${index}].school`}                                       
                            onBlur={handleChange}
                            error={(getIn(touched,`students[${index}].school`) && getIn(errors,`students[${index}].school`)) != undefined}
                            helperText={(getIn(touched,`students[${index}].school`) && getIn(errors,`students[${index}].school`))}
                          ></TextField>
                      </Col>
                    </Row>
                    <Row style={{margin : "1rem 0"}}>
                      <Col className='txtBold' style={{alignSelf : "center"}}>Postcode :</Col>
                      <Col className='flex-grow-2'>
                          <TextField type="text" placeholder="Postal Code" variant="outlined" 
                            fullWidth
                            inputProps={{style:{padding:"0.5rem"}}}
                            // InputProps={singleLineTextFieldProps}
                            onChange={handleChange}
                            value={student.postcode} name={`students[${index}].postcode`}                                       
                            onBlur={handleChange}
                            error={(getIn(touched,`students[${index}].postcode`) && getIn(errors,`students[${index}].postcode`)) != undefined}
                            helperText={(getIn(touched,`students[${index}].postcode`) && getIn(errors,`students[${index}].postcode`))}
                          ></TextField>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      {values.students.length > 1 && (
                      <div className="clickable" style={{ color: 'red', textAlign: 'right', paddingRight: '15px' }} onClick={()=>{
                        arrayHelpers.remove(index)
                      }}>Remove Student</div>
                      )}
                      </Col>
                    </Row>
                  </React.Fragment>
                ))}  
                <Row>
                  <Col>
                    <div className="clickable" style={{ color: '#27bdbe' }} onClick={() => {
                      arrayHelpers.push({ 
                        firstName: '', lastName: '', email : '', dob : '', school : "", postcode : "", gender : '' }
                      )
                    }}>
                      + Add Another Student
                    </div>
                  </Col>
                  {/* <Col>
                    <div className="clickable" style={{ color: 'red', textAlign: 'right', paddingRight: '15px' }}>Remove Student</div>
                  </Col> */}
                </Row>
              </React.Fragment>
            )}
          />
        </Col>
      </Row>
    );
  }
  render() {
    if (this.state.loading) {
      return(<FullScreenSpinner />)
    }
    return (
      <Container style={{marginTop:'3%'}}>
        <Row style={{textAlign: 'left'}}>
          <Col>
            <Row>
              <Col className="txtBold fs-24">Add New Family</Col>
            </Row>
            <Formik
              initialValues={{
                parents : [
                    {
                        firstName: "",
                        lastName: "",
                        email: "",
                        contact: "",
                    },{
                        firstName: "",
                        lastName: "",
                        email: "",
                        contact: "",
                    },
                ],
                students : [{firstName: '', lastName: '', email : '', dob : '', school : "", postcode : "", gender : ''}],
                centreId : '',
                idsource: null,
                relatedParentId: null,
              }}
              validationSchema={this.validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                var firstParent = values.parents[0]
                if (firstParent.firstName == "" || firstParent.lastName == "" || firstParent.email == "" || firstParent.contact == "") {
                  setSubmitting(false)
                  this.props.setMessages([{type : "danger", message : "At least one parent details need to be filled"}])
                  return
                }
                if (values.idsource != null) {
                  values.source = {
                    idsource : values.idsource,
                    relatedParentId : values.relatedParentId
                  }
                }
                this.setState({loading : true})
                
                this.familyApi.AddFamily(values,(res,err) => {
                  if (err) {
                    this.props.setMessages([{type : "danger" , message : err}])
                  } else {
                    resetForm()
                    this.props.setMessages([{type : "success" , message : "Family Added"}])
                    this.getStudentIdsByFamilyId(res.value.familyId)                    
                  }
                  this.setState({loading : false})
                  setSubmitting(false)
                  window.scrollTo(0,0)
                })
              }}>
              {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                        <Row style={{margin : "1rem 0"}}>
                            <Col className='txtBold' style={{alignSelf : "center", paddingLeft:"55px"}}>Centre :</Col>
                            <Col className='flex-grow-2'>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-simple-select-outlined-label">Centre</InputLabel>
                                    <Select 
                                        fullWidth
                                        value={values.centreId}
                                        name="centreId"
                                        onChange={handleChange}
                                        labelId="demo-simple-select-outlined-label" 
                                        id="demo-simple-select-outlined" label="Centre" size='small'>
                                        {this.state.centres.map(centre => 
                                            <MenuItem key={centre.idcentre} value={centre.idcentre}>{centre.name}</MenuItem>
                                        )}
                                    </Select>
                                    {touched.centreId && errors.centreId && <FormHelperText error={true}>{errors.centreId}</FormHelperText>}
                                </FormControl>
                            </Col>
                        </Row>
                        <Row style={{margin : "1rem 0"}}>
                          <Col style={{paddingLeft : "40px", paddingRight : "0px"}}>
                            <FamilySourceFields values={values} errors={errors} touched={touched} handleChange = {handleChange} isSubmitting = {isSubmitting} setFieldValue = {setFieldValue}/>
                          </Col>
                        </Row>
                        <Row style={{textAlign: 'left', border: '1px solid #c4c4c4', padding: '30px 40px', borderRadius: '4px'}}>
                          <Col>
                            <FieldArray
                              name="parents"
                              render={arrayHelpers => (
                                values.parents.map((parent, index) => (
                                  <React.Fragment key={index}>
                                    <Row style={{margin : "1rem 0"}}>
                                      <Col className='txtBold'> Parent/Guardian {index + 1}</Col>
                                    </Row>
                                    <Row style={{margin : "1rem 0"}}>
                                      <Col className='txtBold' style={{alignSelf : "center"}}>First Name :</Col>
                                      <Col className='flex-grow-2'>
                                          <TextField type="text" placeholder="First Name" variant="outlined" 
                                            fullWidth
                                            inputProps={{style:{padding:"0.5rem"}}}
                                            // InputProps={singleLineTextFieldProps}
                                            initialValue={parent.firstName} name={`parents[${index}].firstName`}                                       
                                            onBlur={handleChange}
                                            error={(getIn(touched,`parents[${index}].firstName`) && getIn(errors,`parents[${index}].firstName`)) != undefined}
                                            helperText={(getIn(touched,`parents[${index}].firstName`) && getIn(errors,`parents[${index}].firstName`))}
                                          ></TextField>
                                      </Col>
                                    </Row>
                                    <Row style={{margin : "1rem 0"}}>
                                      <Col className='txtBold' style={{alignSelf : "center"}}>Last Name :</Col>
                                      <Col className='flex-grow-2'>
                                        <TextField type="text" placeholder="Last Name" variant="outlined" 
                                          fullWidth
                                          inputProps={{style:{padding:"0.5rem"}}}
                                          // InputProps={singleLineTextFieldProps}
                                          initialValue={parent.lastName} name={`parents[${index}].lastName`}                                       
                                          onBlur={handleChange}
                                          error={(getIn(touched,`parents[${index}].lastName`) && getIn(errors,`parents[${index}].lastName`)) != undefined}
                                          helperText={(getIn(touched,`parents[${index}].lastName`) && getIn(errors,`parents[${index}].lastName`))}
                                        ></TextField>
                                      </Col>
                                    </Row>
                                    <Row style={{margin : "1rem 0"}}>
                                      <Col className='txtBold' style={{alignSelf : "center"}}>Email :</Col>
                                      <Col className='flex-grow-2'>
                                        <TextField type="text" placeholder="Email" variant="outlined" 
                                          fullWidth
                                          inputProps={{style:{padding:"0.5rem"}}}
                                          // InputProps={singleLineTextFieldProps}
                                          initialValue={parent.email} name={`parents[${index}].email`}                                       
                                          onBlur={handleChange}
                                          error={(getIn(touched,`parents[${index}].email`) && getIn(errors,`parents[${index}].email`)) != undefined}
                                          helperText={(getIn(touched,`parents[${index}].email`) && getIn(errors,`parents[${index}].email`))}
                                        ></TextField>
                                      </Col>
                                    </Row>
                                    <Row style={{margin : "1rem 0"}}>
                                      <Col className='txtBold' style={{alignSelf : "center"}}>Phone :</Col>
                                      <Col className='flex-grow-2'>
                                        <TextField type="text" placeholder="Phone" variant="outlined" 
                                          fullWidth
                                          inputProps={{style:{padding:"0.5rem"}}}
                                          // InputProps={singleLineTextFieldProps}
                                          initialValue={parent.contact} name={`parents[${index}].contact`}                                       
                                          onBlur={handleChange}
                                          error={(getIn(touched,`parents[${index}].contact`) && getIn(errors,`parents[${index}].contact`)) != undefined}
                                          helperText={(getIn(touched,`parents[${index}].contact`) && getIn(errors,`parents[${index}].contact`))}
                                        ></TextField>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                ))
                              )}
                            />
                          </Col>
                        </Row>
                        <Row style={{marginTop: '40px'}}>
                          <Col className="txtBold fs-24">Add Student</Col>
                        </Row>
                        {this.renderAddStudent(values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue )}
                        <Row style={{margin : "1rem 0"}}>
                            <Col style={{textAlign : "right"}}>
                                <Button className='greenButton' variant="secondary" type="submit" disabled={isSubmitting}>
                                  Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )
              }
            </Formik>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withMessageManager(withRouter(AddNewFamilyForm));
