import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Form} from 'react-bootstrap'
import {MessageContext} from '../../../Helpers/messages/MessageRenderer.js'
import { TextField,  Select, MenuItem, Chip, FormControl, InputLabel } from '@material-ui/core'
import { Formik } from "formik";
import * as Yup from "yup";
import FullScreenSpinner from "../../FullScreenSpinner/FullScreenSpinner.js";
import FullScreenModal from "../../FullScreenModal/FullScreenModal.js";
import Teacher from '../../../Models/Teacher'
import Family from '../../../Models/Family'
import Curriculum from '../../../Models/Curriculum'
import UserAccount from '../../../Models/UserAccount'
import addIcon from '../../../Assets/Icons/addIcon.png'
import CurriculumList from '../../Curriculum/CurriculumList/CurriculumList'
import AccessController from "../../../AccessController/AccessController.js";

import {AuthContext} from '../../../Helpers/auth/auth.js';
/* eslint-disable */
class TeacherProfilePage extends React.Component{
    constructor(props, context) {
        super(props,context)
        this.teacherApi = new Teacher()
        this.familyApi = new Family()
        this.userAccApi = new UserAccount()
        this.curriculumApi = new Curriculum()
        this.accesscontroller = new AccessController()
        this.state = {
            teacher : null,
            accountId : null,
            curriculums : [],
            curriculumsAvailable : [],
            centres : [],
            showCurriculumSelection : false,
            showCurriculumSelectionConfirmation : false,
            newCurriculumChosen : null,
            readOnly : {readOnly: true}
        }
        this.teacherId = props.match.params.teacherId
        this.teacherValidationScheme = Yup.object().shape({
            firstName : Yup.string().required("Please enter your first name"),
            lastName : Yup.string().required("Please enter your last name")
        });
    }

    getFormInitialValues() {
        var teacher = this.state.teacher
        return {
            firstName: teacher.firstName,
            lastName: teacher.lastName,
            centreIds : teacher.centreIds
        } 
    }

    refreshTeacherCurriculum() {
        this.teacherApi.GetTeacherCurriculum(this.teacherId, (curriculums) => {
            this.setState({curriculums : curriculums})
        })
    }
    componentDidMount() {
        this.teacherApi.GetTeacher(this.teacherId, (teacher) => {
            if (teacher == null) {
                this.props.history.goBack()
                this.props.setMessages([{type : "danger", message : "User cannot access this teacher"}])
                return
            }
            this.setState({teacher : teacher, accountId :  teacher.accountId})
            this.refreshTeacherCurriculum()
            this.curriculumApi.ListCurriculum((curriculums) => {
                this.setState({curriculumsAvailable : curriculums})
            })
            this.familyApi.GetCentres((centres,err) => {
                this.setState({centres : centres ? centres : []})
            })
        })
    }

    updateTeacher = (teacher,setSubmitting) => {
        this.teacherApi.UpdateTeacher(this.teacherId, teacher, (resp,error) => {
            setSubmitting(false)
            if (resp) {
                this.props.setMessages([{ type: "success", message: "Teacher successfully updated" }]);
                this.toPrevious()
            } else {
                console.log(error)
                let errormsg = error
                if (error == 'User is not an admin') {
                    errormsg = "User cannot update this teacher due to insufficient access rights or centre rights"
                }
                this.props.setMessages([{ type: "danger", message: errormsg }]);
            }
        })
    } 

    // updateTeacherAccount = () => {
    //     this.userAccApi.GetUserAccount(this.state.teacher.accountId, (res) => {
    //         var teacher = {
    //             initialUsername :res.username,
    //             initialPW : res.password,
    //             username : res.username,
    //             password : "ZERO"
    //         }
    //         this.userAccApi.UpdateUserAccount(this.state.teacher.accountId, teacher, (resp,error) => {
    //             if (resp) {
    //                 this.props.setMessages([{ type: "success", message: "Teacher's account successfully updated" }]);
    //                 this.toPrevious()
    //             } else {
    //                 this.props.setMessages([{ type: "danger", message: error }]);
    //             }
    //         })
    //     })
    // }

    archiveTeacher = () => {
        var accountId = this.state.teacher.accountId
        this.teacherApi.DeleteTeacher(accountId, (resp,error) => {
            if (resp) {
                this.props.setMessages([{ type: "success", message: "Teacher successfully updated" }]);
            } else {
                this.props.setMessages([{ type: "danger", message: error }]);
            }
        })
    }

    renderForm() {
        if (this.state.teacher === null) {
            return(<FullScreenSpinner />)
        }
        var init = this.getFormInitialValues();
        return(
            <AuthContext.Consumer>
            {authManager => {
            return (
                <Row>
                    <Col xs={12} >
                        <Formik
                            initialValues={init}
                            validationSchema={this.adminValidationScheme}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                var teacher = {}
                                teacher.firstName = values.firstName
                                teacher.lastName = values.lastName
                                teacher.centreIds = values.centreIds
                                teacher.accountId = this.state.accountId
                                this.updateTeacher(teacher,setSubmitting)   
                            }}
                        >
                        {({values,errors,touched,handleChange,setFieldValue,handleSubmit,isSubmitting})=> 
                        ( 
                        <Form onSubmit={handleSubmit} className="mx-auto">
                            {this.showScreener(isSubmitting)}
                            
                            <div>
                            <Row>
                                <Col>
                                    <Form.Group controlId="firstName">
                                        <TextField id="firstName" label="First Name" variant="outlined"  
                                            style={{width : "100%"}}
                                            value={values.firstName} 
                                            onChange={handleChange}
                                            name="firstName"
                                            disabled={(this.accesscontroller.UserCanEditTeacher(authManager.user.accessLevel)|| authManager.user.accountId === this.state.accountId )? false : true}
                                            readOnly={this.state.readOnly}
                                            required
                                            error={(touched.firstName && errors.firstName) !== undefined} helperText={touched.firstName && errors.firstName}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="lastName">
                                        <TextField id="lastName" label="Last Name" variant="outlined"  
                                            style={{width : "100%"}}
                                            value={values.lastName} 
                                            onChange={handleChange}
                                            name="lastName"
                                            disabled={(this.accesscontroller.UserCanEditTeacher(authManager.user.accessLevel)|| authManager.user.accountId === this.state.accountId )? false : true}
                                            readOnly={this.state.readOnly}
                                            required
                                            error={(touched.lastName && errors.lastName) !== undefined} helperText={touched.lastName && errors.lastName}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="centre-label" style={{background : 'white'}}>Centres</InputLabel>
                                        <Select
                                            multiple
                                            labelId="centre-label"
                                            name="centreIds"
                                            value={values.centreIds}
                                            onChange={handleChange}
                                            disabled={this.accesscontroller.UserCanEditTeacherCentre(authManager.user.accessLevel)? false : true}
                                            renderValue={(selected) => (
                                                <div style={{
                                                    display: "flex",
                                                    flexWrap : "wrap"
                                                }}>
                                                    {this.state.centres.filter((centre) => {
                                                        return selected.includes(centre.idcentre)
                                                    }).map((value) => (
                                                        <Chip key={value.idcentre} label={value.name} style={{margin : '0px 2px'}}/>
                                                    ))}
                                                </div>
                                            )}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                    width: 250,
                                                    },
                                                },
                                            }}
                                        >
                                            {this.state.centres.map((centre) => (
                                                <MenuItem key={centre.idcentre} value={centre.idcentre}>
                                                {centre.name}
                                                </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col></Col>
                            </Row>
                            </div>
                            
                                <Row style={{marginTop : "0.5%"}}>
                                    <Col style={{textAlign : "right"}}>
                                        {((authManager.user.accountId === this.state.accountId)|| (this.accesscontroller.UserCanResetCredentials(authManager.user.accessLevel))) && (
                                        <Button
                                        variant = "info"
                                        onClick={()=>{
                                            this.props.history.push(this.teacherId+'/account')}
                                        }>Account
                                        </Button>
                                        )}
                                        
                                        {this.accesscontroller.UserCanEditTeacher(authManager.user.accessLevel) && (
                                            <Button 
                                            variant ="info"
                                            style = {{marginLeft : "30px"}}
                                            type="submit" disabled={isSubmitting}>Update Teacher</Button>
                                        )}
                                        {this.accesscontroller.UserCanEditTeacher(authManager.user.accessLevel) && (
                                            <Button 
                                            variant ="info"
                                            style = {{marginLeft : "30px"}}
                                            onClick={() => {this.archiveTeacher()}}>Archive</Button>
                                        )}
                                        <Button 
                                        variant = "secondary"
                                        style = {{marginLeft : "30px"}}
                                        onClick={() => {this.toPrevious()}} disabled={isSubmitting}>Cancel</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                        </Formik>
                    </Col>
                </Row>
            )
            }}
            </AuthContext.Consumer>
        )
    }

    onHideCurriculumSelectionModal() {
        this.setState({showCurriculumSelection : false})
    }
    renderCurriculumSelectionModal() {
        var show = this.state.showCurriculumSelection
        var curriculumsAvailable = this.state.curriculumsAvailable
        return(
            <FullScreenModal onCloseModal={() => {this.onHideCurriculumSelectionModal()}} show={show}>
                <Container>
                    <Row>
                        <Col style={{padding : "10px"}}>
                            <h2>
                                Choose Curriculum to Add
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span : 10, offset : 1}}>
                            <CurriculumList onChoseCurriculum={(curriculum)=>{
                                this.setState({showCurriculumSelectionConfirmation : true, showCurriculumSelection : false, newCurriculumChosen : curriculum})
                            }} curriculums={curriculumsAvailable}/>
                        </Col>
                    </Row>
                </Container>
            </FullScreenModal>
        )
    }
    renderConfirmationModal() {
        var show = this.state.showCurriculumSelectionConfirmation
        var curriculum = this.state.newCurriculumChosen
        if (curriculum === null) {
            return (<></>)
        }
        var teacherId = this.teacherId
        return (
            <FullScreenModal onCloseModal={() => {
                this.setState({showCurriculumSelectionConfirmation : false})
            }} show={show}>
                <Container>
                    <Row>
                        <Col style={{padding : "10px"}}>
                            <h2 style={{fontSize : "1.5rem"}}>Confirm enroll teacher into curriculum "{curriculum.name}" ?</h2>
                        </Col>
                    </Row>
                    <Row style={{marginBottom : "10px"}}>
                        <Col style={{textAlign : "right"}}>
                            <Button variant="secondary" style={{marginRight : "10px"}} onClick={()=>{
                                this.setState({showCurriculumSelectionConfirmation : false,showCurriculumSelection : true})}}>Cancel</Button>
                            <Button variant="info" onClick={()=>{
                                this.teacherApi.EnrollTeacherIntoCurriculum(this.teacherId,curriculum.idcurriculum, (success) => {
                                    if (success) {
                                        this.props.setMessages([{type: "success", message : `You have enrolled the teacher into ${curriculum.name}`}])
                                    } else {
                                        this.props.setMessages([{type: "danger", message : `Enrollment into ${curriculum.name} failed`}])
                                    }
                                    this.setState({showCurriculumSelectionConfirmation : false, showCurriculumSelection : false})
                                    this.refreshTeacherCurriculum()
                                })
                            }}>OK</Button>
                        </Col>
                    </Row>
                </Container>
            </FullScreenModal> 
        )
    }
    renderCurriculumList() {
        return (
            <AuthContext.Consumer>
                {authManager => {
                    var showArchive = this.accesscontroller.UserCanRemoveCurriculum(authManager.user.accessLevel)
                    return(
                        <CurriculumList curriculums={this.state.curriculums}  showArchive={showArchive} viewPath={`/teacher/${this.teacherId}/curriculum`}/>
                    )
                }}
            </AuthContext.Consumer>
        )
    }
    toPrevious() {
        this.props.history.goBack()
    }
    showScreener(show) {
        if (show) {
            return (<FullScreenSpinner></FullScreenSpinner>)
        } else {
            return 
        }
    }
    render() {
        return (
            <Container style={{marginTop:"3%", marginBottom:"3%"}}>
                {this.renderCurriculumSelectionModal()}
                {this.renderConfirmationModal()}
                <Row>
                    <Col xs={12} lg={{span : 10, offset : 1}}>
                        <Row >
                            <Col style={{padding : "10px", textAlign : "left", fontSize : "24px", fontWeight : "bold"}}>
                                Teacher
                            </Col>
                        </Row>
                        {this.renderForm()}
                        <Row >
                            <Col style={{padding : "10px 10px 0 10px", textAlign : "left", fontSize : "24px", fontWeight : "bold"}}>
                                Curriculums Enrolled
                            </Col>
                        </Row>
                        {this.renderCurriculumList()}
                        <Row>
                            <Col style={{textAlign : "right"}}>
                                <img className="clickable" src={addIcon} style={{maxHeight: "100%", padding : "10px"}} onClick={() => {
                                    this.setState({showCurriculumSelection : true})
                                }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }

}
class TeacherProfilePageWrapper extends React.Component {
    render() {
        return (
            <MessageContext.Consumer>
                { ({messages, setMessages}) => (<TeacherProfilePage messages={messages} setMessages ={setMessages} history={this.props.history} {...this.props}></TeacherProfilePage>)}
            </MessageContext.Consumer>
        )
    }
}
export default withRouter(TeacherProfilePageWrapper);