import React from "react";
import { withRouter } from "react-router-dom";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import './footer.css'
class Footer extends React.Component {
  constructor(props, context) {
    super(props, context)

  }
  render() {
    return (
      <MDBFooter className="footer">
        <MDBContainer fluid className="text-center text-md-left">
          <MDBRow className="footerRow" style={{ display: "flex" }}>
            <MDBCol className="footerLeftColumn" style={{ fontSize: "0.825rem" }}>
              <p>© {new Date().getFullYear()} Empire Code Pte. Ltd. All rights reserved.</p>
            </MDBCol>
            <MDBCol className="footerRightColumn" style={{ textAlign: "right" }}>
              <img className="logo" src={require("../../Assets/Icons/EmpireCode-logo-footer.png")} alt="" />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBFooter>
    );
  }
}

export default withRouter(Footer);