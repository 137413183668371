import Model from './Model'

class Prospect {
    constructor() {
        this.model = new Model()
    }

    AddProspect(prospect, callback) {
        this.model.postReq(`/prospect/new`, prospect, (res, err) => {
            callback(res, err)
        })
    }
}

export default Prospect;