import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { TextField , Select, MenuItem, FormControl, InputLabel, FormHelperText} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Family from '../../../Models/Family';
import Autocomplete from "@material-ui/lab/Autocomplete";

class FamilySourceFormFields extends Component {
    constructor(props, context) {
        super(props, context);
        this.familyApi = new Family()
        this.state = {
            sources : [],
            parents : []
        }
    }
 
    componentDidMount() {
        this.familyApi.ListSources((sources,err) => {
            this.setState({sources : sources})
        })
        this.familyApi.ListParents((parents,err) => {
            this.setState({parents : parents})
        })
    }
  
    render() {
        var values = this.props.values
        var errors = this.props.errors
        var touched = this.props.touched
        var handleChange = this.props.handleChange
        var isSubmitting = this.props.isSubmitting
        var setFieldValue = this.props.setFieldValue
        var selectedParent = this.state.parents.find(parent => parent.idparent == values.relatedParentId)
        if (selectedParent == undefined) {
            selectedParent = null
        }
        return (
            <>
                <Row style={{margin : "1rem 0"}}>
                    <Col className='txtBold' style={{alignSelf : "center"}}>Lead Source :</Col>
                    <Col className='flex-grow-2'>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="leadsource-label" style={{backgroundColor : "white"}}>Lead Source</InputLabel>
                            <Select 
                                fullWidth
                                value={values.sourceId}
                                name="idsource"
                                onChange={handleChange}
                                labelId="dleadsource-label" 
                                id="leadsource-select" size='small'>
                                {this.state.sources.map(source => 
                                    <MenuItem key={source.idsource} value={source.idsource}>{source.description}</MenuItem>
                                )}
                            </Select>
                            {touched.sourceId && errors.sourceId && <FormHelperText error={true}>{errors.sourceId}</FormHelperText>}
                        </FormControl>
                    </Col>
                </Row>
                {values.idsource == 1 && this.state.parents && 
                    <Row style={{margin : "1rem 0"}}>
                        <Col className='txtBold' style={{alignSelf : "center"}}>Lead Parent :</Col>
                        <Col className='flex-grow-2'>
                            <Autocomplete
                                limitTags={2}
                                id="parents-selection"
                                options={this.state.parents}
                                getOptionLabel={(option) => option.fullname}
                                style={{ width: "100%" }}
                                value = {selectedParent}
                                renderInput={(params) => {
                                    // Object.assign(params.inputProps, this.props.propsForTextFieldInput);
                                    return (
                                        <TextField
                                            {...params}
                                            placeholder="Choose Parent"
                                            variant="outlined"
                                        />
                                    );
                                }}
                                onChange={(ev, value) => {
                                    if (value != null) {
                                        setFieldValue("relatedParentId",value.idparent)
                                    }
                                }}
                            />
                            {touched.relatedParentId && errors.relatedParentId && <FormHelperText error={true}>{errors.relatedParentId}</FormHelperText>}
                        </Col>
                    </Row>
                }
            </>
        );
    }
}

export default withRouter(FamilySourceFormFields);
