import React from 'react';
import { withRouter } from "react-router-dom";
import TeacherLessonListItem from './TeacherLessonListItem.js'
class TeacherLessonList extends React.Component { 
    render() {
        return (
            <>
            {
                this.props.lessons.map((lesson,i) => {
                    return (<TeacherLessonListItem 
                        lesson={lesson} 
                        teacherId={this.props.teacherId}
                        key={i} />)
                })
            }
            </>
        )
    }
}
export default withRouter(TeacherLessonList);