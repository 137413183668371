import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { TextField, FormControl, InputLabel, Select, Divider } from '@material-ui/core';
import { Spinner } from 'react-activity';
import FullScreenSpinner from '../../../../Components/FullScreenSpinner/FullScreenSpinner'
import { withRouter } from 'react-router-dom';
import { withMessageManager } from '../../../../Helpers/messages/MessageRenderer'

import heic2any from "heic2any";
import Student from '../../../../Models/Student'
import CloseButton from '../../../../Assets/Icons/cancelIcon.png'
class UploadMediaModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            modalShow : 'upload',
            files : [],
            uploading : false,
            loading : false
        }
        this.studentApi = new Student()
    }
    openModal(modal){
        this.setState({modalShow: modal})
    }
    closeModal(modal){
        this.props.onClose()
        this.setState({modalShow: null})
    }
    componentDidMount() {
        this.init()
    }
    init() {
        var studentId = this.props.studentId
    }

    renderInputElm() {
        return(
            <input style={{display : "none"}} 
            accept="image/*,video/*,.heic"
            ref={(ref) => {
                if (ref == null) {
                    return
                }
                ref.click()
                this.inputRef = ref
            }} type="file" name="filefield" multiple="multiple" onChange={(e) => {
                this.OnUploadFiles(e)
            }} />
        )
    }

    renderUploadModal(){
        return(
            <Modal size="lg" show={this.state.modalShow == "upload"} onHide={()=> this.closeModal('upload')}>
                <Modal.Header closeButton style={{border: 'none'}}>
                    <Modal.Title className='txtBold fs-24'>Upload Media</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.loading && 
                    <FullScreenSpinner />
                    }
                    {this.state.files.map((file,index) => {
                        return (
                            <>
                            <Row>
                                <Col style={{
                                    display : "flex",
                                    flexDirection : "row"
                                }}>
                                    {file.data.type.includes("video") ? 
                                    <video style={{flexGrow : 1, maxWidth : "95%"}} src={window.URL.createObjectURL(file.data)} controls/>
                                    :
                                    <img style={{flexGrow : 1, maxWidth : "95%"}} src={window.URL.createObjectURL(file.data)} />
                                    }
                                    <div 
                                    style={{
                                        display : "flex",
                                        alignItems : "flex-start",
                                        flexDirection : "row"
                                    }}>
                                        <img 
                                        className="clickable"
                                        src={CloseButton} onClick={() => {
                                            var files = this.state.files
                                            files.splice(index,1)
                                            this.setState({files})
                                        }}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginTop : "15px", marginBottom : "15px"}}>
                                <Col>
                                    <TextField
                                        type="text" placeholder="Caption" variant="outlined" fullWidth
                                        onBlur={(e) => {
                                            var files = this.state.files
                                            files[index].caption = e.target.value
                                            this.setState(files)
                                        }}
                                        defaultValue={file.caption}
                                    />
                                </Col>
                            </Row>
                            </>
                        )
                    })}
                    <Row>
                        <Col>
                            <Button className='blackButton fs-14' onClick={()=>this.setState({uploading : true})}>+ Add More Media</Button>
                        </Col>
                        <Col style={{textAlign : "right"}}>
                            <Button className='greenButton fs-14' onClick={()=>this.OnSubmitUpload()}>Publish</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            
        )
    }

    OnSubmitUpload() {
        var studentId = this.props.studentId
        var formData = new FormData();
        this.state.files.forEach(file => {
            formData.append("images", file.data);
            formData.append("captions", file.caption);
        })
        this.setState({loading : true})
        this.studentApi.UploadFiles(studentId, formData, (result) => {
            this.setState({loading : false})
            this.props.setMessages([{type : "success", message : "Files uploaded!"}])
            this.props.onClose()
        })
    }
    async OnUploadFiles(event) {
        var files = this.state.files
        var keys = Object.keys(event.target.files)
        this.setState({loading : true})
        await Promise.all(keys.map(async (key) => {
            return new Promise(async (res,rej) => {
                let file = event.target.files[key]
                if (file.type.includes("heic")) {
                    let blobURL = URL.createObjectURL(file);
                    let blobRes = await fetch(blobURL)
                    let blob = await blobRes.blob()
                    let conversionResult = await heic2any({ blob })
                    files.push({data : conversionResult, caption : ''});
                } else {
                    files.push({data : event.target.files[key], caption : ''});
                }
                res(true)
            })
        }))
        this.setState({uploading : false, loading : false})
    }
    render() {
        return (
        <>
            {this.state.loading && <FullScreenSpinner />}
            {this.renderUploadModal()}
            {this.state.uploading && this.renderInputElm()}
        </>
        );
    }
}

export default withMessageManager(withRouter(UploadMediaModal));
