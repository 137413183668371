import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { TextField, Checkbox , FormControlLabel, FormHelperText } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import FullScreenSpinner from '../../../Components/FullScreenSpinner/FullScreenSpinner'
import { withRouter } from 'react-router-dom';
import { withMessageManager} from '../../../Helpers/messages/MessageRenderer'
import Student from '../../../Models/Student'
import { Formik } from "formik";
import * as Yup from "yup";
import moment from'moment'

const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#27bdbe",
        },
      },
      MuiButton: {
          label: {
              color: "#27bdbe"
          }
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          // backgroundColor: lightBlue.A200,
          // color: "white",
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: "#27bdbe",
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: "#27bdbe",
        },
      },
        MuiPickersClockPointer:{
            pointer:{
              backgroundColor: "#27bdbe",
            },
            thumb: {
                border: "14px solid #27bdbe"
            },
            noPoint: {
                backgroundColor: "#27bdbe"
            }
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: "#27bdbe"
            }
        },
    },
  });

class ClassForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.studentApi = new Student()
        this.state = {
            error : null
        }
        this.validationSchema = Yup.object().shape({
            date: Yup.date().typeError("Please enter date of class").required("Please enter date of class"),
            starttime: Yup.date().typeError("Please enter class start time").required("Please enter class start time"),
            endtime: Yup.date()
                        .typeError("Please enter class end time")
                        .required("Please enter class end time")
                        .test(
                            "end later than starting time",
                            "Please select a valid end time",
                            function (endtime) {
                                if (this.parent.starttime) {
                                    return moment(endtime).isAfter(moment(this.parent.starttime))
                                }
                                return true
                            }
                        ),
            credits : Yup.number().typeError("Enter how much credits were consumed").min(0).required("Enter how much credits were consumed"),
            noShow : Yup.bool().required("Indicate if is no show")
        });
    }
 
    componentDidMount() {
    }
  
    render() {
        var studentId = this.props.studentId
        var postSubmitHandler = this.props.postSubmitHandler
        var existingClass = this.props.existingClass
        var creditsUsed = null
        if (existingClass) {
            var endtime = moment(existingClass.endtime)
            var starttime = moment(existingClass.starttime)
            var duration = moment.duration(endtime.diff(starttime));
            creditsUsed = existingClass.creditsUsed
        }
        var now = new Date()
        now.setMinutes(0)
        var initialValues = {
            date: existingClass ? existingClass.starttime : new Date(),
            starttime: existingClass ? existingClass.starttime : now,
            endtime : existingClass ? existingClass.endtime : now,
            credits : creditsUsed,
            noShow : existingClass ? existingClass.isNoShow : false,
        }
        return (
            <>
                <Formik
                  initialValues={initialValues}
                  validationSchema={this.validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                        var dateofClass = moment(values.date)
                        var starttimeStr = dateofClass.format("YYYY-MM-DD")+"T"+ moment(values.starttime).format("HH:mm")
                        var endtimeStr = dateofClass.format("YYYY-MM-DD")+"T"+ moment(values.endtime).format("HH:mm")
                        var classObj = {
                            starttime : moment(starttimeStr,"YYYY-MM-DDTHH:mm").toDate(),
                            endtime : moment(endtimeStr,"YYYY-MM-DDTHH:mm").toDate(),
                            isNoShow : values.noShow,
                            creditsUsed : values.credits
                        }
                        if (existingClass != null) {
                            classObj.classId = existingClass.classId
                        }
                        this.studentApi.PostClassTakenByStudent(studentId, classObj, (res,err) => {
                            setSubmitting(false)
                            if (err != null) {
                                this.setState({error : err})
                                // this.props.setMessages([{message : err , type : "danger"}])
                            } else {
                                postSubmitHandler(res,err)
                            }
                        })
                  }}>
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) =>
                    (
                        <Form onSubmit={handleSubmit}>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold' style={{alignSelf : "center"}}>
                                    DATE:
                                </Col>
                                <Col className="flex-grow-2">
                                <ThemeProvider theme={materialTheme}>
                                    <KeyboardDatePicker
                                        id="date-picker-dialog" className="fs-12" label="Date of class" fullWidth
                                        disableFuture={true} inputVariant="outlined" format="dd/MM/yyyy"
                                        emptyLabel="Date of class"
                                        // inputProps={this.propsForTextFieldInput}
                                        InputProps={{
                                            onBlur : (val) => {
                                                var date = moment(val.target.value,"DD/MM/YYYY")
                                                if (date.isValid()) {
                                                    setFieldValue("date", date.toDate())
                                                }
                                            }
                                        }}
                                        value={values.date} 
                                        onChange={(date) => {
                                            setFieldValue("date", date)}}
                                    />
                                    {touched.date && errors.date && <FormHelperText error={true}>{errors.date}</FormHelperText>}
                                </ThemeProvider>
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold' style={{alignSelf : "center"}}>
                                    START TIME :
                                </Col>
                                <Col className="flex-grow-2">
                                <ThemeProvider theme={materialTheme}>
                                    <KeyboardTimePicker
                                        className="fs-12" fullWidth name="starttime"
                                        disableFuture={true} inputVariant="outlined" format="hh:mma"
                                        // inputProps={this.propsForTextFieldInput}
                                        InputProps={{
                                            onBlur : (val) => {
                                                var date = moment(val.target.value,"hh:mma")
                                                if (date.isValid()) {
                                                    setFieldValue("starttime", date.toDate(), true)
                                                }
                                                if (values.endtime != '') {
                                                    var endtime = moment(values.endtime)
                                                    endtime.seconds(0)
                                                    endtime.milliseconds(0)
                                                    var duration = moment.duration(endtime.diff(date));
                                                    var creditsUsed = duration.asHours().toFixed(2);
                                                    setFieldValue("credits",creditsUsed)
                                                }
                                            }
                                        }}
                                        minutesStep={15}
                                        value={values.starttime} 
                                        onChange={(date) => {
                                            var startTime = moment(date)
                                            startTime.seconds(0)
                                            startTime.milliseconds(0)
                                            setFieldValue("starttime", startTime, true)
                                            if (values.endtime != '') {
                                                var endtime = moment(values.endtime)
                                                endtime.seconds(0)
                                                endtime.milliseconds(0)
                                                var duration = moment.duration(endtime.diff(startTime));
                                                var creditsUsed = duration.asHours().toFixed(2);
                                                setFieldValue("credits",creditsUsed)
                                            }
                                        }}
                                    />
                                    {touched.starttime && errors.starttime && <FormHelperText error={true}>{errors.starttime}</FormHelperText>}
                                </ThemeProvider>
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold' style={{alignSelf : "center"}}>
                                    END TIME :
                                </Col>
                                <Col className="flex-grow-2">
                                <ThemeProvider theme={materialTheme}>
                                    <KeyboardTimePicker
                                        className="fs-12" fullWidth name="endtime"
                                        disableFuture={true} inputVariant="outlined" format="hh:mma"
                                        // emptyLabel="Start time of class"
                                        // inputProps={this.propsForTextFieldInput}
                                        InputProps={{
                                            onBlur : (val) => {
                                                var date = moment(val.target.value,"hh:mma")
                                                if (date.isValid()) {
                                                    setFieldValue("endtime", date.toDate(), true)
                                                }
                                                if (values.starttime != '') {
                                                    var starttime = moment(values.starttime)
                                                    starttime.seconds(0)
                                                    starttime.milliseconds(0)
                                                    var duration = moment.duration(date.diff(starttime));
                                                    var creditsUsed = duration.asHours().toFixed(2);
                                                    setFieldValue("credits",creditsUsed)
                                                }
                                            }
                                        }}
                                        value={values.endtime} 
                                        minutesStep={15}
                                        onChange={(date) => {
                                            var endtime = moment(date)
                                            endtime.seconds(0)
                                            endtime.milliseconds(0)
                                            setFieldValue("endtime", endtime, true)
                                            if (values.starttime != '') {
                                                var starttime = moment(values.starttime)
                                                starttime.seconds(0)
                                                starttime.milliseconds(0)
                                                var duration = moment.duration(endtime.diff(starttime));
                                                var creditsUsed = duration.asHours().toFixed(2);
                                                setFieldValue("credits",creditsUsed)
                                            }
                                        }}
                                    />
                                    {touched.endtime && errors.endtime && <FormHelperText error={true}>{errors.endtime}</FormHelperText>}
                                </ThemeProvider>
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col className='txtBold' style={{alignSelf : "center"}}>
                                    CREDITS USED :
                                </Col>
                                <Col className="flex-grow-2">
                                    <TextField
                                        type="text" placeholder="Credits Used" variant="outlined" fullWidth name="credits"
                                        onChange={handleChange}
                                        error={errors.credits && touched.credits}
                                        helperText={errors.credits}
                                        value={values.credits}
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col>
                                    <FormControlLabel
                                        control={<Checkbox checked={values.noShow} onChange={handleChange} name="noShow" />}
                                        label="No Show"
                                    />
                                    {touched.noShow && errors.noShow && <FormHelperText error={true}>{errors.noShow}</FormHelperText>}
                                </Col>
                            </Row>
                            <Row style={{margin : "1rem 0"}}>
                                <Col style={{textAlign : "right"}}>
                                    <Button className='greenButton' variant="secondary" type="submit" disabled={isSubmitting}>
                                        Done
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                {this.state.error && <FormHelperText error={true}>{this.state.error}</FormHelperText>}
                                </Col>
                            </Row>
                        </Form>
                    )
                  }
                </Formik>
            </>
        );
    }
}

export default withMessageManager(withRouter(ClassForm));
