import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core'
import './MyCheckbox.css'


const MyCheckbox = ({label, selected, styleClass, onChange}) => {

    const handeChange = (event) => {
        const {checked} = event.target
        onChange(checked);
    };

    return (
        <div className={`form-group ${styleClass}`} style={{height:"5vh", display:"flex", alignItems:"center"}}>
            {/* <input type="checkbox" 
            className="myinput"
            value={selected} 
            defaultChecked={selected} 
            onChange={handeChange}
            style={{zoom:"1.5", verticalAlign:"middle"}}/>

        <span style={{paddingLeft:"10px", fontWeight:"500", verticalAlign:"middle", fontSize:"0.9rem"}}>{ label }</span> */}
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox onChange={handeChange} value={selected} defaultChecked={selected} ></Checkbox>
            <p style={{margin: "auto", fontSize:"0.9rem", fontWeight:"500", paddingLeft:"10px"}}>{ label }</p>
        </div>
        </div>
    )
};

MyCheckbox.propTypes = {
    selected : PropTypes.bool.isRequired,
    label : PropTypes.string.isRequired,
    styleClass : PropTypes.string,
    onChange : PropTypes.func.isRequired
};

MyCheckbox.defaultProps = {
    styleClass: '' 
};

export default MyCheckbox;