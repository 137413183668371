import React from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Col} from 'react-bootstrap'
import TeacherLessonList from '../../Lesson/LessonList/TeacherLessonList'
import FullScreenSpinner from "../../FullScreenSpinner/FullScreenSpinner.js";
import Curriculum from '../../../Models/Curriculum';
class CurriculumPage extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {
            curriculum : undefined
        }
        this.curriculumApi = new Curriculum()
        this.curriculumId = props.match.params.curriculumId
        this.teacherId = props.match.params.teacherId
    }
    componentDidMount(){
        if (this.teacherId !== undefined) {
            this.curriculumApi.GetTeacherCurriculumProgress(this.curriculumId, this.teacherId, (curriculum) => {
                this.setState({curriculum : curriculum})
            })
        } else {
            this.curriculumApi.GetCurriculum(this.curriculumId, (curriculum) => {
                this.setState({curriculum : curriculum})
            })
        }
    }
    render() {
        var curriculum = this.state.curriculum
        if (curriculum === undefined) {
            return (<FullScreenSpinner />)
        }
        return (
            <>
            <Container style={{marginTop:"3%", marginBottom:"3%"}}>
            <Row>
                <Col style={{textAlign : "left"}}>
                    <h1>
                        {curriculum.name}
                    </h1>
                </Col>
            </Row>
            <TeacherLessonList lessons={curriculum.lessons} teacherId={this.teacherId}/>
            </Container>
            </>
        )
    }
}
export default withRouter(CurriculumPage);