import React from 'react';
import { withRouter } from "react-router-dom";
import TeacherListItem from './TeacherListItem'
import {MessageContext} from '../../../Helpers/messages/MessageRenderer.js'

// onDeleteTeacher, teachers
class TeacherList extends React.Component { 
    render() {
        return (
            <>
            {
                this.props.teachers.map((teacher,i) => {
                    return (
                        <div key={i} >
                            {teacher.isDeleted === 0 && (
                                <TeacherListItem 
                                    setMessages={this.props.setMessages} 
                                    teacher={teacher} key={i} 
                                    style={{marginTop : "5px", marginBottom : "10px"}}
                                    onDeleteTeacher={this.props.onDeleteTeacher}/>
                            )}
                        </div>
                        )
                })
            }
            </>
        )
    }
}
class TeacherListWrapper extends React.Component {
    render() {
        return (
            <MessageContext.Consumer>
                { ({messages, setMessages}) => (<TeacherList messages={messages} setMessages ={setMessages} history={this.props.history} {...this.props}></TeacherList>)}
            </MessageContext.Consumer>
        )
    }
}
export default withRouter(TeacherListWrapper);