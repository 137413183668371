import React from 'react';
import { withRouter } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { AuthContext, logout, withAuthManager } from '../../Helpers/auth/auth.js';
import { IsMobileView } from '../../Helpers/Helper';
import './Navbar.css'
class Home extends React.Component {
    constructor(props,context) {
        super(props, context);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
            width: 0,
            height: 0
          };
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    getNavbarText() {
        let navbarText = 'EMPIRE CODE CLASS TRACKING SYSTEM'; // for desktop view
        if (IsMobileView(this.state.width)) {
            navbarText = ''; // no text for mobile view
        } 
        return navbarText;                      
    }

    AccessControlButtons(authManager) {
        if (authManager.user.isAuthenticated) {
            return (
                <NavDropdown.Header>
                    {authManager.user.isAuthenticated && authManager.user.adminId !== undefined && authManager.user.adminId !== null && (
                        <>
                            <NavDropdown.Item onSelect={() => {
                                this.props.history.push('/profile')
                            }
                            }>Profile</NavDropdown.Item>
                        </>
                    )}
                    <NavDropdown.Item onSelect={() => {
                        var currUser = authManager.user
                        logout(authManager);
                        if (currUser.teacherId != undefined || currUser.adminId != null) {
                            this.props.history.push('/login')
                        }
                        else {
                            this.props.history.push('/parent/login')
                        }

                    }}>Log Out</NavDropdown.Item>
                </NavDropdown.Header>
            )
        } else {
            return (
                <>
                    <NavDropdown.Item href="/login">Teachers Portal</NavDropdown.Item>
                    <NavDropdown.Item onSelect={() => {
                        this.props.history.push('/parent/login')
                    }
                    }>Parents Portal</NavDropdown.Item>
                </>
            )
        }
    }
    render() {
        return (
            <>
                <Navbar className="topHeader" expand="lg" style={{ backgroundColor: "#27BDBE" }}>
                    <AuthContext.Consumer>
                        {authManager => {
                            return (
                                <>
                                    <Navbar.Brand style={{ color: "white", fontWeight: "bold" }}>
                                        <p className="logoText" style={{ margin: "auto 0" }}>
                                            <img
                                                className="clickable"
                                                src={require("../../Assets/Icons/EmpireCode-logo.png")} style={{ maxWidth: "100%", minWidth: "35px", width: "16%", paddingLeft: "3%", paddingRight: "1%" }} alt=""
                                                onClick={() => {
                                                    if (authManager.user.adminId !== null || authManager.user.teacherId !== null) {
                                                        this.props.history.push(`/`)
                                                    } else {
                                                        this.props.history.push(`/student/gallery/${authManager.user.familyId}`)
                                                    }
                                                }}
                                            />
                                            {this.getNavbarText()}
                                        </p>
                                    </Navbar.Brand>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
                                        <Nav className="justify-content-end">
                                            {authManager.user.isAuthenticated &&
                                                ((authManager.user.adminId !== undefined && authManager.user.adminId !== null) || (authManager.user.teacherId !== undefined && authManager.user.teacherId !== null))
                                                ? (
                                                    <>
                                                        <Nav.Link href="/student/list" style={{ color: "white", fontWeight: "bold" }}>Students</Nav.Link>
                                                        {(authManager.user.accessLevel.includes(1)|| authManager.user.accessLevel.includes(2)) &&
                                                            <Nav.Link href="/teacher/list" style={{ color: "white", fontWeight: "bold" }}>Teachers</Nav.Link>
                                                        }
                                                    </>
                                                ) :
                                                <>
                                                    <Nav.Link href={`/student/gallery/${authManager.user.familyId}`} style={{ color: "white", fontWeight: "bold" }}>Gallery</Nav.Link>
                                                    <Nav.Link href={`/parent/${authManager.user.familyId}/credit/`} style={{ color: "white", fontWeight: "bold" }}>Credits</Nav.Link>
                                                </>
                                            }


                                            <NavDropdown title={`${authManager.user.isAuthenticated ? "Options" : "Log In"}`} id="basic-nav-dropdown" className="optionsDropdown">
                                                {this.AccessControlButtons(authManager)}
                                            </NavDropdown>
                                        </Nav>
                                    </Navbar.Collapse>
                                </>
                            )
                        }}
                    </AuthContext.Consumer>
                </Navbar>
            </>
        )
    }
}
export default withAuthManager(withRouter(Home));
