import React from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col} from 'react-bootstrap'
import TeacherProjectList from '../../Project/ProjectList/TeacherProjectList'
class LessonListItem extends React.Component { 
    render() {
        var lesson = this.props.lesson
        return (
            <Row style={{borderRadius : "15px", marginTop : "10px",  marginBottom : "10px"}}>
                <Col >
                    <Row>
                        <Col xs={12} >
                            <div style={{textAlign : "left", padding : "10px", fontSize : "1.2rem", fontWeight : "bold"}}>
                                {lesson.name}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span : 12, offset : 0}}>
                            <TeacherProjectList projects={lesson.projects} teacherId={this.props.teacherId}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
export default withRouter(LessonListItem);