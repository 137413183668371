import React from 'react';
import { withRouter } from "react-router-dom";
import CurriculumListItem from './CurriculumListItem'

class CurriculumList extends React.Component { 
    render() {
        var viewPath = this.props.viewPath
        var showArchive = this.props.showArchive
        var onChoseCurriculum = this.props.onChoseCurriculum
        
        return (
            <>
            {
                this.props.curriculums.map((curriculum,i) => {
                    return (<CurriculumListItem 
                        showArchive={showArchive}
                        curriculum={curriculum} 
                        viewPath={`${viewPath}/${curriculum.idcurriculum}`} 
                        onChoseCurriculum = {onChoseCurriculum}
                        key={i} />)
                })
            }
            </>
        )
    }
}
export default withRouter(CurriculumList);