import React from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Col} from 'react-bootstrap'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { InputBase } from '@material-ui/core'
import StudentList from '../../Student/StudentList/StudentList'
import Student from '../../../Models/Student';
import Family from '../../../Models/Family';
import Curriculum from '../../../Models/Curriculum';
import Search from '../../../Components/Search/Search'
import cancelIcon from '../../../Assets/Icons/cancelIcon.png'

class StudentListPage extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {
            students : [],
            originalStudents : [],
            curriculums : [],
            studentsToFilter : [],
            chosenCurriculum : null
        }
        this.studentApi = new Student()
        this.familyApi = new Family()
        this.curriculumApi = new Curriculum()
    }
    componentDidMount(){
        this.reloadData()
    }
    reloadData() {
        this.curriculumApi.ListCurriculum((resp,err)=>{
            this.setState({curriculums : resp})
        })
        this.studentApi.ListStudents((students) => {
            var sorted = students.sort((a,b) => {
                if(a.fullname < b.fullname) { return -1; }
                if(a.fullname > b.fullname) { return 1; }
                return 0;
            })
            this.setState({students : sorted})
        })
    }

    async onChangeHandler(studentSearch, parentSearch) {
        let curriculumId = this.state.chosenCurriculum
        let studentlist = await this.studentApi.SearchStudent(studentSearch, parentSearch, curriculumId)
        this.setState({students : studentlist})
    }

    render() {
        return (
            <>
            <Container style={{marginTop:"3%"}}>
            <Row>
                <Col style={{textAlign : "left"}}>
                    <h1>
                        Students
                    </h1>
                </Col>
            </Row>
            <Row style={{paddingBottom:"10px"}}>
                <Col>
                    <div style={{fontWeight : "bold", fontSize : "1rem", border:"1px solid #c0c0c0", color:"#E2E2E2",
                    backgroundColor : "white", borderRadius : "6px", display : "flex"}}>
                        <InputBase
                            id="searchstudent"
                            placeholder="Search By Student" 
                            onChange = {(e,v) => {
                                let parentSearchTerm = document.getElementById("searchparent").value;
                                this.onChangeHandler(e.target.value, parentSearchTerm)
                            }}
                            style={{display : "block", flexGrow : 1, padding:"10.5px 14px"}}
                            inputProps={{ 'aria-label': 'naked' }}
                        />
                        <img src={cancelIcon} className="clickable" style={{width : "20px", height : "20px", marginRight : "10px", alignSelf : "center", marginLeft : "auto"}} 
                        onClick={() => {
                            document.getElementById("searchstudent").value = "";
                            let parentSearchTerm = document.getElementById("searchparent").value;
                            this.onChangeHandler('', parentSearchTerm)
                        }}/>
                    </div>
                </Col>
                <Col style={{textAlign : "left", paddingLeft:0}}>
                    <div style={{fontWeight : "bold", fontSize : "1rem", border:"1px solid #c0c0c0", color:"#E2E2E2",
                    backgroundColor : "white", borderRadius : "6px", display : "flex"}}>
                        <InputBase
                            id="searchparent"
                            placeholder="Search By Parent"
                            onChange = {(e,v) => {
                                let studentSearchTerm = document.getElementById("searchstudent").value;
                                this.onChangeHandler(studentSearchTerm, e.target.value)
                            }}
                            style={{display : "block", flexGrow : 1, padding:"10.5px 14px"}}
                            inputProps={{ 'aria-label': 'naked' }}
                            disabled={this.state.disableParentSearch}
                        />
                        <img src={cancelIcon} className="clickable" style={{width : "20px", height : "20px", marginRight : "10px", alignSelf : "center", marginLeft : "auto"}} 
                        onClick={() => {
                            document.getElementById("searchparent").value = "";
                            let studentSearchTerm = document.getElementById("searchstudent").value;
                            this.onChangeHandler(studentSearchTerm, '')
                        }}/>
                    </div>
                </Col>
                <Col style={{textAlign : "left", paddingLeft:0}}>
                    <FormControl variant="outlined" style={{width:"100%", paddingBottom:0}}>
                        <InputLabel id="demo-simple-select-outlined-label" style={{color:"light gray", opacity:"0.65"}}>Search By Curriculum</InputLabel>
                        <Select
                        labelId="curriculumId"
                        id="curriculumId"
                        value={this.state.chosenCurriculum}
                        onChange={(e,v) => {
                            let studentSearchTerm = document.getElementById("searchstudent").value;
                            let parentSearchTerm = document.getElementById("searchparent").value;
                            this.setState({chosenCurriculum : v.props.value},()=>{
                                this.onChangeHandler(studentSearchTerm, parentSearchTerm)
                            })
                        }}
                        label="curriculum"
                        style={{fontSize:"1rem"}}
                        disabled={this.state.disableCurriculumDropdown}
                        >
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {this.state.curriculums.map((curriculum, key) =>
                            <MenuItem key = {key} value={curriculum.idcurriculum}>{curriculum.name}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Col>
            </Row>
            <Row>
                <Col>
                    <StudentList students={this.state.students} onDeleteStudent={()=>{
                        this.reloadData()
                    }}/>
                </Col>
            </Row>
            </Container>
            </>
        )
    }
}
export default withRouter(StudentListPage);