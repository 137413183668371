import Model from './Model'
class Project {
    constructor() {
        this.model = new Model()
    }
    MarkProjectAsCompleted(studentId,comments,projectId, initials, completedDate, callback) {
        this.model.postReq(`/student/${studentId}/project`,{
            projectId : projectId,
            comments : comments,
            initials : initials,
            completedDate : completedDate
        },(curriculums,err) => {
            callback(curriculums.value, err ? err : curriculums.error)
        })
    }
    UpdateComment(studentId, projectId, comment, initials, completedDate, callback) {
        this.model.postReq(`/student/${studentId}/project/update_comment`,{
            projectId : projectId,
            comments : comment,
            initials : initials,
            completedDate : completedDate
        },(curriculums,err) => {
            callback(curriculums.value,err)
        })
    }
    MarkProjectAsCompletedForTeacher(teacherId, comments ,projectId, initials, completedDate, callback) {
        this.model.postReq(`/teacher/${teacherId}/project`,{
            projectId : projectId,
            comments : comments,
            initials : initials,
            completedDate : completedDate
        },(curriculums,err) => {
            callback(curriculums.value, err ? err : curriculums.error)
        })
    }
    UpdateCommentForTeacher(teacherId, projectId, comment, initials, completedDate, callback) {
        this.model.postReq(`/teacher/${teacherId}/project/update_comment`,{
            projectId : projectId,
            comments : comment,
            initials : initials,
            completedDate : completedDate
        },(curriculums,err) => {
            callback(curriculums.value,err)
        })
    }
}
export default Project;