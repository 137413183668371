import Model from './Model'
class Credit {
    constructor() {
        this.model = new Model()
    }
    GetPackages(callback) {
        this.model.get(`/credit/package`, {}, (res, err) => {
            callback(res.value, err || (!res.success && res.error))
        })
    }
    GetBalance(studentId, callback) {
        this.model.get(`/credit/student/${studentId}/balance`, {}, (res, err) => {
            callback(res.value, err || (!res.success && res.error))
        })
    }
    /*
    studentId : Int,
    packageId : Int,
    purchaseDate : Datetime
    */
    RecordPackagePurchase(studentId, packageId,price,rentalMode, purchaseDate, callback) {
        var post = {
            studentId: studentId,
            packageId: packageId,
            purchaseDate: purchaseDate,
            rentalMode:rentalMode,
            price: price,
        }
        this.model.postReq(`/credit/package/purchase`, post, (res, err) => {
            callback(res.value, err || (!res.success && res.error))
        })
    }
    GetTransactions(studentId, callback) {
        this.model.get(`/credit/student/${studentId}/transaction`, {}, (res, err) => {
            callback(res.value, err || (!res.success && res.error))
        })
    }

    GetBalanceWithFamilyId(familyId, callback) {
        this.model.get(`/credit/family/${familyId}/balance`, null, (res, err) => {
            callback(res.value[0], err || (!res.success && res.error))
        })
    }

    GetTransactionsByStudentIds(studentIds, callback) {
        this.model.get(`/credit/students/${studentIds}/transactions`, null, (res, err) => {
            callback(res.value, err || (!res.success && res.error))
        })
    }
    
    DeleteTransaction(transactionId, callback) {
        this.model.delete(`/credit/transaction/${transactionId}`, null, (res, err) => {
            callback(res && res.value, err || (!res.success && res.error))
        })
    }
    /*
    transactionId : Int,
    credits : decimal,
    studentId : Int,
    rental_mode : Int,
    */
    UpdateTransaction(transaction, callback) {
        this.model.postReq(`/credit/transaction/${transaction.transactionId}`, transaction, (res, err) => {
            callback(res && res.value, err || (!res.success && res.error))
        })
    }
    /*
    studentId : Int,
    credit : decimal,
    expiry : Datetime
    */
    SetBalance(studentId, credit, expiry, callback) {
        this.model.postReq(`/credit/set`,
            {
                studentId: studentId,
                credit: credit,
                expiry: expiry
            }, (res, err) => {
                callback(res && res.value, err || (!res.success && res.error))
            })
    }
}
export default Credit;