import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import FullScreenSpinner from '../../../Components/FullScreenSpinner/FullScreenSpinner'
import { withRouter } from 'react-router-dom';
import { Formik,} from "formik";
import * as Yup from "yup";
import Family from '../../../Models/Family';
import FormFields from './FamilySourceFormFields'

class FamilySourceForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.validationSchema = Yup.object().shape({
            idsource: Yup.number().nullable().typeError("Please select the lead source"),
            relatedParentId: Yup.number().nullable().typeError("Please select a related parent"),
        });
        this.familyApi = new Family()
    }
 
    componentDidMount() {
    }
  
    render() {
        var source = this.props.source ? this.props.source : {}
        var familyId = this.props.familyId
        var postSubmitHandler = this.props.postSubmitHandler
        return (
            <>
                <Formik
                    initialValues={{
                        idsource: source.idsource,
                        relatedParentId: source.relatedParentId,
                    }}
                    validationSchema={this.validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                            this.familyApi.UpdateFamilySource(familyId,{idsource : values.idsource, relatedParentId : values.relatedParentId}, (res,err) => {
                                setSubmitting(false)
                                postSubmitHandler(res,err)
                            })
                }}>
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue }) =>
                    (
                        <Form onSubmit={handleSubmit}>
                            <FormFields values={values} errors={errors} touched={touched} handleChange={handleChange} isSubmitting={isSubmitting} setFieldValue={setFieldValue}/>
                            <Row style={{margin : "1rem 0"}}>
                                <Col style={{textAlign : "right"}}>
                                    <Button className='greenButton' variant="secondary" type="submit" disabled={isSubmitting}>
                                        Done
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                  }
                </Formik>
            </>
        );
    }
}

export default withRouter(FamilySourceForm);
