import React from 'react';
import { withRouter } from "react-router-dom";
import StudentListItem from './StudentListItem'
import {MessageContext} from '../../../Helpers/messages/MessageRenderer.js'

// onDeleteStudent, students
class StudentList extends React.Component { 
    render() {
        return (
            <>
            {
                this.props.students.map((student,i) => {
                    return (<StudentListItem 
                        setMessages={this.props.setMessages} 
                        student={student} key={i} 
                        style={{marginTop : "5px", marginBottom : "10px"}}
                        onDeleteStudent={this.props.onDeleteStudent}/>)
                })
            }
            </>
        )
    }
}
class StudentListWrapper extends React.Component {
    render() {
        return (
            <MessageContext.Consumer>
                { ({messages, setMessages}) => (<StudentList messages={messages} setMessages ={setMessages} history={this.props.history} {...this.props}></StudentList>)}
            </MessageContext.Consumer>
        )
    }
}
export default withRouter(StudentListWrapper);