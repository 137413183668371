import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { withMessageManager } from '../../../Helpers/messages/MessageRenderer'
import { withAuthManager } from '../../../Helpers/auth/auth'
import studentIcon from '../../../Assets/Icons/GalleryIcons/student-icon.png'
import rightArrow from '../../../Assets/Icons/GalleryIcons/arrow.png'
import leftArrow from '../../../Assets/Icons/GalleryIcons/arrow-left.png'
import moment from 'moment'
import ReactPlayer from 'react-player/lazy'
import './family.css'
import Family from '../../../Models/Family';
import FullScreenSpinner from '../../FullScreenSpinner/FullScreenSpinner';
import deleteicon from '../../../Assets/Icons/DeleteIcon.png'
import Student from '../../../Models/Student';
class MediaGalleryItem extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isDownloading : false,
            idx : 0
        }
        this.familyApi = new Family()
        this.studentApi = new Student()
    }

    componentDidMount(){
        var files = this.props.mediaFiles
        var count = files.length
        this.setState({count : count})
    }

    componentDidUpdate(prevProps) {
        var files = this.props.mediaFiles
        var count = files.length
        var idx = this.state.idx
        if (count != this.state.count) {
            if (idx >= count) {
                idx = count - 1
            }
            this.setState({count : count, idx : idx})
        }
    }

    downloadMedia = () => {
        var files = this.props.mediaFiles
        var familyId = this.props.match.params.familyId
        var fileIds = files.map(media => media.fileId) 
        this.setState({isDownloading : true})
        var getUrl =`${this.familyApi.model.apiEndPoint}/family/${familyId}/upload/proxy?`
        fileIds.forEach((id,i) => {
            getUrl += `fileIds[]=${id}`
            if (i < (fileIds.length- 1)) {
                getUrl += '&&'
            }
        })
        let element = document.createElement("a");
        element.target = '_blank'
        element.href = getUrl
        console.log(getUrl)
        element.setAttribute("download",`EmpirecodeMoments.zip`);
        document.body.appendChild(element);
        element.click();
        this.setState({isDownloading : false})
        document.body.removeChild(element);
    }

    renderNextMedia = () => {
        var count = this.state.count
        var idx = this.state.idx
        if(idx + 1 < count){
            this.setState({idx : idx + 1})
        }else{
            this.setState({idx : 0})
        }
    }

    renderPrevMedia = () => {
        var count = this.state.count
        var idx = this.state.idx
        if(idx - 1 >= 0){
            this.setState({idx : idx - 1})
        }else{
            this.setState({idx : count-1})
        }
    }

    render() {
        var files = this.props.mediaFiles
        var idx = this.state.idx
        var numberOfMedia = this.state.count
        idx = idx%numberOfMedia
        var media = files[idx]
        if (media == undefined ) {
            return (<></>)
        }
        return (
        <>  
            <Row style={{backgroundColor:"white", marginBottom:"6%"}}>
                {this.state.isDownloading && <FullScreenSpinner />}
                <Col>
                    <Row className="wrappertitle" style={{padding:"10px"}}>
                        <Col className="fs-14" style={{textAlign:"left", display:'flex'}}>
                            <img src={studentIcon} alt="student-icon" style={{height:"20px", width:"20px", alignSelf:"center"}}/>
                            <span style={{fontWeight:"700", marginLeft:'10px'}}>{media.firstName} {media.lastName}</span>
                        </Col>
                        <Col className="fs-14" style={{textAlign:"right", color:"#4A4A4A", fontWeight:"500", opacity:"0.8"}}>
                            <span>{moment(media.uploadDate).format("MMMM DD, YYYY")}</span>
                        </Col>
                    </Row>
                    <Row style={{borderTop:"1.5px #C4C4C4 solid"}}>
                        <Col xs={1} className="arrowcol" style={{display:"flex"}}>
                            <img className="topArrow" src={leftArrow} alt="student-icon" onClick={()=>this.renderPrevMedia()}
                                    style={{height:"20px", width:"20px", alignSelf:"center", marginLeft:"auto", cursor:"pointer"}}/>
                        </Col>
                        <Col className='fullimg'>
                            {media.fileType.includes("video")? 
                            <ReactPlayer className="galleryImg" url={media.signedUrl} playing={false} controls={true} 
                            height="100%" width="100%"
                            />
                            : 
                            <img className="galleryImg" src={media.signedUrl} alt="student-icon" style={
                                {maxWidth:"100%"}
                            }/>
                            }
                            
                        </Col>
                        <Col xs={1} className="arrowcol" style={{display:"flex"}}>
                            <img className="topArrow" src={rightArrow} alt="student-icon" onClick={()=>this.renderNextMedia()}
                                    style={{height:"20px", width:"20px", alignSelf:"center", cursor:"pointer"}}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"10px", marginBottom : "10px", borderBottom:"1.5px #C4C4C4 solid"}}>
                        <Col className="fs-14" style={{textAlign:"center"}}>
                            {media.caption}
                        </Col>
                    </Row>
                    <Row className="footerBoxGallery" style={{textAlign : "center"}} noGutters={true}>
                        <Col style={{display:"flex", flexDirection:"row", alignItems : "center", textAlign : "center"}}>
                            <img className="bottomArrow" src={leftArrow} alt="student-icon" onClick={()=>this.renderPrevMedia()}
                                style={{height:"8px", width:"8px", alignSelf:"center", marginLeft:"auto", cursor:"pointer"}}/>
                            <label className="fs-12" style={{margin: "auto", color:"#4A4A4A", fontWeight:"500"}}>{idx+1}/{numberOfMedia}</label>
                            <img className="bottomArrow" src={rightArrow} alt="student-icon" onClick={()=>this.renderNextMedia()}
                                style={{height:"8px", width:"8px", alignSelf:"center", cursor:"pointer"}}/>
                        </Col>
                        <Col style={{display:"flex", alignItems : "center"}}>
                            <div disabled={this.state.isDownloading} className="fs-12" style={{color:"#24BDBE", cursor:"pointer", fontWeight:"500", margin : "auto"}} onClick={()=> this.downloadMedia()}>Download</div>
                        </Col>
                        {
                            (this.props.user.adminId != null || this.props.user.teacherId != null) && (
                                <Col>
                                    <div style={{padding : ".5rem"}}>
                                        <img src={deleteicon} className="clickable" onClick={() => {
                                            this.studentApi.DeleteFile(media.fileId, () => {
                                                this.props.reinit()
                                            })
                                        }}/>
                                    </div>
                                </Col>
                            )
                        }
                    </Row>
                </Col>
            </Row>
        </>
        );
    }
}

export default withAuthManager(withMessageManager(withRouter(MediaGalleryItem)));
